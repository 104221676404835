import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom";
import { map } from "lodash"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  InputGroup,
  FormGroup
} from "reactstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select"
import makeAnimated from "react-select/animated"

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";


/** Photo */
import photo1 from "assets/images/pillar/jaris-1.jpg";
import photo2 from "assets/images/pillar/jaris-2.jpg";
import photo3 from "assets/images/pillar/jaris-3.jpg";


const provinceData = [
  {
    label: "Province",
    options: [
      {value:"11", label:"ACEH"},
      {value:"12", label:"SUMATERA UTARA"},
      {value:"13", label:"SUMATERA BARAT"},
      {value:"14", label:"RIAU"},
      {value:"15", label:"JAMBI"},
      {value:"16", label:"SUMATERA SELATAN"},
      {value:"17", label:"BENGKULU"},
      {value:"18", label:"LAMPUNG"},
      {value:"19", label:"KEPULAUAN BANGKA BELITUNG"},
      {value:"21", label:"KEPULAUAN RIAU"},
      {value:"31", label:"DKI JAKARTA"},
      {value:"32", label:"JAWA BARAT"},
      {value:"33", label:"JAWA TENGAH"},
      {value:"34", label:"DAERAH ISTIMEWA YOGYAKARTA"},
      {value:"35", label:"JAWA TIMUR"},
      {value:"36", label:"BANTEN"},
      {value:"51", label:"BALI"},
      {value:"52", label:"NUSA TENGGARA BARAT"},
      {value:"53", label:"NUSA TENGGARA TIMUR"},
      {value:"61", label:"KALIMANTAN BARAT"},
      {value:"62", label:"KALIMANTAN TENGAH"},
      {value:"63", label:"KALIMANTAN SELATAN"},
      {value:"64", label:"KALIMANTAN TIMUR"},
      {value:"65", label:"KALIMANTAN UTARA"},
      {value:"71", label:"SULAWESI UTARA"},
      {value:"72", label:"SULAWESI TENGAH"},
      {value:"73", label:"SULAWESI SELATAN"},
      {value:"74", label:"SULAWESI TENGGARA"},
      {value:"75", label:"GORONTALO"},
      {value:"76", label:"SULAWESI BARAT"},
      {value:"81", label:"MALUKU"},
      {value:"82", label:"MALUKU UTARA"},
      {value:"91", label:"PAPUA"},
      {value:"92", label:"PAPUA BARAT"},
    ]
  },
]
const cityData = [
  {
    label: "City",
    options: [
      {value:"3101", label:"KAB. ADM. KEP. SERIBU"},
      {value:"3171", label:"KOTA ADM. JAKARTA PUSAT"},
      {value:"3172", label:"KOTA ADM. JAKARTA UTARA"},
      {value:"3173", label:"KOTA ADM. JAKARTA BARAT"},
      {value:"3174", label:"KOTA ADM. JAKARTA SELATAN"},
      {value:"3175", label:"KOTA ADM. JAKARTA TIMUR"},
    ]
  },
]
const districtData = [
  {
    label: "District",
    options: [
      {value:"317101", label:"Gambir"},
      {value:"317102", label:"Sawah Besar"},
      {value:"317103", label:"Kemayoran"},
      {value:"317104", label:"Senen"},
      {value:"317105", label:"Cempaka Putih"},
      {value:"317106", label:"Menteng"},
      {value:"317107", label:"Tanah Abang"},
      {value:"317108", label:"Johar Baru"},
    ]
  },
]
const orientationData = [
  {
    label: "Orientation",
    options: [
      {value:"vertical", label:"Vertical"},
      {value:"horizontal", label:"Horizontal"},
    ]
  },
]
const typeData = [
  {
    label: "OOH Type",
    options: [
      {value:"Static", label:"Static"},
      {value:"Led", label:"Led"},
    ]
  },
]
const shareSlotData = [
  {
    label: "Share Slot",
    options: [
      {value:"Yes", label:"Yes"},
      {value:"No", label:"No"},
    ]
  },
]
const pillarData = [
  {
    label: "Pillar",
    options: [
      {value:"SP 1", label:"SP 1"},
      {value:"SP 2", label:"SP 2"},
      {value:"SP 3", label:"SP 3"},
      {value:"SP 4", label:"SP 4"},
      {value:"SP 5", label:"SP 5"},
      {value:"SP 6", label:"SP 6"},
      {value:"SP 7", label:"SP 7"},
      {value:"SP 8", label:"SP 8"},
      {value:"MP 1", label:"MP 1"},
      {value:"MP 2", label:"MP 2"},
      {value:"MP 3", label:"MP 3"},
      {value:"MP 4", label:"MP 4"},
      {value:"MP 5", label:"MP 5"},
      {value:"MP 6", label:"MP 6"},
      {value:"MP 7", label:"MP 7"},
      {value:"MP 8", label:"MP 8"},
      {value:"AP 1", label:"AP 1"},
      {value:"AP 2", label:"AP 2"},
      {value:"AP 3", label:"AP 3"},
      {value:"AP 4", label:"AP 4"},
      {value:"AP 5", label:"AP 5"},
      {value:"AP 6", label:"AP 6"},
      {value:"AP 7", label:"AP 7"},
      {value:"AP 8", label:"AP 8"},
    ]
  },
]



class MlpCampaignCreate extends Component {

  constructor(){
    super();
    this.state={
      // siteId: "53",
      // siteName: "ST. ASEAN UNDERSTATION DOWNTRACK",
      // pillars: ["SS 1D","SS"],
      // latitude: "-6.2383976",
      // longitude: "106.7983671",
      // address: "Jl. Sisingamangaraja,",
      // directionalView: "ST. ASEAN TO ST. BLOKM",
      // province: "DKI Jakarta",
      // city: "Jakarta Selatan",
      // district: "Cilandak",
      // uniType: "STATIC",
      // uniWidth: "2",
      // uniHeight: "4",
      // uniOrientation: "Vertical",
      // numbersSet: "15",
      // sidePosition: "1",
      // shareSlot: "no",
      // totalSpotDay: "1"
    }
  }

  render() {
    return (
      <>
        <div className="page-content">
          <MetaTags>
            <title>Create New Pillar | Nord & Smith Dashboard</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Pillar" breadcrumbItem="Create New" />
            <Row>
              <Col lg="12">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    siteName: (this.state && this.state.siteName) || "",
                    pillars: (this.state && this.state.pillars) || "",
                    latitude: (this.state && this.state.latitude) || "",
                    longitude: (this.state && this.state.longitude) || "",
                    address: (this.state && this.state.address) || "",
                    directionalView: (this.state && this.state.directionalView) || "",
                    province: (this.state && this.state.province) || "",
                    city: (this.state && this.state.city) || "",
                    district: (this.state && this.state.district) || "",
                    uniType: (this.state && this.state.uniType) || "",
                    uniWidth: (this.state && this.state.uniWidth) || "",
                    uniHeight: (this.state && this.state.uniHeight) || "",
                    uniOrientation: (this.state && this.state.uniOrientation) || "",
                    numbersSet: (this.state && this.state.numbersSet) || "",
                    sidePosition: (this.state && this.state.sidePosition) || "",
                    shareSlot: (this.state && this.state.shareSlot) || "",
                    totalSpotDay: (this.state && this.state.totalSpotDay) || "",
                  }}
                  validationSchema={Yup.object().shape({
                    siteName: Yup.string().required("Please enter Site Name"),
                    pillars: Yup.string().required("Please select Pillar"),
                    latitude: Yup.string().required("Please enter Site Latitude"),
                    longitude: Yup.string().required("Please enter Site Longitude"),
                    address: Yup.string().required("Please enter Site Address"),
                    directionalView: Yup.string().required("Please enter Site Directional View"),
                    province: Yup.string().required("Please select Site Province"),
                    city: Yup.string().required("Please select Site City"),
                    distric: Yup.string().required("Please select Site Distric"),
                    uniType: Yup.string().required("Please select Site Type"),
                    uniWidth: Yup.string().required("Please enter Site Width"),
                    uniHeight: Yup.string().required("Please enter Site Height"),
                    uniOrientation: Yup.string().required("Please select Site Orientation"),
                    numbersSet: Yup.string().required("Please enter Site Numbers Set"),
                    sidePosition: Yup.string().required("Please enter Site Side Position"),
                    shareSlot: Yup.string().required("Please select Site Share Slot"),
                    totalSpotDay: Yup.string().required("Please enter Spot Day"),
                  })}
                  onSubmit={values => {
                    console.log(values);
                  }}
                  >
                  {({ errors, status, touched }) => (
                    <Form className="needs-validation">
                      <Card>
                        <div className="bg-transparent border-primary card-header">
                          <h5 className="my-0 text-primary">
                            <i className="bx bx-map-pin me-3"></i>
                            Site Name &amp; Location
                          </h5>
                        </div>
                        <CardBody>
                          {/* Double Line */}
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Site Name</Label>
                                <Field
                                  name="siteName"
                                  placeholder="First name"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.siteName && touched.siteName
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="siteName"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">                                
                                <Label>Select Pillar</Label>
                                <Select
                                  name="pillar"
                                  placeholder="pillar"
                                  options={pillarData}
                                  isMulti={true}
                                  classNamePrefix="select2-selection"
                                />
                                <ErrorMessage
                                  name="pillar"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* Double Line */}
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Latitude</Label>
                                <Field
                                  name="latitude"
                                  type="text"
                                  placeholder="Latitude"
                                  className={
                                    "form-control" +
                                    (errors.latitude && touched.latitude
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="latitude"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Longitude</Label>
                                <Field
                                  name="longitude"
                                  placeholder="Longitude"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.longitude && touched.longitude
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="longitude"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* Double Line */}
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Address</Label>
                                <Input
                                  placeholder="Address"
                                  type="textarea"
                                  defaultValue={this.state.address}
                                  rows="3"
                                />
                                <ErrorMessage
                                  name="address"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Directional View</Label>
                                <Input
                                  placeholder="Directional View"
                                  type="textarea"
                                  defaultValue={this.state.directionalView}
                                  rows="3"
                                />
                                <ErrorMessage
                                  name="directionalView"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* Triple Line */}
                          <Row>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label>Province</Label>
                                <Select
                                  name="province"
                                  placeholder="Province"
                                  options={provinceData}
                                  classNamePrefix="select2-selection"
                                />
                                <ErrorMessage
                                  name="province"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label>City</Label>
                                <Select
                                  name="city"
                                  placeholder="City"
                                  options={cityData}
                                  classNamePrefix="select2-selection"
                                />
                                <ErrorMessage
                                  name="city"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label>District</Label>
                                <Select
                                  name="district"
                                  placeholder="District"
                                  options={districtData}
                                  classNamePrefix="select2-selection"
                                />
                                <ErrorMessage
                                  name="district"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="12">
                              <FormGroup className="mb-3">
                                <small>
                                  Regional Selector nanti pakai ini : 
                                  <a href="https://ibnux.github.io/data-indonesia/" target="_blank" rel="noopener noreferrer">https://ibnux.github.io/data-indonesia/</a>
                                </small>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                          
                      <Card>
                        <div className="bg-transparent border-primary card-header">
                          <h5 className="my-0 text-primary">
                            <i className="bx bx-screenshot me-3"></i>
                            Site Size &amp; Detail
                          </h5>
                        </div>
                        <CardBody>
                          {/* Double Line */}
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Width</Label>
                                <Field
                                  name="uniWidth"
                                  type="text"
                                  placeholder="Width in meter"
                                  className={
                                    "form-control" +
                                    (errors.uniWidth && touched.uniWidth
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="uniWidth"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Height</Label>
                                <Field
                                  name="uniHeight"
                                  placeholder="Height in meter"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.uniHeight && touched.uniHeight
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="uniHeight"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* Double Line */}
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Type</Label>
                                <Select
                                  name="typeData"
                                  placeholder="Type"
                                  options={typeData}
                                  classNamePrefix="select2-selection"
                                />
                                <ErrorMessage
                                  name="typeData"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Orientation</Label>
                                <Select
                                  name="orientation"
                                  placeholder="Orientation"
                                  options={orientationData}
                                  classNamePrefix="select2-selection"
                                />
                                <ErrorMessage
                                  name="orientation"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          {/* Double Line */}
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Numbers Set</Label>
                                <Field
                                  name="numbersSet"
                                  type="text"
                                  placeholder="Numbers Set"
                                  className={
                                    "form-control" +
                                    (errors.numbersSet && touched.numbersSet
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="numbersSet"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Side Position</Label>
                                <Field
                                  name="sidePosition"
                                  placeholder="Side Position"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.sidePosition && touched.sidePosition
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="sidePosition"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>



                          {/* Double Line */}
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Share Slot</Label>
                                <Select
                                  name="shareSlot"
                                  placeholder="Share Slot"
                                  options={shareSlotData}
                                  classNamePrefix="select2-selection"
                                />
                                <ErrorMessage
                                  name="shareSlot"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Total Spot Day</Label>
                                <Field
                                  name="totalSpotDay"
                                  placeholder="Total Spot Day"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.totalSpotDay && touched.totalSpotDay
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="totalSpotDay"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </FormGroup>
                            </Col>
                          </Row>







                          <Row>
                            <Col md="12" className="text-right">
                              <FormGroup className="mb-3">
                                <Button color="primary" type="submit">
                                  Submit form
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>

                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default MlpCampaignCreate;