import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RECOVER_ACCOUNT,
  RECOVER_ACCOUNT_SUCCESS,
  RECOVER_ACCOUNT_ERROR
} from "./actionTypes"

const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  recoverAccountSuccessMsg: null,
  recoverAccountError: null
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null,
      }
      break
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetSuccessMsg: action.payload,
      }
      break
    case FORGET_PASSWORD_ERROR:
      state = { ...state, forgetError: action.payload }
      break
    case RECOVER_ACCOUNT:
      state = {
        ...state,
        recoverAccountSuccessMsg: null,
        recoverAccountError: null,
      }
      break
    case RECOVER_ACCOUNT_SUCCESS:
        state = {
          ...state,
          recoverAccountSuccessMsg: action.payload,
        }
      break
    case RECOVER_ACCOUNT_ERROR:
      state = {
        ...state,
        recoverAccountError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default forgetPassword
