import {
  GET_PILLARS, GET_PILLARS_SUCCESS, GET_PILLARS_FAIL,
  GET_UNASSIGNED_PILLARS, GET_UNASSIGNED_PILLARS_SUCCESS, GET_UNASSIGNED_PILLARS_FAIL,
  GET_PILLAR_DATA, GET_PILLAR_DATA_SUCCESS, GET_PILLAR_DATA_FAIL,
  ADD_NEW_PILLAR, ADD_PILLAR_FAIL, ADD_PILLAR_SUCCESS,
  UPDATE_PILLAR, UPDATE_PILLAR_SUCCESS, UPDATE_PILLAR_FAIL,
  DELETE_PILLAR, DELETE_PILLAR_SUCCESS, DELETE_PILLAR_FAIL,
  RESET_PILLAR_ERROR, RESET_PILLAR_MESSAGE
} from "./actionTypes"

const INIT_STATE = {
  pillars: [],
  pillarData: null,
  error: null,
  message: null,
  loading: false
}

const store = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PILLARS:
      return { ...state, loading: true, message: null, error: null, pillars: [] }
    case GET_PILLARS_SUCCESS:
      return { ...state, loading: false, message: null, error: null, pillars: action.payload }
    case GET_PILLARS_FAIL:
      return { ...state, loading: false, message: null, error: action.payload, pillars: [] }
    case GET_UNASSIGNED_PILLARS:
      return { ...state, loading: true, message: null, error: null, pillars: [] }
    case GET_UNASSIGNED_PILLARS_SUCCESS:
      return { ...state, loading: false, message: null, error: null, pillars: action.payload }
    case GET_UNASSIGNED_PILLARS_FAIL:
      return { ...state, loading: false, message: null, error: action.payload, pillars: [] }
    case GET_PILLAR_DATA:
      return { ...state, loading: true, message: null, error: null, pillarData: null }
    case GET_PILLAR_DATA_SUCCESS:
      return { ...state, loading: false, message: null, error: null, pillarData: action.payload }
    case GET_PILLAR_DATA_FAIL:
      return { ...state, loading: false, message: null, error: action.payload, pillarData: null }
    case ADD_NEW_PILLAR:
      return { ...state, loading: true, message: null, error: null }
    case ADD_PILLAR_SUCCESS:
      return { ...state, loading: false, message: action.payload, error: null }
    case ADD_PILLAR_FAIL:
      return { ...state, loading: false, message: null, error: action.payload }
    case UPDATE_PILLAR:
      return { ... state, loading: true, message: null, error: null }
    case UPDATE_PILLAR_SUCCESS:
      return { ...state, loading: false, message: action.payload, error: null }
    case UPDATE_PILLAR_FAIL:
      return { ...state, loading: false, message: null, error: action.payload }
    case DELETE_PILLAR:
      return { ...state, loading: true, message: null, error: null }
    case DELETE_PILLAR_SUCCESS:
      return { ...state, loading: false, message: action.payload, error: null }
    case DELETE_PILLAR_FAIL:
      return { ...state, loading: false, message: null, error: action.payload }
    case RESET_PILLAR_ERROR:
      return { ...state, error: null }
    case RESET_PILLAR_MESSAGE:
      return { ...state, message: null }
    default:
      return state
  }
}

export default store