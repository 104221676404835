import React, {Component} from "react"
import {Link} from "react-router-dom"
import PropTypes from "prop-types"
import { downloadFile } from "./../../helpers/api_v1_helper"

class CustomDownloadButton extends Component {
    handleClick (e) {
        const { to, mimetype } = this.props

        downloadFile(to, mimetype).then(() => e.stopPropagation())
    }

    render() {
        const { className, ...more } = this.props
        return (
            <Link to="#" className={className} onClick={this.handleClick.bind(this)} {...more}>
                {this.props.children}
            </Link>
        )
    }
}

CustomDownloadButton.propTypes = {
    to: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    mimetype: PropTypes.string
}

export default CustomDownloadButton