import { call, put, takeEvery } from "redux-saga/effects"
import { GET_PILLARS, GET_PILLAR_DATA, ADD_NEW_PILLAR, DELETE_PILLAR, DELETE_PILLAR_SUCCESS, UPDATE_PILLAR, GET_UNASSIGNED_PILLARS } from "./actionTypes"
import {
  getPillarsSuccess,
  getPillarsFail,
  addPillarFail,
  getPillarDataSuccess,
  getPillarDataFail,
  addPillarSuccess,
  updatePillarSuccess,
  updatePillarFail,
  deletePillarSuccess,
  deletePillarFail, getUnassignedPillarsSuccess, getUnassignedPillarsFail
} from "./actions";
import { getPillars, getPillarData, addNewPillar, updatePillar, deletePillar, getUnassignedPillars } from "../../helpers/api_v1_helper"

function* fetchPillars() {
  try {
    const response = yield call(getPillars)

    yield put(getPillarsSuccess(response))
  } catch (error) {
    yield put(getPillarsFail(error))
  }
}

function* fetchUnassignedPillars({ payload: siteId }) {
  try {
    const response = yield call(getUnassignedPillars, siteId)

    yield put(getUnassignedPillarsSuccess(response))
  } catch (error) {
    yield put(getUnassignedPillarsFail(error))
  }
}

function* fetchPillarData({ payload: id }) {
  try {
    const response = yield call(getPillarData, id)

    yield put(getPillarDataSuccess(response))
  } catch (error) {
    yield put(getPillarDataFail(error))
  }
}

function* onAddNewPillar({ payload: pillar }) {
  try {
    yield call(addNewPillar, pillar)
    yield put(addPillarSuccess("Pillar added successfully"))
  } catch (error) {
    yield put(addPillarFail(error))
  }
}

function* onUpdatePillar({ payload: pillar }) {
  try {
    yield call(updatePillar, pillar)
    yield put(updatePillarSuccess("Pillar updated successfully"))
  } catch (error) {
    yield put(updatePillarFail(error))
  }
}

function* onDeletePillar({ payload: pillar }) {
  try {
    yield call(deletePillar, pillar)
    yield put(deletePillarSuccess("Pillar delete successfully"))
  } catch (error) {
    yield put(deletePillarFail(error))
  }
}

function* pillarSaga() {
  yield takeEvery(GET_PILLARS, fetchPillars)
  yield takeEvery(GET_UNASSIGNED_PILLARS, fetchUnassignedPillars)
  yield takeEvery(GET_PILLAR_DATA, fetchPillarData)
  yield takeEvery(ADD_NEW_PILLAR, onAddNewPillar)
  yield takeEvery(UPDATE_PILLAR, onUpdatePillar)
  yield takeEvery(DELETE_PILLAR, onDeletePillar)
}

export default pillarSaga
