import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Button, Label, InputGroup, FormGroup } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getSites, getCampaignsBySite, addNewStaticLog } from "./../../store/actions"
import CustomSelect from "../../components/Common/CustomSelect"
import CustomFlatpickr from "../../components/Common/CustomFlatpickr"
import CustomFileInput from "../../components/Common/CustomFileInput"

class MlpSiteCreate extends Component {
  componentDidMount() {
    const { getSites } = this.props

    getSites()
  }

  render() {
    const { sites, campaigns, addNewStaticLog, history, loading, getCampaignsBySite } = this.props
    const optSites = sites.map(item => ({ value: item.id, label: item.name }))
    const optCampaigns = campaigns.map(item => ({ value: item.id, label: item.name }))

    return (
      <>
        <div className="page-content">
          <MetaTags>
            <title>Media Log Proof Static | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Media Log Proof" breadcrumbItem="Create New Log - Static" />
              <Row>
                <Col lg="12">
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      site: "",
                      campaign: "",
                      date: "",
                      files: "",
                      notes: ""
                    }}
                    validationSchema={Yup.object().shape({
                      site: Yup.string().required("Please select the site"),
                      campaign: Yup.string().required("Please select the campaign"),
                      date: Yup.string().required("Please enter the capture date"),
                      files: Yup.array().required("Please enter choose the files"),
                      notes: Yup.string().required("Please enter the notes")
                    })}
                    onSubmit={values => {
                      const formData = new FormData()

                      let newValues = Object.keys(values).reduce((r, i) => {
                        let cur = values[i]
                        let val = cur && cur.value ? cur.value : cur

                        if (Array.isArray(val) && val.length) {
                          for (let n = 0; n < val.length; n++) {
                            let nVal = val[n]

                            r.append(`${i}[${n}]`, nVal)
                          }
                        } else {
                          r.append(i, val)
                        }

                        return r
                      }, formData)

                      addNewStaticLog(newValues, () => {
                        history.push("/mlp-static-list")
                      })
                    }}
                    >
                    {({ errors, status, touched, handleSubmit }) => (
                      <Form className="needs-validation" onSubmit={handleSubmit}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  <Label>Site Static</Label>
                                  <Field
                                      name="site"
                                      placeholder="Select Site"
                                      component={CustomSelect}
                                      onChanged={opt => {
                                        if (opt && opt.value) {
                                          getCampaignsBySite(opt.value)
                                        }
                                      }}
                                      options={[{label: "Select Site", options: optSites }]}
                                      classNamePrefix="select2-selection"
                                  />
                                  <ErrorMessage name="site" component="div" className="invalid-feedback" />
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  <Label>Campaign</Label>
                                  <Field
                                      name="campaign"
                                      placeholder="Select Campaign"
                                      component={CustomSelect}
                                      options={[{label: "Select Campaign", options: optCampaigns }]}
                                      classNamePrefix="select2-selection"
                                  />
                                  <ErrorMessage name="campaign" component="div" className="invalid-feedback" />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  <Label>Capturing Date</Label>
                                  <InputGroup>
                                    <Field
                                        component={CustomFlatpickr}
                                        name="date"
                                        className="form-control"
                                        placeholder="Select Capturing Date"
                                        options={{mode: "single"}}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  <Label>Image</Label>
                                  <Field
                                    name="files"
                                    type="file"
                                    multiple={true}
                                    component={CustomFileInput}
                                    placeholder="Upload Image"
                                    className={"form-control" + (errors.image && touched.image ? " is-invalid" : "")}
                                  />
                                  <ErrorMessage name="files" component="div" className="invalid-feedback"/>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label>Notes</Label>
                                  <Field
                                      name="notes"
                                      as="textarea"
                                      rows="3"
                                      placeholder="Notes"
                                      className={"form-control" + (errors.side && touched.side ? " is-invalid" : "")}
                                  />
                                  <ErrorMessage name="notes" component="div" className="invalid-feedback" />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12" className="text-right">
                                <FormGroup className="mb-3">
                                  <Button color="primary" type="submit" disabled={loading}>Submit form</Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
          </Container>
        </div>
      </>
    )
  }
}


MlpSiteCreate.propTypes = {
  history: PropTypes.object,
  sites: PropTypes.array,
  campaigns: PropTypes.array,
  loading: PropTypes.bool,
  getSites: PropTypes.func,
  getCampaignsBySite: PropTypes.func,
  addNewStaticLog: PropTypes.func
}

const mapStateToProps = ({ sites, campaigns, log }) => ({
  sites: sites.sites && sites.sites.data ? sites.sites.data : [],
  campaigns: campaigns.campaigns && campaigns.campaigns.data ? campaigns.campaigns.data : [],
  loading: log.log_static_loading
})

const mapDispatchToProps = dispatch => ({
  getSites: () => dispatch(getSites()),
  getCampaignsBySite: id => dispatch(getCampaignsBySite(id)),
  addNewStaticLog: (data, callback) => dispatch(addNewStaticLog(data, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpSiteCreate))
