import { call, put, takeEvery } from "redux-saga/effects"
import { GET_SITES, GET_SITE_DATA, ADD_NEW_SITE, DELETE_SITE, UPDATE_SITE, DELETE_SITE_SUCCESS } from "./actionTypes"
import {
  getSitesSuccess,
  getSitesFail,
  getSiteDataSuccess,
  getSiteDataFail,
  addSiteFail,
  addSiteSuccess,
  updateSiteSuccess,
  updateSiteFail,
  deleteSiteSuccess,
  deleteSiteFail
} from "./actions"
import { getSites, getSiteData, addNewSite, updateSite, deleteSite } from "../../helpers/api_v1_helper"

function* fetchSites({ payload: queries }) {
  try {
    const response = yield call(getSites, queries)

    yield put(getSitesSuccess(response))
  } catch (error) {
    yield put(getSitesFail(error))
  }
}

function* fetchSiteData({ payload: id }) {
  try {
    const response = yield call(getSiteData, id)

    yield put(getSiteDataSuccess(response))
  } catch (error) {
    yield put(getSiteDataFail(error))
  }
}

function* onAddNewSite({ payload: site }) {
  try {
    yield call(addNewSite, site)
    yield put(addSiteSuccess("Site added successfully"))
  } catch (error) {
    yield put(addSiteFail(error))
  }
}

function* onUpdateSite({ payload: site }) {
  try {
    yield call(updateSite, site)
    yield put(updateSiteSuccess("Site updated successfully"))
  } catch (error) {
    yield put(updateSiteFail(error))
  }
}

function* onDeleteSite({ payload: site }) {
  try {
    yield call(deleteSite, site)
    yield put(deleteSiteSuccess("Site removed successfully"))
  } catch (error) {
    yield put(deleteSiteFail(error))
  }
}

function* siteSaga() {
  yield takeEvery(GET_SITES, fetchSites)
  yield takeEvery(GET_SITE_DATA, fetchSiteData)
  yield takeEvery(ADD_NEW_SITE, onAddNewSite)
  yield takeEvery(UPDATE_SITE, onUpdateSite)
  yield takeEvery(DELETE_SITE, onDeleteSite)
}

export default siteSaga
