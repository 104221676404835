export const POST_LOGIN = "/auth/login"
export const POST_LOGOUT = "/auth/logout"
export const POST_PASSWORD_FORGET = "/auth/forgot_password"
export const POST_RECOVER = "/auth/recover"
export const PROFILE = "/auth/profile"
export const USERS_URL = "/users"
export const SITES_URL = "/sites"
export const PILLARS_URL = "/pillars"
export const CAMPAIGNS_URL = "/campaigns"
export const CAMPAIGNS_BYSITE_URL = "/campaigns/by_site"
export const CAMPAIGN_STATUS_URL = "/campaign_status"
export const GET_PROVINCES = "/provinces"
export const GET_CITIES = "/cities"
export const GET_DISTRICTS = "/districts"
export const GET_LED_LOGS = "/logs/led"
export const GET_STATIC_LOGS = "/logs/static"
export const GET_RAW_LOGS = "/logs/ledraw"
export const GET_LED_SUMMARY = "/logs/ledsummary"
export const IMPORT_RAW_LED_LOGS = "/logs/ledraw_import"
export const BOOKINGS_URL = "/bookings"
export const FILES_URL = "/files"
export const GET_SUB_INDUSTRY = "/sub_industry"
export const GET_ADVERTISER = "/advertiser"
export const GET_SALES = "/sales"
export const GET_PLAYERS = "/players"
export const PLAYER_MAP_URL = "/player_map"
export const GET_ACCOUNTS = "/accounts"
export const CREATE_PLAYER_MAP_URL = "/create_player_map"
