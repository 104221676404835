import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {Container, Row, Col, Card, CardBody, Button, Spinner } from "reactstrap"
import paginationFactory, { PaginationListStandalone, PaginationProvider } from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import AccountDialogAction from "./account-dialog-action"
import { getAccounts, deleteAccount, resetAccountState } from "./../../store/actions"
import { DateTime } from "luxon"
import DeleteModal from "../../components/Common/DeleteModal"
import AlertModal from "../../components/Common/AlertModal"

class AccountList extends Component {
    constructor (props) {
        super(props)

        this.state = {
            dialog_open: false,
            dialog_title: "",
            dialog_data: null,
            want_to_delete: null
        }

        this.openAddNewAccountDialog = this.openAddNewAccountDialog.bind(this)
        this.openEditAccountDialog = this.openEditAccountDialog.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
        this.handleDeleteAccount = this.handleDeleteAccount.bind(this)
    }

    componentDidMount() {
        const { getAccounts } = this.props

        getAccounts()
    }

    openAddNewAccountDialog () {
        this.setState({
            dialog_open: true,
            dialog_title: "Add New Account",
            dialog_data: null
        })
    }

    openEditAccountDialog (data) {
        this.setState({
            dialog_open: true,
            dialog_title: "Edit Account",
            dialog_data: data
        })
    }

    closeDialog () {
        this.setState({
            dialog_open: false,
            dialog_title: "",
            dialog_data: null
        })
    }

    handleDeleteAccount = () => {
        const { deleteAccount } = this.props
        const { want_to_delete } = this.state
        if (want_to_delete !== null) {
            deleteAccount(want_to_delete, () => {
                this.setState({ want_to_delete: null })
            })
        }
    }

    render () {
        const { accounts, error, resetAccountState, loading } = this.props
        const { dialog_open, dialog_title, dialog_data, want_to_delete } = this.state
        const pageOptions = {sizePerPage: 10, totalSize: 0, custom: true}
        const accountListColumns = [
            {
                text: "ID",
                dataField: "id",
                sort: false
            },
            {
                text: "Url",
                dataField: "url",
                sort: false
            },
            {
                text: "Username",
                dataField: "username",
                sort: false
            },
            {
                text: "Status",
                dataField: "is_enable",
                sort: false,
                formatter: (cellContent, item) => item.is_enable ? "Enable" : "Disable",
            },
            {
                text: "Last Read",
                dataField: "last_read",
                sort: false,
                formatter: (cellContent, item) => {
                    if (item.last_read) {
                        return DateTime.fromSeconds(item.last_read).toFormat('dd MMM y - hh:mm:ss')
                    }

                    return "-"
                },
            },
            {
                dataField: "action",
                isDummyField: true,
                text: "Action",
                formatter: (cellContent, item) => (
                    <>
                        <div className="d-flex gap-3">
                            <Link to="#" className="badge badge-soft-danger" title="Delete" onClick={() => this.setState({ want_to_delete: item })}>
                                <i className="bx bx-trash font-size-18" />
                            </Link>
                            <Link to="#" className="badge badge-soft-secondary" title="Edit" onClick={() => this.openEditAccountDialog(item)}>
                                <i className="bx bx-edit font-size-18"/>
                            </Link>
                        </div>
                    </>
                ),
            }
        ]

        return (
            <React.Fragment>
                { dialog_open && <AccountDialogAction title={dialog_title} data={dialog_data} callback={this.closeDialog} /> }
                <DeleteModal show={want_to_delete !== null} onDeleteClick={this.handleDeleteAccount} onCloseClick={() => this.setState({ want_to_delete: null })} />
                <AlertModal show={error && error.length > 0} onCloseClick={resetAccountState} title="Error" message={error} type="error" />
                <div className="page-content">
                    <MetaTags>
                        <title>Account List | Nord &amp; Smith Dashboard</title>
                    </MetaTags>
                    <Container fluid>
                        <Breadcrumbs title="Accounts" breadcrumbItem="Account List" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider pagination={paginationFactory(pageOptions)} keyField="id" columns={accountListColumns} data={accounts}>
                                        {
                                            ({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider keyField="id" columns={accountListColumns} data={accounts}>
                                                {
                                                    toolkitprops => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col sm="4">
                                                                    <div className="search-box ms-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">

                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col sm="8">
                                                                    <div className="text-sm-end">
                                                                        <Button color="primary" className="font-16 btn-block btn btn-primary" onClick={this.openAddNewAccountDialog}>
                                                                            <i className="mdi mdi-plus-circle-outline me-1" />
                                                                            Add New Account
                                                                        </Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            {...toolkitprops.baseProps}
                                                                            {...paginationTableProps}
                                                                            classes={"table align-middle table-nowrap"}
                                                                            bordered={true}
                                                                            striped={true}
                                                                            hover={true}
                                                                            headerWrapperClasses={"thead-light"}
                                                                            noDataIndication={loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                                                            responsive
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                                    <PaginationListStandalone {...paginationProps} />
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )
                                                }
                                                </ToolkitProvider>
                                            )
                                        }
                                        </PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

AccountList.propTypes = {
    accounts: PropTypes.array,
    error: PropTypes.string,
    message: PropTypes.string,
    loading: PropTypes.bool,
    getAccounts: PropTypes.func,
    deleteAccount: PropTypes.func,
    resetAccountState: PropTypes.func
}

const mapStateToProps = ({ account }) => ({
    accounts: account.accounts && account.accounts.data ? account.accounts.data : [],
    error: account.error,
    message: account.message,
    loading: account.loading
})

const mapDispatchToProps = dispatch => ({
    getAccounts: () => dispatch(getAccounts()),
    deleteAccount: (data, callback) => dispatch(deleteAccount(data, callback)),
    resetAccountState: () => dispatch(resetAccountState())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AccountList))