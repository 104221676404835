import axios from "axios"
import config from "./../config"

const axiosApi = axios.create({
  baseURL: config.api.base_url
})

axiosApi.defaults.headers.common["X-App-Token"] = config.api.x_app_token
axiosApi.interceptors.request.use(config => {
  if (localStorage) {
    let accessToken = localStorage.getItem("accessToken")

    if (accessToken && accessToken.length) {
      config.headers['Authorization'] = accessToken
    }
  }

  return config
}, error => Promise.reject(error))
axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(err => {
      let message = err.message

      if (err.response && err.response.data && err.response.data.message) {
        message = err.response.data.message
      }

      throw message
    })
}

export async function postFormData(url, data, config = {}) {
    let defaultConfig = {
        headers: { "Content-Type": "multipart/form-data" }
    }

    return axiosApi
        .post(url, data, { ...config, ...defaultConfig })
        .then(response => response.data)
        .catch(err => {
            let message = err.message

            if (err.response && err.response.data && err.response.data.message) {
                message = err.response.data.message
            }

            throw message
        })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
    .catch(err => {
      let message = err.message

      if (err.response && err.response.data && err.response.data.message) {
        message = err.response.data.message
      }

      throw message
    })
}

export async function putFormData(url, data, config = {}) {
  let defaultConfig = {
    headers: { "Content-Type": "multipart/form-data" }
  }

  return axiosApi
    .put(url, data, { ...config, ...defaultConfig })
    .then(response => response.data)
    .catch(err => {
      let message = err.message

      if (err.response && err.response.data && err.response.data.message) {
        message = err.response.data.message
      }

      throw message
    })
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
    .catch(err => {
      let message = err.message

      if (err.response && err.response.data && err.response.data.message) {
        message = err.response.data.message
      }

      throw message
    })
}

export async function download(url, config = {}, name, type) {
    const defaultConfig = {
        responseType: "arraybuffer"
    }

    return await axiosApi.get(url, { ...config, ...defaultConfig }).then(response => {
        let element = document.createElement("a")

        document.body.appendChild(element)

        let blob = new Blob([response.data], { type })
        let url = window.URL.createObjectURL(blob)

        element.setAttribute("href", url)
        element.setAttribute("download", name)
        element.style.display = ""

        element.click()

        document.body.removeChild(element)
    })
}