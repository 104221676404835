import { call, put, takeEvery } from "redux-saga/effects"
import { GET_USERS, GET_USER_DATA, ADD_NEW_USER, DELETE_USER, UPDATE_USER, DELETE_USER_SUCCESS, ADD_USER_SUCCESS, UPDATE_USER_SUCCESS } from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getUserDataSuccess,
  getUserDataFail,
  addUserFail,
  addUserSuccess,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
} from "./actions"
import { getUsers, getUserData, addNewUser, updateUser, deleteUser } from "../../helpers/api_v1_helper"

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersSuccess(response))
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUserData ({ payload: id }) {
  try {
    const response = yield call(getUserData, id)
    yield put(getUserDataSuccess(response))
  } catch (error) {
    yield put(getUserDataFail(error))
  }
}

function* onAddNewUser({ payload: user }) {
  try {
    const response = yield call(addNewUser, user)
    yield put(addUserSuccess(response))
  } catch (error) {

    yield put(addUserFail(error))
  }
}

function* onUpdateUser({ payload: user }) {
  try {
    const response = yield call(updateUser, user)
    yield put(updateUserSuccess(response))
  } catch (error) {
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: user }) {
  try {
    const response = yield call(deleteUser, user)

    yield put(deleteUserSuccess(response))
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}

function* contactsSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER_DATA, fetchUserData)
  yield takeEvery(ADD_NEW_USER, onAddNewUser)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(DELETE_USER_SUCCESS, fetchUsers)
  yield takeEvery(ADD_USER_SUCCESS, fetchUsers)
  yield takeEvery(UPDATE_USER_SUCCESS, fetchUsers)
}

export default contactsSaga
