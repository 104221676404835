import React, { Component } from "react"
import { Bar } from "react-chartjs-2"

class BarChart2 extends Component {
  render() {
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      datasets: [
        {
          label: "Sales Analytics",
          backgroundColor: "rgb(86 191 12 / 20%)",
          borderColor: "rgb(86 191 12 / 20%)",
          borderWidth: 1,
          hoverBackgroundColor: "rgb(86 191 12 / 60%)",
          hoverBorderColor: "rgb(86 191 12 / 90%)",
          data: [65, 59, 81, 45, 56, 80, 50, 40, 81, 45, 56, 80],
        },
      ],
    }

    const option = {
      scales: {
        yAxes: [{
            ticks: {
                beginAtZero: true
            }
        }],
        xAxes: [{
          barPercentage: 0.4
        }],
        dataset: [
          {
            barPercentage: 0.4,
          },
        ],
      },
    }

    return (
      <React.Fragment>
        <Bar width={474} height={250} data={data} options={option} />
      </React.Fragment>
    )
  }
}

export default BarChart2
