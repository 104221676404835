import {
  GET_PILLAR_DATA,
  GET_PILLAR_DATA_FAIL,
  GET_PILLAR_DATA_SUCCESS,
  GET_PILLARS,
  GET_PILLARS_FAIL,
  GET_PILLARS_SUCCESS,
  ADD_NEW_PILLAR,
  ADD_PILLAR_SUCCESS,
  ADD_PILLAR_FAIL,
  UPDATE_PILLAR,
  UPDATE_PILLAR_SUCCESS,
  UPDATE_PILLAR_FAIL,
  DELETE_PILLAR,
  DELETE_PILLAR_SUCCESS,
  DELETE_PILLAR_FAIL,
  RESET_PILLAR_ERROR,
  RESET_PILLAR_MESSAGE,
  GET_UNASSIGNED_PILLARS_FAIL,
  GET_UNASSIGNED_PILLARS_SUCCESS,
  GET_UNASSIGNED_PILLARS
} from "./actionTypes";

export const getPillars = () => ({
  type: GET_PILLARS
})

export const getPillarsSuccess = pillars => ({
  type: GET_PILLARS_SUCCESS,
  payload: pillars
})

export const getPillarsFail = error => ({
  type: GET_PILLARS_FAIL,
  payload: error
})

export const getUnassignedPillars = siteId => ({
  type: GET_UNASSIGNED_PILLARS,
  payload: siteId
})

export const getUnassignedPillarsSuccess = pillars => ({
  type: GET_UNASSIGNED_PILLARS_SUCCESS,
  payload: pillars
})

export const getUnassignedPillarsFail = error => ({
  type: GET_UNASSIGNED_PILLARS_FAIL,
  payload: error
})

export const getPillarData = id => ({
  type: GET_PILLAR_DATA,
  payload: id
})

export const getPillarDataSuccess = pillar => ({
  type: GET_PILLAR_DATA_SUCCESS,
  payload: pillar
})

export const getPillarDataFail = error => ({
  type: GET_PILLAR_DATA_FAIL,
  payload: error
})

export const addNewPillar = pillar => ({
  type: ADD_NEW_PILLAR,
  payload: pillar
})

export const addPillarSuccess = message => ({
  type: ADD_PILLAR_SUCCESS,
  payload: message
})

export const addPillarFail = error => ({
  type: ADD_PILLAR_FAIL,
  payload: error
})

export const updatePillar = pillar => ({
  type: UPDATE_PILLAR,
  payload: pillar
})

export const updatePillarSuccess = message => ({
  type: UPDATE_PILLAR_SUCCESS,
  payload: message
})

export const updatePillarFail = error => ({
  type: UPDATE_PILLAR_FAIL,
  payload: error
})

export const deletePillar = pillar => ({
  type: DELETE_PILLAR,
  payload: pillar
})

export const deletePillarSuccess = message => ({
  type: DELETE_PILLAR_SUCCESS,
  payload: message
})

export const deletePillarFail = error => ({
  type: DELETE_PILLAR_FAIL,
  payload: error
})

export const resetPillarError = () => ({
  type: RESET_PILLAR_ERROR
})

export const resetPillarMessage = () => ({
  type: RESET_PILLAR_MESSAGE
})

