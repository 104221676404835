import {
  GET_PROVINCES_FAIL,
  GET_PROVINCES_SUCCESS,
  GET_CITIES_FAIL,
  GET_CITIES_SUCCESS,
  GET_DISTRICTS_FAIL,
  GET_DISTRICTS_SUCCESS,
  GET_SUB_INDUSTRY_FAIL,
  GET_SUB_INDUSTRY_SUCCESS,
  GET_ADVERTISER_FAIL,
  GET_ADVERTISER_SUCCESS,
  GET_SALES_FAIL,
  GET_SALES_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  provinces: [],
  cities: [],
  districts: [],
  sub_industry: [],
  advertiser: [],
  sales: [],
  error: {}
}

const store = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROVINCES_SUCCESS:
      return {...state, provinces: action.payload}
    case GET_PROVINCES_FAIL:
      return {...state, error: action.payload}
    case GET_CITIES_SUCCESS:
      return {...state, cities: action.payload}
    case GET_CITIES_FAIL:
      return {...state, error: action.payload}
    case GET_DISTRICTS_SUCCESS:
      return {...state, districts: action.payload}
    case GET_DISTRICTS_FAIL:
      return {...state, error: action.payload}
    case GET_SUB_INDUSTRY_SUCCESS:
      return {...state, sub_industry: action.payload}
    case GET_SUB_INDUSTRY_FAIL:
      return {...state, error: action.payload}
    case GET_ADVERTISER_SUCCESS:
      return {...state, advertiser: action.payload}
    case GET_ADVERTISER_FAIL:
      return {...state, error: action.payload}
    case GET_SALES_SUCCESS:
      return {...state, sales: action.payload}
    case GET_SALES_FAIL:
      return {...state, error: action.payload}
    default:
      return state
  }
}

export default store
