import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import { Card, CardBody, CardTitle, Col, Container, Row, Table, Label, Button } from "reactstrap"
import { Formik, Form, ErrorMessage, Field } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "components/Common/Breadcrumb"
import CardBooking from "./CardBooking"
import { getBookingData, updateCampaignStatus } from "../../store/actions"
import { DateTime } from "luxon"
import CustomDownloadButton from "../../components/Common/CustomDownloadButton"
import CustomSelect from "../../components/Common/CustomSelect"
import { CampaignStatusFieldOptions } from "../../constants/common"

class BookingDetail extends Component {
  componentDidMount () {
    const { match = {}, getBooking } = this.props
    const { params = {} } = match
    const { id } = params

    if (id) {
      getBooking(id)
    }
  }

  render() {
    const { booking, updateCampaignStatus, history } = this.props
    const allSites = []
    const staticSites = []
    const ledSites = []

    let total_spots = 0

    if (booking && booking.Campaign && booking.Campaign.CampaignSites && booking.Campaign.CampaignSites.length) {
      for (let i = 0; i < booking.Campaign.CampaignSites.length; i++) {
        let selectedSite = booking.Campaign.CampaignSites[i]

        allSites.push(selectedSite)

        if (selectedSite.Site && selectedSite.Site.type) {
          if (selectedSite.Site.type === "static") {
            staticSites.push(selectedSite)
          }

          if (selectedSite.Site.type === "led") {
            ledSites.push(selectedSite)

            total_spots += selectedSite.total_spot
          }
        }
      }
    }

    return (
      !booking ? <React.Fragment /> :
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Booking Detail | Nord & Smith Dashboard</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Bookings" breadcrumbItem="Booking Detail" />
            <Row>
              <Col md="6">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      <Row className="mb-3">
                        <Col md="6">
                          <h5>Booking Detail</h5>
                        </Col>
                        <Col md="6" className="text-sm-end">
                          <Link to={"/booking/edit/" + booking.id} className="btn btn-outline-primary btn-sm text-right mb-2">
                            <i className="bx bx-edit font-size-16 align-middle me-2"></i>
                            Edit Booking Detail
                          </Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12" className="bd-status mb-3">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              id: booking && booking.Campaign ? booking.Campaign.id : "",
                              status: booking && booking.Campaign ? booking.Campaign.status : "",
                            }}
                            validationSchema={Yup.object().shape({
                              status: Yup.string().required("Please select status"),
                            })}
                            onSubmit={values => {
                              updateCampaignStatus(values, () => history.push("/booking"))
                            }}
                            >
                            {({ errors, status, touched, handleSubmit }) => (
                              <Form className="needs-validation" onSubmit={handleSubmit}>
                                <Row>
                                  <Col md="3">
                                    <Label>Status</Label>
                                  </Col>
                                  <Col md="6">
                                    <Field
                                        name="status"
                                        placeholder="Status"
                                        options={[{label: "Status", options: CampaignStatusFieldOptions}]}
                                        component={CustomSelect}
                                        classNamePrefix="select2-selection"
                                    />
                                    <ErrorMessage name="status" component="div" className="invalid-feedback" />
                                  </Col>
                                  <Col md="3">
                                    <Button color="secondary" type="submit" className="block">Save</Button>
                                  </Col>
                                </Row>
                              </Form>
                            )}
                          </Formik>
                        </Col>
                      </Row>
                    </CardTitle>
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <th width="200">Create Date</th>
                          <td>{DateTime.fromSeconds(booking.created).toFormat("dd MMM y")}</td>
                        </tr>
                        <tr>
                          <th width="200">Campaign Category</th>
                          <td>
                            {
                              booking.Campaign.category === 'advertisement'? (
                                <span className="badge bg-primary font-size-11 m-1">Advertisement</span>
                              ) :
                                  booking.Campaign.category === 'psa'?(
                                <span className="badge bg-success font-size-11 m-1">PSA</span>
                              ) : null
                            }
                          </td>
                        </tr>
                        <tr>
                          <th width="200">Advertiser</th>
                          <td>{booking.advertiser}</td>
                        </tr>
                        <tr>
                          <th width="200">Sub-Industry</th>
                          <td>{booking.sub_industry}</td>
                        </tr>
                        <tr>
                          <th width="200">Sites</th>
                          <td>
                            {map(
                                allSites,
                                (site, index) => <Link to={"/sites/view/" + site.Site.id} className="badge badge-soft-primary font-size-11 m-1" key={"_site_" + site.Site.id + index}>{site.Site.name}</Link>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th width="200">Period start - end</th>
                          <td>{booking.Campaign.period_start} - {booking.Campaign.period_end}</td>
                        </tr>
                        <tr>
                          <th width="200">Contact person</th>
                          <td>{booking.cp_name}</td>
                        </tr>
                        <tr>
                          <th width="200">CP Phone</th>
                          <td>{booking.cp_phone}</td>
                        </tr>
                        <tr>
                          <th width="200">CP Email</th>
                          <td>{booking.cp_email}</td>
                        </tr>
                        <tr>
                          <th width="200">Company</th>
                          <td>{booking.company}</td>
                        </tr>
                        <tr>
                          <th width="200">Sales Name</th>
                          <td>{booking.sales}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Row>
                  <Col md="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Bookiing ID</small>
                            <h5 className="mb-0">{booking.id}</h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-donate-blood font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Campaign Name</small>
                            <h5 className="mb-0">{booking.Campaign.name}</h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-pen font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Purchase No.</small>
                            <h5>{booking.purchase_no}</h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-badge-check font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Total Spot (LED)</small>
                            <h5 className="mb-0">{total_spots}</h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-sitemap font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Period</small>
                            <h5 className="mb-0">
                              {booking.Campaign.period_start} - {booking.Campaign.period_end}
                            </h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-message-square-check font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">PO Value</small>
                            <h5 className="mb-0">Rp {new Intl.NumberFormat('id-ID', { maximumFractionDigits: 2, minimumFractionDigits: 0 }).format(booking.po_value)}</h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-dollar-circle font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            {
                              booking.files && booking.files.po_file &&
                              <div>
                                <small className="text-muted fw-medium mb-2 display-block">Download PO Files</small>
                                <CustomDownloadButton to={booking.files.po_file.name} className="btn btn-outline-primary text-right mb-2 mr-10" mimetype={booking.files.po_file.mimetype}>
                                  <i className="bx bx-download font-size-16 align-middle me-2"></i>
                                  Download PO File
                                </CustomDownloadButton>
                              </div>
                            }
                            {
                                booking.files && booking.files.spk_files && booking.files.spk_files.length &&
                                <div>
                                  <small className="text-muted fw-medium mb-2 display-block">Download SPK Files</small>
                                  {map(
                                      booking.files.spk_files,
                                      (spk_file, index) =>
                                          <CustomDownloadButton to={booking.files.spk_files[index].name} className="btn btn-outline-primary text-right mb-2 mr-10" key={"spk_files_" + index} mimetype={booking.files.spk_files[index].mimetype}>
                                            <i className="bx bx-download font-size-16 align-middle me-2"></i>
                                            Download SPK File
                                          </CustomDownloadButton>
                                  )}
                                </div>
                            }
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-message-x font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              {
                ledSites && ledSites.length > 0 &&
                <Col md="6">
                  <h5>Distributions - LED</h5>
                  <Row>
                    <Col md={12}>
                      {
                        ledSites.map((data, index) => <CardBooking site={data} key={'static-' + index} />)
                      }
                    </Col>
                  </Row>
                </Col>
              }
              {
                staticSites && staticSites.length > 0 &&
                <Col md="6">
                  <h5>Distributions - Static</h5>
                  <Row>
                    <Col md={12}>
                      {
                        staticSites.map((data, index) => <CardBooking site={data} key={'static-' + index} />)
                      }
                    </Col>
                  </Row>
                </Col>
              }
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

BookingDetail.propTypes = {
  history: PropTypes.object,
  error: PropTypes.string,
  match: PropTypes.object,
  booking: PropTypes.object,
  getBooking: PropTypes.func,
  updateCampaignStatus: PropTypes.func
}

const mapStateToProps = ({ booking, sites }) => ({
  error: booking.error,
  booking: booking.bookingData && booking.bookingData.data ? booking.bookingData.data : null
})

const mapDispatchToProps = dispatch => ({
  getBooking: id => dispatch(getBookingData(id)),
  updateCampaignStatus: (data, callback) => dispatch(updateCampaignStatus(data, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingDetail))