import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import DeleteModal from "components/Common/DeleteModal"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { getPlayers } from "../../store/actions"
import { DateTime } from "luxon"

const { ExportCSVButton } = CSVExport

class MlpMappingList extends Component {
  componentDidMount() {
    const { getPlayers } = this.props

    getPlayers()
  }

  onClickDelete = (mappings) => {
    this.setState({ mapping: mappings })
    this.setState({ deleteModal: true })
  }

  render() {
    const { players, loading } = this.props

    console.log(players)

    const columns = [
      {
        dataField: 'player_id',
        text: 'ID Player',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'player_name',
        text: 'Player Name',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'pillar_name',
        text: 'Pillar Name',
        sort: true,
        csvExport: true,
        formatter: (cellContent, item) => {
          if (item && item.PlayerMap && item.PlayerMap.Pillar) {
            return <Link to={"/pillars/view/" + item.PlayerMap.Pillar.id}>{item.PlayerMap.Pillar.name}</Link>
          }

          return '-'
        },
        csvFormatter: (cellContent, item) => {
          if (item && item.PlayerMap && item.PlayerMap.Pillar) {
            return item.PlayerMap.Pillar.name
          }

          return ''
        }
      },
      {
        dataField: 'updated',
        text: 'Last Updated',
        sort: true,
        csvExport: true,
        formatter: (cellContent, item) => {
          if (item && item.PlayerMap) {
            return DateTime.fromSeconds(item.PlayerMap.updated).toFormat("dd MMM y, HH:mm:ss")
          }

          return '-'
        },
        csvFormatter: (cellContent, item) => {
          if (item && item.PlayerMap) {
            return DateTime.fromSeconds(item.PlayerMap.updated).toFormat("dd MMM y, HH:mm:ss")
          }

          return ''
        }
      },
      {
        dataField: 'updateby',
        text: 'Updated By',
        sort: true,
        csvExport: true,
        formatter: (cellContent, item) => {
          if (item && item.PlayerMap && item.PlayerMap.User) {
            return item.PlayerMap.User.name
          }

          return '-'
        },
        csvFormatter: (cellContent, item) => {
          if (item && item.PlayerMap && item.PlayerMap.User) {
            return item.PlayerMap.User.name
          }

          return ''
        }
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        csvExport: false,
        formatter: (cellContent, item) => (
          <>
            <div className="d-flex gap-3">
              <Link to={"/mlp-mapping-edit/" + item.player_id} className="badge badge-soft-secondary" title="Edit">
                <i className="bx bx-edit font-size-18"/>
              </Link>
            </div>
          </>
        ),
      }
    ]

    this.onClickDelete = this.onClickDelete.bind(this)

    const pageOptions = { sizePerPage: 10, totalSize: players.length, custom: true }
    const { SearchBar } = Search

    return (
      <React.Fragment>
        <DeleteModal show={false} onDeleteClick={this.handleDeleteOrder} onCloseClick={() => this.setState({ deleteModal: false })}/>
        <div className="page-content">
          <MetaTags>
            <title>Media Log Proof Mapping | Nord & Smith Dashboard</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Media Log Proof" breadcrumbItem="Mapping List" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={columns} data={players}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider keyField='id' columns={columns} data={players} search>
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar{...toolkitProps.searchProps}/>
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="text-sm-end">
                                    <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-secondary mr-10">Export to CSV</ExportCSVButton>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      hover={true}
                                      classes={"table align-middle table-nowrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      noDataIndication={loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone{...paginationProps}/>
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone{...paginationProps}/>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

MlpMappingList.propTypes = {
  players: PropTypes.array,
  loading: PropTypes.bool,
  getPlayers: PropTypes.func
}

const mapStateToProps = ({ player }) => ({
  players: player && player.players && player.players.data ? player.players.data : [],
  loading: player.loading
})

const mapDispatchToProps = dispatch => ({
  getPlayers: () => dispatch(getPlayers())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpMappingList))