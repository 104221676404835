import React, { Component } from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import { getProfile } from "./store/actions"

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.getLayout = this.getLayout.bind(this)
  }

  componentDidMount () {
    const { onGetProfile, history } = this.props

    if (localStorage) {
      let accessToken = localStorage.getItem("accessToken")
      if (accessToken && accessToken.length) {
        onGetProfile(history)
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { onGetProfile, history } = this.props

    if (localStorage) {
      let accessToken = localStorage.getItem("accessToken")
      if (accessToken && accessToken.length) {
        onGetProfile(history)
      }
    }
  }

  getLayout = () => {
    let layoutCls = VerticalLayout
    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  render() {
    const Layout = this.getLayout()
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute path={route.path} layout={NonAuthLayout} component={route.component} key={idx} isAuthProtected={false} />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute path={route.path} layout={Layout} component={route.component} key={idx} isAuthProtected={true} exact />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    )
  }
}

App.propTypes = {
  layout: PropTypes.object,
  onGetProfile: PropTypes.func,
  history: PropTypes.object
}

const mapStateToProps = state => ({
  layout: state.Layout
})

const mapDispatchToProps = dispatch => ({
  onGetProfile: history => dispatch(getProfile(history))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
