import { combineReducers } from "redux"
import Layout from "./layout/reducer"
import Login from "./auth/login/reducer"
// import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import ecommerce from "./e-commerce/reducer"
// import calendar from "./calendar/reducer"
import chat from "./chat/reducer"
// import crypto from "./crypto/reducer"
// import invoices from "./invoices/reducer"
// import projects from "./projects/reducer"
// import tasks from "./tasks/reducer"
import users from "./users/reducer"
// import mails from "./mails/reducer";
import Dashboard from "./dashboard/reducer";
import DashboardSaas from "./dashboard-saas/reducer";
import booking from "./booking/reducer"
import sites from "./sites/reducer"
import pillars from "./pillars/reducer"
import campaigns from "./campaign/reducer"
import common from "./common/reducer"
import contacts from "./contacts/reducer"
import log from "./log/reducer"
import player from "./player/reducer"
import account from "./accounts/reducer"

const rootReducer = combineReducers({
  Layout,
  Login,
  account,
  ForgetPassword,
  Profile,
  ecommerce,
  // calendar,
  // chat,
  // mails,
  // crypto,
  // invoices,
  // projects,
  // tasks,
  contacts,
  users,
  sites,
  pillars,
  campaigns,
  log,
  Dashboard,
  DashboardSaas,
  common,
  booking,
  player
})

export default rootReducer
