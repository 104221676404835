import { takeEvery, put, call } from "redux-saga/effects"
import { FORGET_PASSWORD, RECOVER_ACCOUNT } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError, userRecoverAccountSuccess, userRecoverAccountError } from "./actions"
import { forgotPassword, recoverAccount } from "./../../../helpers/api_v1_helper"

function* forgetUser({ payload: data }) {
  try {
    yield call(forgotPassword, data)

    yield put(
      userForgetPasswordSuccess("Reset link are sent to your mailbox, please check your inbox")
    )
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

function* recoverUser({ payload: { data, history } }) {
  try {
    yield call(recoverAccount, data)

    yield put(
      userRecoverAccountSuccess("Your password has been set, please login with your new password")
    )

    setTimeout(() => {
      history.push("/login")
    }, 1500)
  } catch (error) {
    yield put(userRecoverAccountError(error))
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(RECOVER_ACCOUNT, recoverUser)
}

export default forgetPasswordSaga