import PropTypes from "prop-types"
import React, { Component } from "react"
import { Alert, Button, Card, CardBody, Col, Container, Row, Label, CardTitle, Table, CardHeader} from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import Breadcrumb from "../../components/Common/Breadcrumb"
import profile1 from "assets/images/profile-img.png"
import { editProfile } from "../../store/actions"
import { UserRoleFieldOptions } from "constants/common"

class UserProfile extends Component {
  getRole (user) {
    let role = UserRoleFieldOptions.find(item => item.value === user.role)

    return role ? role.label : user.role
  }

  render() {
    const { profile, editProfile } = this.props
    return (
      profile &&
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Account" breadcrumbItem="My Profile" />
            {this.props.error && this.props.error ? (<Alert color="danger">{this.props.error}</Alert>) : null}
              <Row>
                <Col xl="4" md="6">
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col xs="7">
                          <div className="text-primary p-3">
                            <h5 className="text-primary">Welcome Back!</h5>
                          </div>
                        </Col>
                        <Col xs="5" className="align-self-end">
                          <img src={profile1} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <Row>
                        <Col sm={12}>
                          <div className="avatar-md profile-user-wid mb-4">
                            <div className="me-3">
                              <div className="avatar-md display-inherit">
                                <span className="avatar-title rounded-circle">
                                  {profile.name.at(0)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="table-responsive">
                            <Table className="table-nowrap mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row">Username</th>
                                  <td>: {profile.username}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Full Name</th>
                                  <td>: {profile.name}</td>
                                </tr>
                                <tr>
                                  <th scope="row">E-mail</th>
                                  <td>: {profile.email}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Role</th>
                                  <td>: {this.getRole(profile)}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl="8" md="6" className="">
                  <Card>
                    <CardHeader className="bg-transparent border-bottom card-header">
                      <CardTitle className="mb-4 h4">Edit My Profile</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Formik enableReinitialize={true}
                        initialValues={{
                          name: profile.name,
                          email: profile.email
                        }}
                        validationSchema={Yup.object().shape({
                          name: Yup.string().required("Please enter Your name"),
                          email: Yup.string().required("Please enter Your email")
                        })}
                        onSubmit={values => {
                          editProfile(values)
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3 row">
                              <Label for="name" className="col-md-2 col-form-label">Full Name</Label>
                              <Col md={10}>
                                <Field name="name" type="text" className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")} />
                              </Col>
                              <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="mb-3 row">
                              <Label for="email" className="col-md-2 col-form-label">Email</Label>
                              <Col md={10}>
                                <Field name="email" type="email" className={"form-control" + (errors.email && touched.email ? " is-invalid" : "")} />
                              </Col>
                              <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                            </div>
                            <div className="mt-4 row">
                              <Col md={12} className="text-right">
                                <Button type="submit" color="success">Submit</Button>
                              </Col>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  profile: PropTypes.object
}

const mapStateToProps = state => {
  const { profile } = state.Profile
  return { profile }
}

export default withRouter(connect(mapStateToProps, { editProfile })(UserProfile))
