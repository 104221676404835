import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Button, Label, FormGroup, Table } from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getPillars, createPlayerMap } from "../../store/actions"
import CustomSelect from "../../components/Common/CustomSelect"

class MlpMappingCreate extends Component {
  componentDidMount() {
    const { getPillars } = this.props

    getPillars()
  }

  render() {
    const { pillars, createPlayerMap } = this.props
    const pillarMap = pillars.map(item => ({ value: item.id, label: item.name }))

    return (
      <>
        <div className="page-content">
          <MetaTags>
            <title>Player Mapping | Nord & Smith Dashboard</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Player Mapping" breadcrumbItem="New Player" />
              <Row>
                <Col md="12">
                  <Formik
                    enableReinitialize={false}
                    initialValues={{
                      player_id: '',
                      player_name: '',
                      player_sn: '',
                      pillar_id: '',
                    }}
                    validationSchema={Yup.object().shape({
                      player_id: Yup.string().required("Please enter Player Id"),
                      player_name: Yup.string().required("Please enter Player Name"),
                      player_sn: Yup.string(),
                      pillar_id: Yup.string().required("Please select Pillar Name")
                    })}
                    onSubmit={values => {
                      let newValues = Object.keys(values).reduce((r, i) => {
                        let cur = values[i]

                        if (cur && cur.value) {
                          r[i] = cur.value
                        } else {
                          r[i] = cur
                        }

                        return r
                      }, {})

                      createPlayerMap(newValues, () => history.push("/mlp-mapping-list"))
                    }}
                    >
                    {({ handleSubmit, isSubmitting }) => (
                      <Form className="needs-validation" onSubmit={handleSubmit}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label>ID Player</Label>
                                  <Field
                                    name="player_id"
                                    type="text"
                                    className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label>Player Name</Label>
                                  <Field
                                      name="player_name"
                                      type="text"
                                      className="form-control"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup className="mb-3">
                                  <Label>Pillar</Label>
                                  <Field
                                      name="pillar_id"
                                      placeholder="Select Pillar"
                                      options={[{ label: "Select Pillar", options: pillarMap }]}
                                      component={CustomSelect}
                                      classNamePrefix="select2-selection"
                                  />
                                  <ErrorMessage name="pillar_id" component="div" className="invalid-feedback"/>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12" className="text-right">
                                <FormGroup className="mb-3">
                                  <Button color="primary" type="submit" disabled={isSubmitting}>{ isSubmitting ? 'Saving' : 'Save'}</Button>
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
          </Container>
        </div>
      </>
    );
  }
}

MlpMappingCreate.propTypes = {
  pillars: PropTypes.array,
  getPillars: PropTypes.func,
  createPlayerMap: PropTypes.func
}

const mapStateToProps = ({ player, pillars }) => ({
  player: player && player.playerData && player.playerData.data ? player.playerData.data : null,
  pillars: pillars.pillars && pillars.pillars.data ? pillars.pillars.data : []
})

const mapDispatchToProps = dispatch => ({
  getPillars: () => dispatch(getPillars()),
  createPlayerMap: (data, callback) => dispatch(createPlayerMap(data, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpMappingCreate))