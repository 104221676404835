import { call, put, takeEvery } from "redux-saga/effects"
import {
  ADD_STATIC_NEW_LOG,
  GET_LED_LOGS, GET_LED_LOGS_BY_CAMPAIGN,
  GET_LED_LOGS_BY_SITE,
  GET_RAW_LOGS, GET_STATIC_LOG_DATA,
  GET_STATIC_LOGS, GET_STATIC_LOGS_BY_CAMPAIGN,
  GET_STATIC_LOGS_BY_SITE, IMPORT_LED_LOGS, GET_LED_SUMMARY, GET_LED_SUMMARY_BY_CAMPAIGN
} from "./actionTypes"
import {
  getLedLogsSuccess,
  getLedLogsFail,
  getLedLogsBySiteSuccess,
  getLedLogsBySiteFail,
  getStaticLogsSuccess,
  getStaticLogsFail,
  getStaticLogsBySiteSuccess,
  getStaticLogsBySiteFail,
  getRawLogsSuccess,
  getRawLogsFail,
  addNewStaticLogSuccess,
  addNewStaticLogFail,
  getStaticLogDataSuccess,
  getStaticLogDataFail,
  getLedLogsByCampaignSuccess,
  getLedLogsByCampaignFail,
  getStaticLogsByCampaignSuccess,
  getStaticLogsByCampaignFail, importLedLogsSuccess, importLedLogsFail, getLedSummarySuccess, getLedSummaryFail,
  getLedSummaryByCampaignSuccess, getLedSummaryByCampaignFail
} from "./actions";
import { getLedLogs, getLedLogsBySite, getStaticLogs, getStaticLogsBySite, getRawLogs, addStaticLog, getStaticLogData, getLedLogsByCampaign, getStaticLogsByCampaign, importRawLedLogs, getLedSummary, getLedSummaryByCampaign } from "../../helpers/api_v1_helper"

function* fetchLedLogs({ payload: queries }) {
  try {
    const response = yield call(getLedLogs, queries)

    yield put(getLedLogsSuccess(response))
  } catch (error) {
    yield put(getLedLogsFail(error))
  }
}

function* fetchLedLogsBySite({ payload: {siteId, query} }) {
  try {
    const response = yield call(getLedLogsBySite, siteId, query)

    yield put(getLedLogsBySiteSuccess(response))
  } catch (error) {
    yield put(getLedLogsBySiteFail(error))
  }
}

function* fetchLedLogsByCampaign({ payload: {campaignId, query} }) {
  try {
    const response = yield call(getLedLogsByCampaign, campaignId, query)

    yield put(getLedLogsByCampaignSuccess(response))
  } catch (error) {
    yield put(getLedLogsByCampaignFail(error))
  }
}

function* fetchStaticLogs({ payload: queries }) {
  try {
    const response = yield call(getStaticLogs, queries)

    yield put(getStaticLogsSuccess(response))
  } catch (error) {
    yield put(getStaticLogsFail(error))
  }
}

function* fetchStaticLogData({ payload: id }) {
  try {
    const response = yield call(getStaticLogData, id)

    yield put(getStaticLogDataSuccess(response))
  } catch (error) {
    yield put(getStaticLogDataFail(error))
  }
}

function* fetchStaticLogsBySite({ payload: {siteId, query} }) {
  try {
    const response = yield call(getStaticLogsBySite, siteId, query)

    yield put(getStaticLogsBySiteSuccess(response))
  } catch (error) {
    yield put(getStaticLogsBySiteFail(error))
  }
}

function* fetchStaticLogsByCampaign({ payload: {campaignId, query} }) {
  try {
    const response = yield call(getStaticLogsByCampaign, campaignId, query)

    yield put(getStaticLogsByCampaignSuccess(response))
  } catch (error) {
    yield put(getStaticLogsByCampaignFail(error))
  }
}

function* fetchRawLogs({ payload: queries }) {
  try {
    const response = yield call(getRawLogs, queries)

    yield put(getRawLogsSuccess(response))
  } catch (error) {
    yield put(getRawLogsFail(error))
  }
}

function* onAddNewStaticLog({ payload: { log, callback } }) {
  try {
    const response = yield call(addStaticLog, log)

    yield put(addNewStaticLogSuccess(response))

    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(addNewStaticLogFail(error))
  }
}

function* onImportLedRawLogs({ payload: { data, callback } }) {
  try {
    const response = yield call(importRawLedLogs, data)
    const { count } = response

    if (callback) {
      callback()
    }

    if (count && count > 0) {
      yield put(importLedLogsSuccess(`${count} data imported`))
    }
  } catch (error) {
    yield put(importLedLogsFail(error))
  }
}

function* fetchLedSummary({ payload: queries }) {
  try {
    const response = yield call(getLedSummary, queries)

    yield put(getLedSummarySuccess(response))
  } catch (error) {
    yield put(getLedSummaryFail(error))
  }
}

function* fetchLedSummaryByCampaign({ payload: {campaignId, query} }) {
  try {
    const response = yield call(getLedSummaryByCampaign, campaignId, query)

    yield put(getLedSummaryByCampaignSuccess(response))
  } catch (error) {
    yield put(getLedSummaryByCampaignFail(error))
  }
}

function* logSaga() {
  yield takeEvery(GET_LED_LOGS, fetchLedLogs)
  yield takeEvery(GET_LED_LOGS_BY_SITE, fetchLedLogsBySite)
  yield takeEvery(GET_LED_LOGS_BY_CAMPAIGN, fetchLedLogsByCampaign)
  yield takeEvery(GET_STATIC_LOGS, fetchStaticLogs)
  yield takeEvery(GET_STATIC_LOG_DATA, fetchStaticLogData)
  yield takeEvery(GET_STATIC_LOGS_BY_SITE, fetchStaticLogsBySite)
  yield takeEvery(GET_STATIC_LOGS_BY_CAMPAIGN, fetchStaticLogsByCampaign)
  yield takeEvery(GET_RAW_LOGS, fetchRawLogs)
  yield takeEvery(ADD_STATIC_NEW_LOG, onAddNewStaticLog)
  yield takeEvery(IMPORT_LED_LOGS, onImportLedRawLogs)
  yield takeEvery(GET_LED_SUMMARY, fetchLedSummary)
  yield takeEvery(GET_LED_SUMMARY_BY_CAMPAIGN, fetchLedSummaryByCampaign)
}

export default logSaga
