import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_ACCOUNT_DATA,
  GET_ACCOUNTS,
  ADD_NEW_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_SUCCESS, ADD_NEW_ACCOUNT_SUCCESS
} from "./actionTypes"
import {
  getAccountsSuccess,
  getAccountsFail,
  getAccountDataSuccess,
  getAccountDataFail,
  addNewAccountSuccess,
  addNewAccountFail,
  updateAccountSuccess,
  updateAccountFail,
  deleteAccountSuccess,
  deleteAccountFail
} from "./actions"
import { getAccounts, getAccountData, addNewAccount, updateAccount, deleteAccount } from "../../helpers/api_v1_helper"

function* fetchAccounts() {
  try {
    const response = yield call(getAccounts)

    yield put(getAccountsSuccess(response))
  } catch (error) {
    yield put(getAccountsFail(error))
  }
}

function* fetchAccountData({ payload: id }) {
  try {
    const response = yield call(getAccountData, id)

    yield put(getAccountDataSuccess(response))
  } catch (error) {
    yield put(getAccountDataFail(error))
  }
}

function* onAddNewAccount({ payload: { account, callback } }) {
  try {
    yield call(addNewAccount, account)

    yield put(addNewAccountSuccess("Account has been created"))

    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(addNewAccountFail(error))
  }
}

function* onUpdateAccount({ payload: { account, callback } }) {
  try {
    yield call(updateAccount, account)

    yield put(updateAccountSuccess("Account has been updated"))

    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(updateAccountFail(error))
  }
}

function* onDeleteAccount({ payload: { account, callback } }) {
  try {
    yield call(deleteAccount, account)

    yield put(deleteAccountSuccess("Account has been removed"))

    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(deleteAccountFail(error))

    if (callback) {
      callback()
    }
  }
}

function* accountsSaga() {
  yield takeEvery(GET_ACCOUNTS, fetchAccounts)
  yield takeEvery(GET_ACCOUNT_DATA, fetchAccountData)
  yield takeEvery(ADD_NEW_ACCOUNT, onAddNewAccount)
  yield takeEvery(UPDATE_ACCOUNT, onUpdateAccount)
  yield takeEvery(DELETE_ACCOUNT, onDeleteAccount)
  yield takeEvery(ADD_NEW_ACCOUNT_SUCCESS, fetchAccounts)
  yield takeEvery(UPDATE_ACCOUNT_SUCCESS, fetchAccounts)
  yield takeEvery(DELETE_ACCOUNT_SUCCESS, fetchAccounts)
}

export default accountsSaga
