import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import { Button, Card, CardBody, Col, Container, Form, FormGroup, InputGroup, Label, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import { Link, withRouter } from "react-router-dom"
import { Formik, Field, ErrorMessage } from "formik"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getSites, getSubIndustry, getAdvertiser, getSales, addNewBooking, resetBookingError } from "../../store/actions"
import { CampaignCategoryFieldOptions, OOHTypeFieldOptions } from "../../constants/common"
import * as Yup from "yup"
import CustomSelect from "../../components/Common/CustomSelect"
import CustomFlatpickr from "../../components/Common/CustomFlatpickr"
import CustomFileInput from "../../components/Common/CustomFileInput"
import AlertModal from "../../components/Common/AlertModal";

class BookingCreate extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 1,
      passedSteps: [1],
      rows: [],
    }

    this.toggleTab = this.toggleTab.bind(this)
  }

  componentDidMount() {
    const { getSites, getSubIndustry, getAdvertiser, getSales } = this.props

    getSites()
    getSubIndustry()
    getAdvertiser()
    getSales()
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        const modifiedSteps = [...this.state.passedSteps, tab]
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps
        })
      }
    }
  }

  handleAddRowNested = () => {
    const item = { name1: "" }

    this.setState({
      rows: [...this.state.rows, item],
    })
  }

  handleRemoveRowNested = (e, idx, formValues, setValues) => {
    const rows = this.state.rows
    const { ooh_type = [], site = [], total_spot = [], material_name = [] } = formValues

    if (rows[idx]) {
      rows.splice(idx, 1)
    }

    if (total_spot[idx + 1]) {
      ooh_type.splice(idx + 1, 1)
      site.splice(idx + 1, 1)
      total_spot.splice(idx + 1, 1)
      material_name.splice(idx + 1, 1)
    }

    this.setState({rows})

    setValues({ooh_type, site, total_spot, material_name})
  }

  render() {
    const { sites, addNewBooking, history, sub_industry, advertiser, sales, loading, message, error, resetBookingError } = this.props
    const subIndustries = sub_industry.map(item => ({ value: item.subind_name, label: item.subind_name }))
    const advertisers = advertiser.map(item => ({ value: item.adv_name, label: item.adv_name }))
    const optSales = sales.map(item => ({ value: item.name, label: item.name }))
    const sitesMap = {
      static: sites.filter(item => item.type == 'static').map(item => ({ value: item.id, label: item.name })),
      led: sites.filter(item => item.type == 'led').map(item => ({ value: item.id, label: item.name }))
    }

    let alertShow = false
    let alertTitle, alertMessage, alertType, alertCallback

    if (message && message.length) {
      alertShow = true
      alertTitle = 'Success'
      alertMessage = message
      alertType = 'success'
      alertCallback = () => history.push("/booking")
    }

    if (error && error.length) {
      alertShow = true
      alertTitle = 'Error'
      alertMessage = error
      alertType = 'error'
      alertCallback = () => resetBookingError()
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <AlertModal show={alertShow} onCloseClick={alertCallback} title={alertTitle} message={alertMessage} type={alertType} />
          <MetaTags>
            <title>Create New Booking | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Booking" breadcrumbItem="Create New" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <Formik
                        enableReinitialize={false}
                        initialValues={{
                          campaign_name: "",
                          purchase_number: "",
                          campaign_period: [],
                          po_value: "",
                          sales: "",
                          company: "",
                          campaign_category: "",
                          advertiser: "",
                          sub_industry: "",
                          cp_name: "",
                          cp_phone: "",
                          cp_email: "",
                          ooh_type: [],
                          site: [],
                          total_spot: [],
                          distribution_period: [],
                          material_name: [],
                          material_file: []
                        }}
                        validationSchema={Yup.object().shape({
                          campaign_name: Yup.string().required("Please enter campaign name"),
                          purchase_number: Yup.string(),
                          po_value: Yup.number(),
                          campaign_period: Yup.array(),
                          sales: Yup.string(),
                          company: Yup.string(),
                          campaign_category: Yup.string(),
                          advertiser: Yup.string(),
                          sub_industry: Yup.string(),
                          cp_name: Yup.string(),
                          cp_phone: Yup.string(),
                          cp_email: Yup.string(),
                          ooh_type: Yup.array(),
                          site: Yup.array(),
                          total_spot: Yup.array(),
                          distribution_period: Yup.array(),
                          material_name: Yup.array(),
                          material_file: Yup.array()
                        })}
                        onSubmit={values => {
                          const formData = new FormData()

                          let newValues = Object.keys(values).reduce((r, i) => {
                            let cur = values[i]
                            let val = cur && cur.value ? cur.value : cur

                            if (Array.isArray(val) && val.length) {
                              for (let n = 0; n < val.length; n++) {
                                let nVal = val[n]
                                if (Array.isArray(nVal) && nVal.length) {
                                  for (let o = 0; o < nVal.length; o++) {
                                    r.append(`${i}[${n}][${o}]`, nVal[o])
                                  }
                                } else {
                                  r.append(`${i}[${n}]`, nVal)
                                }
                              }
                            } else {
                              r.append(i, val)
                            }

                            return r
                          }, formData)

                          addNewBooking(newValues)
                        }}
                    >
                      {({ errors, values, touched, setValues, handleSubmit, setFieldValue }) => (
                        <Form className="needs-validation" onSubmit={handleSubmit}>
                          <div className="wizard clearfix">
                            <div className="steps clearfix">
                              <ul>
                                <NavItem className={classnames({current: this.state.activeTab === 1})}>
                                  <NavLink className={classnames({active: this.state.activeTab === 1})} onClick={() => this.toggleTab(1)}>
                                    <span className="number">1</span>{" "}Booking Details
                                  </NavLink>
                                </NavItem>
                                <NavItem className={classnames({current: this.state.activeTab === 2})}>
                                  <NavLink disabled={!(this.state.passedSteps || []).includes(2)} className={classnames({active: this.state.activeTab === 2,})} onClick={() => this.toggleTab(2)}>
                                    <span className="number">2</span>{" "}
                                    <span>Campaign Distributions</span>
                                  </NavLink>
                                </NavItem>
                                <NavItem className={classnames({current: this.state.activeTab === 3})}>
                                  <NavLink disabled={!(this.state.passedSteps || []).includes(3)} className={classnames({active: this.state.activeTab === 3,})} onClick={() => this.toggleTab(3)}>
                                    <span className="number">3</span>{" "}Document Files
                                  </NavLink>
                                </NavItem>
                              </ul>
                            </div>
                            <div className="content clearfix">
                              <TabContent activeTab={this.state.activeTab} className="body">
                                <TabPane tabId={1}>
                                    <Row>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="campaign_name">Campaign Name</Label>
                                          <Field
                                              id="campaign_name"
                                              name="campaign_name"
                                              placeholder="Enter campaign name"
                                              type="text"
                                              className={"form-control" + (errors.campaign_name && touched.campaign_name ? " is-invalid" : "")}
                                          />
                                          <ErrorMessage name="campaign_name" component="div" className="invalid-feedback" />
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="purchase_number">Purchase Number</Label>
                                          <Field
                                              id="purchase_number"
                                              name="purchase_number"
                                              placeholder="Enter purchase number"
                                              type="text"
                                              className={"form-control" + (errors.purchase_number && touched.purchase_number ? " is-invalid" : "")}
                                          />
                                          <ErrorMessage name="purchase_number" component="div" className="invalid-feedback" />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label>Campaign Period</Label>
                                          <InputGroup>
                                            <Field
                                                id="campaign_period"
                                                name="campaign_period"
                                                placeholder="Select period start - end"
                                                component={CustomFlatpickr}
                                                className={"form-control" + (errors.campaign_period && touched.campaign_period ? " is-invalid" : "")}
                                            />
                                            <ErrorMessage name="campaign_period" component="div" className="invalid-feedback" />
                                          </InputGroup>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="po_value">PO Value</Label>
                                          <InputGroup>
                                            <div className="input-group-text">Rp</div>
                                            <Field
                                                id="po_value"
                                                name="po_value"
                                                placeholder="Enter PO value"
                                                type="number"
                                                className={"form-control" + (errors.po_value && touched.po_value ? " is-invalid" : "")}
                                            />
                                            <ErrorMessage name="po_value" component="div" className="invalid-feedback" />
                                          </InputGroup>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="sales">Sales Name</Label>
                                          <Field
                                              id="sales"
                                              name="sales"
                                              placeholder="Select sales name"
                                              options={[{label: "Select Sales Name", options: optSales}]}
                                              component={CustomSelect}
                                              classNamePrefix="select2-selection"
                                          />
                                          <ErrorMessage name="sales" component="div" className="invalid-feedback" />
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="created_date">Created date</Label>
                                          <InputGroup>
                                            <Flatpickr className="form-control forbidden" placeholder="Select create date" options={{altInput: true, altFormat: "F j, Y", dateFormat: "Y-m-d", defaultDate: "today", allowInput: false, clickOpens: false,}}/>
                                          </InputGroup>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="company">Company</Label>
                                          <Field
                                              id="company"
                                              name="company"
                                              placeholder="Enter company name"
                                              type="text"
                                              className={"form-control" + (errors.company && touched.company ? " is-invalid" : "")}
                                          />
                                          <ErrorMessage name="company" component="div" className="invalid-feedback" />
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="campaign_category">Campaign Category</Label>
                                          <Field
                                              id="campaign_category"
                                              name="campaign_category"
                                              placeholder="Select Campaign Category"
                                              options={[{label: "Select Campaign Category", options: CampaignCategoryFieldOptions}]}
                                              component={CustomSelect}
                                              classNamePrefix="select2-selection"
                                          />
                                          <ErrorMessage name="campaign_category" component="div" className="invalid-feedback" />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="advertiser">Advertiser / Brand</Label>
                                          <Field
                                              id="advertiser"
                                              name="advertiser"
                                              placeholder="Select advertiser"
                                              options={[{label: "Select Advertiser", options: advertisers}]}
                                              component={CustomSelect}
                                              classNamePrefix="select2-selection"
                                          />
                                          <ErrorMessage name="advertiser" component="div" className="invalid-feedback" />
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="sub_industry">Sub-Industry</Label>
                                          <Field
                                              id="sub_industry"
                                              name="sub_industry"
                                              placeholder="Select Sub-Industry"
                                              options={[{label: "Select Sub Industry", options: subIndustries}]}
                                              component={CustomSelect}
                                              classNamePrefix="select2-selection"
                                          />
                                          <ErrorMessage name="sub_industry" component="div" className="invalid-feedback" />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="cp_name">Contact Person</Label>
                                          <Field
                                              id="cp_name"
                                              name="cp_name"
                                              placeholder="Enter contact person"
                                              type="text"
                                              className={"form-control" + (errors.cp_name && touched.cp_name ? " is-invalid" : "")}
                                          />
                                          <ErrorMessage name="cp_name" component="div" className="invalid-feedback" />
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="cp_phone">CP Phone</Label>
                                          <Field
                                              id="cp_phone"
                                              name="cp_phone"
                                              placeholder="Enter CP phone"
                                              type="text"
                                              className={"form-control" + (errors.cp_phone && touched.cp_phone ? " is-invalid" : "")}
                                          />
                                          <ErrorMessage name="cp_phone" component="div" className="invalid-feedback" />
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup className="mb-3">
                                          <Label for="cp_email">CP Email</Label>
                                          <Field
                                              id="cp_email"
                                              name="cp_email"
                                              placeholder="Enter CP email"
                                              type="text"
                                              className={"form-control" + (errors.cp_email && touched.cp_email ? " is-invalid" : "")}
                                          />
                                          <ErrorMessage name="cp_email" component="div" className="invalid-feedback" />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={2}>
                                  <div data-repeater-list="outer-group" className="outer">
                                      <div data-repeater-item className="outer">
                                        <div className="inner-repeater mb-4">
                                          <table style={{ width: "100%" }}>
                                            <tbody>
                                              <tr id="addrMain" key="">
                                                <td>
                                                  <Row className="inner mb-3">
                                                    <Col md="10">
                                                      <Row>
                                                        <Col>
                                                          <FormGroup className="mb-3">
                                                            <Label for="ooh_type_1">Select OOH Type</Label>
                                                            <Field
                                                                id="ooh_type_1"
                                                                name="ooh_type[0]"
                                                                placeholder="Select OOH Type"
                                                                options={[{label: "Select OOH Type", options: OOHTypeFieldOptions}]}
                                                                component={CustomSelect}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                            <ErrorMessage name="ooh_type[0]" component="div" className="invalid-feedback" />
                                                          </FormGroup>
                                                        </Col>
                                                        <Col>
                                                          <FormGroup className="mb-3">
                                                            <Label for="site_1">Select Site</Label>
                                                            <Field
                                                                id="site_1"
                                                                name="site[0]"
                                                                placeholder="Select site"
                                                                options={[{label: "Select Site Static", options: values && values.ooh_type && values.ooh_type.length ? sitesMap[values.ooh_type[0]] : []}]}
                                                                component={CustomSelect}
                                                                classNamePrefix="select2-selection"
                                                            />
                                                            <ErrorMessage name="site[0]" component="div" className="invalid-feedback" />
                                                          </FormGroup>
                                                        </Col>
                                                      </Row>
                                                      <Row>
                                                        <Col>
                                                          <FormGroup className="mb-3">
                                                            <Label for="total_spot_1">Total Spot</Label>
                                                            <Field
                                                                id="total_spot_1"
                                                                name="total_spot[0]"
                                                                placeholder="Enter Total Spot"
                                                                type="text"
                                                                className={"form-control" + (errors.total_spot && touched.total_spot ? " is-invalid" : "")}
                                                            />
                                                            <ErrorMessage name="total_spot[0]" component="div" className="invalid-feedback" />
                                                          </FormGroup>
                                                        </Col>
                                                        <Col>
                                                          <FormGroup className="mb-3">
                                                            <Label>Distribution Period</Label>
                                                            <InputGroup>
                                                              <Field
                                                                  id="distribution_period_1"
                                                                  name="distribution_period[0]"
                                                                  placeholder="Select period start - end"
                                                                  component={CustomFlatpickr}
                                                                  className={"form-control" + (errors.distribution_period && touched.distribution_period ? " is-invalid" : "")}
                                                              />
                                                              <ErrorMessage name="distribution_period[0]" component="div" className="invalid-feedback" />
                                                            </InputGroup>
                                                          </FormGroup>
                                                        </Col>
                                                      </Row>
                                                      <Row>
                                                        <Col>
                                                          <FormGroup className="mb-3">
                                                            <Label for="material_name_1">Material Name</Label>
                                                            <Field
                                                                id="material_name_1"
                                                                name="material_name[0]"
                                                                placeholder="Enter material name"
                                                                type="text"
                                                                className={"form-control" + (errors.material_name && touched.material_name ? " is-invalid" : "")}
                                                            />
                                                            <ErrorMessage name="material_name[0]" component="div" className="invalid-feedback" />
                                                          </FormGroup>
                                                        </Col>
                                                        <Col>
                                                          <FormGroup className="mb-3">
                                                            <Label>Upload Material</Label>
                                                            <Field
                                                                id="material_file_1"
                                                                name="material_file[0]"
                                                                placeholder="Upload material file"
                                                                component={CustomFileInput}
                                                                onChanged={name => setFieldValue('material_name[0]', name.join(','))}
                                                                multiple={true}
                                                                className={"form-control" + (errors.material_file && touched.material_file ? " is-invalid" : "")}
                                                            />
                                                            <ErrorMessage name="material_file[0]" component="div" className="invalid-feedback" />
                                                          </FormGroup>
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                    <Col md="2">
                                                      <FormGroup className="mb-3">
                                                        <Label for="total_spot">&nbsp;</Label>
                                                        <Button disabled color="primary" className="btn-block inner btn-light" style={{ width: "100%" }}>{" "}Delete</Button>
                                                      </FormGroup>
                                                    </Col>
                                                  </Row>
                                                </td>
                                              </tr>
                                              {this.state.rows.map((item1, idx) => (
                                                <tr id={"nested" + idx} key={idx} className="nested-box mb-3">
                                                  <td>
                                                    <Row className="inner">
                                                      <Col md="10" key={"col_" + idx + "_1"}>
                                                        <Row key={"row_" + idx + "_1"}>
                                                          <Col>
                                                            <FormGroup className="mb-3">
                                                              <Label for={"ooh_type_" + (idx + 1)}>Select OOH Type</Label>
                                                              <Field
                                                                  id={"ooh_type_" + (idx + 1)}
                                                                  name={`ooh_type[${idx + 1}]`}
                                                                  placeholder="Select OOH Type"
                                                                  options={[{label: "Select OOH Type", options: OOHTypeFieldOptions}]}
                                                                  component={CustomSelect}
                                                                  classNamePrefix="select2-selection"
                                                              />
                                                              <ErrorMessage name={`ooh_type[${idx + 1}]`} component="div" className="invalid-feedback" />
                                                            </FormGroup>
                                                          </Col>
                                                          <Col>
                                                            <FormGroup className="mb-3">
                                                              <Label for={"site_" + (idx + 1)}>Select Site</Label>
                                                              <Field
                                                                  id={"site_" + (idx + 1)}
                                                                  name={`site[${idx + 1}]`}
                                                                  placeholder="Select site"
                                                                  options={[{label: "Select Site Static", options: values && values.ooh_type && values.ooh_type.length > idx ? sitesMap[values.ooh_type[idx+1]] : []}]}
                                                                  component={CustomSelect}
                                                                  classNamePrefix="select2-selection"
                                                              />
                                                              <ErrorMessage name={`site[${idx + 1}]`} component="div" className="invalid-feedback" />
                                                            </FormGroup>
                                                          </Col>
                                                        </Row>
                                                        <Row key={"row_" + idx + "_2"}>
                                                          <Col>
                                                            <FormGroup className="mb-3">
                                                              <Label for={"total_spot_" + (idx + 1)}>Total Spot</Label>
                                                              <Field
                                                                  id={"total_spot_" + (idx + 1)}
                                                                  name={`total_spot[${idx + 1}]`}
                                                                  placeholder="Enter Total Spot"
                                                                  type="text"
                                                                  className={"form-control" + (errors.total_spot && touched.total_spot ? " is-invalid" : "")}
                                                              />
                                                              <ErrorMessage name={`total_spot[${idx + 1}]`} component="div" className="invalid-feedback" />
                                                            </FormGroup>
                                                          </Col>
                                                          <Col>
                                                            <FormGroup className="mb-3">
                                                              <Label>Distribution Period</Label>
                                                              <InputGroup>
                                                                <Field
                                                                    id={"distribution_period_" + (idx + 1)}
                                                                    name={`distribution_period[${idx + 1}]`}
                                                                    placeholder="Select period start - end"
                                                                    component={CustomFlatpickr}
                                                                    className={"form-control" + (errors.distribution_period && touched.distribution_period ? " is-invalid" : "")}
                                                                />
                                                                <ErrorMessage name={`distribution_period[${idx + 1}]`} component="div" className="invalid-feedback" />
                                                              </InputGroup>
                                                            </FormGroup>
                                                          </Col>
                                                        </Row>
                                                        <Row key={"row_" + idx + "_3"}>
                                                          <Col>
                                                            <FormGroup className="mb-3">
                                                              <Label for={"material_name_" + (idx + 1)}>Material Name</Label>
                                                              <Field
                                                                  id={"material_name_" + (idx + 1)}
                                                                  name={`material_name[${idx + 1}]`}
                                                                  placeholder="Enter material name"
                                                                  type="text"
                                                                  className={"form-control" + (errors.material_name && touched.material_name ? " is-invalid" : "")}
                                                              />
                                                              <ErrorMessage name={`material_name[${idx + 1}]`} component="div" className="invalid-feedback" />
                                                            </FormGroup>
                                                          </Col>
                                                          <Col>
                                                            <FormGroup className="mb-3">
                                                              <Label>Upload Material</Label>
                                                              <Label>Upload Material</Label>
                                                              <Field
                                                                  id={"material_file_" + (idx + 1)}
                                                                  name={`material_file[${idx + 1}]`}
                                                                  placeholder="Upload material file"
                                                                  component={CustomFileInput}
                                                                  onChanged={name => setFieldValue(`material_name[${idx + 1}]`, name.join(','))}
                                                                  multiple={true}
                                                                  className={"form-control" + (errors.material_file && touched.material_file ? " is-invalid" : "")}
                                                              />
                                                              <ErrorMessage name="material_file[0]" component="div" className="invalid-feedback" />
                                                            </FormGroup>
                                                          </Col>
                                                        </Row>
                                                      </Col>
                                                      <Col md="2" key={"col_" + idx + "_2"}>
                                                        <FormGroup>
                                                          <Label for="total_spot">&nbsp;</Label>
                                                          <Button onClick={e => this.handleRemoveRowNested(e, idx, values, setValues)} color="primary" className="btn-block inner btn-danger" style={{ width: "100%" }}>{" "}Delete</Button>
                                                        </FormGroup>
                                                      </Col>
                                                    </Row>
                                                  </td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                          <Button onClick={this.handleAddRowNested} color="success">Add more</Button>
                                        </div>
                                      </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId={3}>
                                  <div>
                                    <Row>
                                      <Col lg="12">
                                        <div className="mb-3">
                                          <Label for="po_file">Upload PO (Single select file)</Label>
                                          <Field
                                              id="po_file"
                                              name="po_file"
                                              placeholder="Upload PO Document"
                                              component={CustomFileInput}
                                              className={"form-control" + (errors.po_file && touched.po_file ? " is-invalid" : "")}
                                          />
                                          <ErrorMessage name="po_file" component="div" className="invalid-feedback" />
                                        </div>
                                      </Col>
                                      <Col lg="12">
                                        <div className="mb-3">
                                          <Label for="spk_file">Upload SPK (Multiple select file)</Label>
                                          <Field
                                              id="spk_file"
                                              name="spk_file"
                                              placeholder="Upload SPK Document"
                                              component={CustomFileInput}
                                              multiple={true}
                                              className={"form-control" + (errors.spk_file && touched.spk_file ? " is-invalid" : "")}
                                          />
                                          <ErrorMessage name="spk_file" component="div" className="invalid-feedback" />
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </div>
                            <div className="actions clearfix mt-20">
                              <ul>
                                <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}>
                                  <Link to="#" onClick={() => this.toggleTab(this.state.activeTab - 1)}>Previous</Link>
                                </li>
                                <li className={this.state.activeTab === 3 ? "next hide" : "next"}>
                                  <Link to="#" onClick={() => this.toggleTab(this.state.activeTab + 1)}>Next</Link>
                                </li>
                                <li className={this.state.activeTab === 3 ? "show" : "hide"}>
                                  <Button color="primary" type="submit" style={{padding: "6px 15px"}} disabled={loading}>{loading ? 'Saving' : 'Save'}</Button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

BookingCreate.propTypes = {
  sites: PropTypes.array,
  sub_industry: PropTypes.array,
  advertiser: PropTypes.array,
  sales: PropTypes.array,
  error: PropTypes.string,
  message: PropTypes.string,
  loading: PropTypes.bool,
  history: PropTypes.object,
  getSites: PropTypes.func,
  getSubIndustry: PropTypes.func,
  getAdvertiser: PropTypes.func,
  getSales: PropTypes.func,
  addNewBooking: PropTypes.func,
  resetBookingError: PropTypes.func
}

const mapStateToProps = ({ booking, sites, common }) => ({
  error: booking.error,
  message: booking.message,
  sites: sites.sites && sites.sites.data ? sites.sites.data : [],
  sub_industry: common.sub_industry,
  advertiser: common.advertiser,
  sales: common.sales,
  loading: booking.loading
})

const mapDispatchToProps = dispatch => ({
  getSites: queries => dispatch(getSites(queries)),
  getSubIndustry: () => dispatch(getSubIndustry()),
  getAdvertiser: () => dispatch(getAdvertiser()),
  getSales: () => dispatch(getSales()),
  addNewBooking: booking => dispatch(addNewBooking(booking)),
  resetBookingError: () => dispatch(resetBookingError())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingCreate))
