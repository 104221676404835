import {
  GET_PILLAR_PROFILE,
  GET_PILLAR_PROFILE_FAIL,
  GET_PILLAR_PROFILE_SUCCESS,
  GET_PILLARS,
  GET_PILLARS_FAIL,
  GET_PILLARS_SUCCESS,
  ADD_NEW_PILLAR,
  ADD_PILLAR_SUCCESS,
  ADD_PILLAR_FAIL,
  UPDATE_PILLAR,
  UPDATE_PILLAR_SUCCESS,
  UPDATE_PILLAR_FAIL,
  DELETE_PILLAR,
  DELETE_PILLAR_SUCCESS,
  DELETE_PILLAR_FAIL,
} from "./actionTypes"

export const getPillars = () => ({
  type: GET_PILLARS,
})

export const getPillarsSuccess = pillars => ({
  type: GET_PILLARS_SUCCESS,
  payload: pillars,
})

export const getPillarsFail = error => ({
  type: GET_PILLARS_FAIL,
  payload: error,
})

export const getPillarProfile = () => ({
  type: GET_PILLAR_PROFILE,
})

export const getPillarProfileSuccess = pillarProfile => ({
  type: GET_PILLAR_PROFILE_SUCCESS,
  payload: pillarProfile,
})

export const getPillarProfileFail = error => ({
  type: GET_PILLAR_PROFILE_FAIL,
  payload: error,
})

export const addNewPillar = pillar => ({
  type: ADD_NEW_PILLAR,
  payload: pillar,
})

export const addPillarSuccess = pillar => ({
  type: ADD_PILLAR_SUCCESS,
  payload: pillar,
})

export const addPillarFail = error => ({
  type: ADD_PILLAR_FAIL,
  payload: error,
})

export const updatePillar = pillar => ({
  type: UPDATE_PILLAR,
  payload: pillar,
})

export const updatePillarSuccess = pillar => ({
  type: UPDATE_PILLAR_SUCCESS,
  payload: pillar,
})

export const updatePillarFail = error => ({
  type: UPDATE_PILLAR_FAIL,
  payload: error,
})

export const deletePillar = pillar => ({
  type: DELETE_PILLAR,
  payload: pillar,
})

export const deletePillarSuccess = pillar => ({
  type: DELETE_PILLAR_SUCCESS,
  payload: pillar,
})

export const deletePillarFail = error => ({
  type: DELETE_PILLAR_FAIL,
  payload: error,
})
