import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, GET_PROFILE } from "./actionTypes"

export const getProfile = history => {
  return {
    type: GET_PROFILE,
    payload: history
  }
}

export const editProfile = data => {
  return {
    type: EDIT_PROFILE,
    payload: data
  }
}

export const profileSuccess = profile => {
  return {
    type: PROFILE_SUCCESS,
    payload: profile
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error
  }
}