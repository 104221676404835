import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { DateTime } from "luxon"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Row, InputGroup, FormGroup, Button, Spinner } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { getLedLogs } from "../../store/actions"

const { ExportCSVButton } = CSVExport

class MlpLedList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      queryParams: {}
    }

    this.flatPicker = React.createRef()
    this.onDateRangeChange = this.onDateRangeChange.bind(this)
    this.onTableChange = this.onTableChange.bind(this)
  }

  componentDidMount() {
    const { getLedLogs } = this.props

    getLedLogs()
  }

  onDateRangeChange (data) {
    const { getLedLogs } = this.props
    const [from = false, to = false] = data
    const queryParams = this.state.queryParams

    if (from) {
      queryParams.from = DateTime.fromJSDate(from).toFormat('y-MM-dd')
    }

    if (to) {
      queryParams.to = DateTime.fromJSDate(to).toFormat('y-MM-dd')
    }

    this.setState({ queryParams })

    getLedLogs(queryParams)
  }

  onTableChange (e, data) {
    const { getLedLogs } = this.props
    const { searchText, page, sizePerPage, sortField, sortOrder } = data
    const queryParams = this.state.queryParams

    queryParams.page = '1'
    queryParams.limit = '10'
    queryParams.search = ''

    if (!isNaN(page) && page > 1) {
      queryParams.page = page
    }

    if (!isNaN(sizePerPage) && sizePerPage > 2) {
      queryParams.limit = sizePerPage
    }

    if (searchText && searchText.length) {
      queryParams.search = searchText
    }

    this.setState({ queryParams })

    getLedLogs(queryParams)
  }

  render() {
    const { logs, loading } = this.props
    const { rows = [], limit = 10, count = 0, page } = logs || {}
    const columns = [
      {
        dataField: 'id',
        text: 'Log ID',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'site_name',
        text: 'Site Name',
        sort: true,
        csvExport: true,
        formatter: (cellContent, item) => item.PlayerMap && item.PlayerMap.Pillar && item.PlayerMap.Pillar.Site ? <Link to={"mlp-led-detail/" + item.PlayerMap.Pillar.Site.id}>{item.PlayerMap.Pillar.Site.name}</Link> : '',
        csvFormatter: (cellContent, item) => item.PlayerMap && item.PlayerMap.Pillar && item.PlayerMap.Pillar.Site ? item.PlayerMap.Pillar.Site.name : ''
      },
      {
        dataField: 'last_update',
        text: 'Last Update',
        sort: true,
        csvExport: true,
        formatter: (cellContent, item) => DateTime.fromSeconds(item.start_time).toFormat('dd MMM y'),
        csvFormatter: (cellContent, item) => DateTime.fromSeconds(item.start_time).toFormat('dd MMM y')
      },
      {
        dataField: "action",
        isDummyField: true,
        csvExport: false,
        text: "Action",
        formatter: (cellContent, item) => (
          <>
            <div className="d-flex gap-3">
              {
                item.PlayerMap && item.PlayerMap.Pillar && item.PlayerMap.Pillar.Site &&
                  <Link to={"/mlp-led-detail/" + item.PlayerMap.Pillar.Site.id} className="badge badge-soft-primary"
                        title="View">
                    <i className="mdi mdi-eye-outline font-size-18"/>
                  </Link>
              }
            </div>
          </>
        ),
      }
    ]

    const pageOptions = { sizePerPage: limit, totalSize: count, custom: true, page: Number(page) }
    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Media Log Proof Led | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Media Log Proof" breadcrumbItem="Led List" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={columns} data={rows}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider keyField='id' columns={columns} data={rows} search>
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar{...toolkitProps.searchProps}/>
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="text-sm-end">
                                    <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-secondary mr-5">Export to CSV</ExportCSVButton>
                                    <Button className="mr-5 btn-dark" onClick={() => this.flatPicker.current.flatpickr.clear()}>Reset</Button>
                                    <FormGroup className="btn-datepicker">
                                      <InputGroup>
                                        <Flatpickr className="form-control btn btn-outline-primary" placeholder="Select Date" options={{mode: "range", dateFormat: "Y-m-d"}} onChange={this.onDateRangeChange} ref={this.flatPicker} />
                                      </InputGroup>
                                    </FormGroup>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="id"
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      hover={true}
                                      classes="table align-middle table-nowrap"
                                      headerWrapperClasses="thead-light"
                                      noDataIndication={ loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      loading={true}
                                      remote={{filter: true, pagination: true, sort: true, cellEdit: false}}
                                      onTableChange={this.onTableChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone{...paginationProps}/>
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone{...paginationProps}/>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

MlpLedList.propTypes = {
  logs: PropTypes.object,
  message: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  getLedLogs: PropTypes.func,
  history: PropTypes.object
}

const mapStateToProps = ({ log }) => ({
  logs: log.logs_led && log.logs_led.data ? log.logs_led.data : null,
  message: log.log_led_message,
  error: log.log_led_error,
  loading: log.log_led_loading
})

const mapDispatchToProps = dispatch => ({
  getLedLogs: queries => dispatch(getLedLogs(queries))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpLedList))