import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import { Card, CardBody, CardTitle, Col, Container, Row, Table, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getSiteData } from "../../store/sites/actions"

class SiteDetail extends Component {
  componentDidMount () {
    const { getSiteData, match = {} } = this.props
    const { params = {} } = match
    const { id } = params

    if (id) {
      getSiteData(id)
    }
  }

  render() {
    const { site } = this.props

    return (
      site &&
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Site Detail | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Sites" breadcrumbItem="Site Detail" />
            <Row>
              <Col md="6">
                <Row>
                  <Col md="12">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Site Name</small>
                            <h5 className="mb-0">{site.name}</h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-carousel font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Lists of Pillar({site.number_set})</small>
                            {
                              map(site.Pillars, (pillar, index) => (
                                <Link to={"/pillars/view/" + pillar.id} className="badge badge-soft-primary font-size-11 m-1" key={"_skill_o_" + site.id + index}>
                                  {pillar.name}
                                </Link>
                              ))
                            }
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-list-ul font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  {
                    (site.Province || site.City || site.District) &&
                    <Col md="12">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <small className="text-muted fw-medium mb-2 display-block">Site Address</small>
                              <h5 className="mb-0">
                                {site.District ? site.District.name + ", " : ""}{site.City ? site.City.name + ", " : ""}{site.Province ? site.Province.name : ""}
                              </h5>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i className="bx bx-map font-size-24"/>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  }
                  <Col md="12">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Site Location</small>
                            <h5>
                              <a href={`https://maps.google.com/maps?q=${site.latitude},${site.longitude}`}
                                target="_blank" rel="noopener noreferrer">
                                  {site.latitude}, {site.longitude}
                                  <i className="bx bx-meteor ml-5"></i>
                              </a>
                            </h5>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-map-pin font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      <Row>
                        <Col md="6">
                          <h5>Site Detail</h5>
                        </Col>
                        <Col md="6" className="text-sm-end">
                          <Link to={"/sites/edit/" + site.id} className="btn btn-outline-primary btn-sm text-right mb-2">
                            <i className="bx bx-edit font-size-16 align-middle me-2"></i>
                            Edit Site Detail
                          </Link>
                        </Col>
                      </Row>
                    </CardTitle>
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <th width="200">Site ID</th>
                          <td>{site.id}</td>
                        </tr>
                        <tr>
                          <th width="200">Site Name</th>
                          <td>{site.name}</td>
                        </tr>
                        <tr>
                          <th width="200">Address</th>
                          <td>{site.address}</td>
                        </tr>
                        <tr>
                          <th width="200">Directional View</th>
                          <td>{site.directional_view}</td>
                        </tr>
                        <tr>
                          <th width="200">Lat, Long</th>
                          <td>{site.latitude}, {site.longitude}</td>
                        </tr>
                        {
                          site.Province &&
                          <tr>
                            <th width="200">Province</th>
                            <td>{site.Province.name}</td>
                          </tr>
                        }
                        {
                          site.City &&
                          <tr>
                            <th width="200">City</th>
                            <td>{site.City.name}</td>
                          </tr>
                        }
                        {
                          site.District &&
                          <tr>
                            <th width="200">District</th>
                            <td>{site.District.name}</td>
                          </tr>
                        }
                        <tr>
                          <th width="200">Orientation</th>
                          <td>{site.orientation}</td>
                        </tr>
                        <tr>
                          <th width="200">Side Position</th>
                          <td>{site.side_position}</td>
                        </tr>
                        <tr>
                          <th width="200">Uni Type</th>
                          <td>{site.type}</td>
                        </tr>
                        <tr>
                          <th width="200">Numbers Set</th>
                          <td>{site.number_set}</td>
                        </tr>
                        <tr>
                          <th width="200">Lists of Pillar</th>
                          <td>
                            {
                              map(site.Pillars, (pillar, index) => (
                                <Link to={"/pillars/view/" + pillar.id} className="badge badge-soft-primary font-size-11 m-1" key={"_skill_" + site.id + index}>
                                  {pillar.name}
                                </Link>
                              ))
                            }
                          </td>
                        </tr>
                        <tr>
                          <th width="200">Share Slot</th>
                          <td>
                            {site.share_slot ? (
                              <span className="font-size-12 badge badge-soft-success rounded-pill">Yes</span>
                            ) : (
                              <span className="font-size-12 badge badge-soft-danger rounded-pill">No</span>
                            )}
                            </td>
                        </tr>
                        <tr>
                          <th width="200">Total Spot Day</th>
                          <td>{site.total_spot_day}</td>
                        </tr>
                        <tr>
                          <th width="200">Last Update</th>
                          <td>{new Date(site.updated * 1000).toLocaleString()}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

SiteDetail.propTypes = {
  site: PropTypes.object,
  match: PropTypes.object,
  getSiteData: PropTypes.func
}

const mapStateToProps = ({ sites }) => ({
  site: sites.siteData && sites.siteData.data ? sites.siteData.data : null
})

const mapDispatchToProps = dispatch => ({
  getSiteData: id => dispatch(getSiteData(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SiteDetail))
