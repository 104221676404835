import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { DateTime } from "luxon"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { map } from "lodash"
import { Card, CardBody, Col, Container, Row, FormGroup, InputGroup, Button, Spinner } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Breadcrumbs from "components/Common/Breadcrumb"
import Select from "react-select"
import { getCampaignData, getLedLogsByCampaign, getStaticLogsByCampaign, getLedSummaryByCampaign } from "store/actions"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import CustomDownloadButton from "../../components/Common/CustomDownloadButton"
import CustomImage from "../../components/Common/CustomImage"

const options = [
  { value: '1', label: '1 hari' },
  { value: '3', label: '3 hari' },
  { value: '7', label: '7 hari' }
]

const { ExportCSVButton } = CSVExport
class MlpCampaignDetail extends Component {
  constructor (props) {
    super(props)

    this.flatPicker1 = React.createRef()
    this.flatPicker2 = React.createRef()
    this.onSelectSummaryTimerange = this.onSelectSummaryTimerange.bind(this)
  }

  componentDidMount() {
    const { getCampaignData, getLedLogsByCampaign, getStaticLogsByCampaign, getLedSummaryByCampaign, match = {} } = this.props
    const { params = {} } = match
    const { id } = params

    if (id) {
      getCampaignData(id)
      getLedLogsByCampaign(id)
      getStaticLogsByCampaign(id)
      getLedSummaryByCampaign(id)
    }
  }

  onSelectSummaryTimerange (opt) {
    const { getLedSummaryByCampaign, match = {} } = this.props
    const { params = {} } = match
    const { id } = params
    const { value } = opt
    const from = DateTime.local().minus({ days: value }).toFormat('y-MM-dd')

    if (id && value) {
      getLedSummaryByCampaign(id, { from })
    }
  }

  render() {
    const { campaign, log_leds, log_led_loading, log_statics, log_static_loading, log_led_summary_loading, log_led_summaries  } = this.props
    const staticColumns = [
      {
        dataField: 'id',
        text: 'Log ID',
        sort: true,
        csvExport: true
      },
      {
        dataField: 'Site.name',
        text: 'Site Name',
        sort: true,
        csvExport: true
      },
      {
        dataField: 'log_image',
        text: 'Log Image',
        sort: true,
        csvExport: false,
        formatter: (cell, item) => (
            map(item.data.files, (image, index) => <CustomDownloadButton to={image.name} mimetype={image.mimetype}><CustomImage src={image.name} className="img-table" key={"img_" + item.id + "_" + index} /></CustomDownloadButton>)
        )
      },
      {
        dataField: 'log_date',
        text: 'Log Created',
        sort: true,
        csvExport: true,
        formatter: (cell, item) => DateTime.fromSeconds(item.created).toFormat('dd MMM y - hh:mm:ss'),
        csvFormatter: (cell, item) => DateTime.fromSeconds(item.created).toFormat('dd MMM y - hh:mm:ss')
      },
    ]
    const ledColumns = [
      {
        dataField: 'id',
        text: 'Log ID',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'site_name',
        text: 'Site Name',
        sort: true,
        csvExport: true,
        formatter: (cellContent, item) => item.PlayerMap && item.PlayerMap.Pillar && item.PlayerMap.Pillar.Site ? item.PlayerMap.Pillar.Site.name : ''
      },
      {
        dataField: 'media_name',
        text: 'Media Name',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'duration',
        text: 'Duration',
        sort: true,
        csvExport: true,
        formatter: (cellContent, item) => item.duration + 's'
      },
      {
      dataField: 'log_start',
        text: 'Log Start',
        sort: true,
        csvExport: true,
        formatter: (cellContent, item) => DateTime.fromSeconds(item.start_time).toFormat('dd MMM y - hh:mm:ss')
      },
      {
        dataField: 'log_end',
        text: 'Log End',
        sort: true,
        csvExport: true,
        formatter: (cellContent, item) => DateTime.fromSeconds(item.end_time).toFormat('dd MMM y - hh:mm:ss')
      }
    ]
    const pageOptionsStatic = {sizePerPage: 10, totalSize: 0, custom: true}
    const pageOptionsLed = {sizePerPage: 10, totalSize: 0, custom: true}
    const { SearchBar } = Search
    const LedSummaries = log_led_summaries.rows.map(i => (
        <Row key={i.id}>
          <Col md="3">
            <Card className="mini-stats-wid bg-success bg-soft">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <small className="text-muted fw-medium mb-2 display-block">Site Name</small>
                    <h5 className="mb-0">{i.PlayerMap.Pillar.Site.name}</h5>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="5">
            <Card className="mini-stats-wid bg-success bg-soft">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <small className="text-muted fw-medium mb-2 display-block">Media Name</small>
                    <h5 className="mb-0">{i.media_name}</h5>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="2">
            <Card className="mini-stats-wid bg-success bg-soft">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <small className="text-muted fw-medium mb-2 display-block">Period</small>
                    <h5 className="mb-0">{DateTime.fromSeconds(i.start_time).toFormat('dd MMM y')}</h5>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="2">
            <Card className="mini-stats-wid bg-success bg-soft">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <small className="text-muted fw-medium mb-2 display-block">Total Played</small>
                    <h5 className="mb-0">{i.play_count}</h5>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
    ))

    return (
      !campaign ? <React.Fragment /> :
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Media Log Proof Campaign | Nord &amp Smith Dashboard</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Media Log Proof" breadcrumbItem="Campaign Detail" />
            <Row>
              <Col md="12">
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="12">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <small className="text-muted fw-medium mb-2 display-block">Campaign Name</small>
                                <h5 className="mb-0">{campaign.name}</h5>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i className="bx bxs-slideshow font-size-24"/>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      {
                        campaign.brand && campaign.brand.length &&
                        <Col md="12">
                          <Card className="mini-stats-wid">
                            <CardBody>
                              <div className="d-flex">
                                <div className="flex-grow-1">
                                  <small className="text-muted fw-medium mb-2 display-block">Brand Name</small>
                                  <h5 className="mb-0">{campaign.brand}</h5>
                                </div>
                                <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                  <span className="avatar-title">
                                    <i className="bx bxs-crown font-size-24"/>
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      }
                      <Col md="12">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <small className="text-muted fw-medium mb-2 display-block">Period</small>
                                <h5 className="mb-0">{campaign.period_start ? DateTime.fromFormat(campaign.period_start,'y-MM-dd').toFormat('dd MMM y') : ''} - {campaign.period_end ? DateTime.fromFormat(campaign.period_end,'y-MM-dd').toFormat('dd MMM y') : ''}</h5>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i className="bx bxs-calendar-event font-size-24"/>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <small className="text-muted fw-medium mb-2 display-block">Category</small>
                                {
                                  campaign.category.toLowerCase() === 'advertisement'? (
                                    <span className="btn btn-rounded btn-sm btn-primary">Advertisement</span>
                                  ) :
                                  campaign.category.toLowerCase() === 'psa'?(
                                    <span className="btn btn-rounded btn-sm btn-secondary">PSA</span>
                                  ) : null
                                }
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i className="fas fa-tags font-size-18"/>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <small className="text-muted fw-medium mb-2 display-block">Campaign Status</small>
                                <div>
                                  {
                                    campaign.status.toLowerCase() === 'running'? (
                                      <span className="btn btn-rounded btn-sm btn-success">Running</span>
                                    )  :
                                    campaign.status.toLowerCase() === 'cancellled'?(
                                      <span className="btn btn-rounded btn-sm btn-secondary">Cancellled</span>
                                    ) :
                                    campaign.status.toLowerCase() === 'completed'?(
                                      <span className="btn btn-rounded btn-sm btn-info">Completed</span>
                                    ) : null
                                  }
                                </div>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i className="bx bxs-badge-check font-size-24"/>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col md="12">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <small className="text-muted fw-medium mb-2 display-block">Led Log</small>
                                <h5 className="mb-10">{log_leds.count} <small className="text-muted">(Total Log)</small></h5>
                                <h5 className="mb-10">{log_leds.count} <small className="text-muted">(Total Recorded)</small></h5>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i className="bx bx-transfer font-size-24"/>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="12">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <small className="text-muted fw-medium mb-2 display-block">Static Log</small>
                                <h5 className="mb-0">{log_statics.count} <small className="text-muted">(Total Uploaded Log)</small></h5>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-info align-self-center">
                                <span className="avatar-title">
                                  <i className="bx bx-transfer font-size-24"/>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Row className="mb-10">
                      <Col md="9">
                        <h5 className="mb-20">Latest Data</h5>
                      </Col>
                      <Col md="3">
                        <Select options={options} placeholder={<div>Select Period</div>} defaultValue={options[0]} onChange={this.onSelectSummaryTimerange} />
                      </Col>
                    </Row>
                    {log_led_summary_loading ? <><Spinner size="sm" as="span" /> Loading...</> : LedSummaries}
                  </CardBody>
                </Card>
              </Col>
              <Col md="12">
                <Row>
                  <Col md="12" className="mb-15">
                    <Card>
                      <CardBody className="border-bottom">
                        <span className="text-muted">Site Log - Led</span>
                      </CardBody>
                      <CardBody>
                        <PaginationProvider pagination={paginationFactory(pageOptionsLed)} keyField='id' columns={ledColumns} data={log_leds.rows}>
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider keyField='id' columns={ledColumns} data={log_leds.rows} search>
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row>
                                    <Col md="6">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar{...toolkitProps.searchProps}/>
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className="text-sm-end">
                                        <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-secondary mr-5">Export to CSV</ExportCSVButton>
                                        <Button className="mr-5 btn-dark" onClick={() => this.flatPicker1.current.flatpickr.clear()}>Reset</Button>
                                        <FormGroup className="btn-datepicker">
                                          <InputGroup>
                                            <Flatpickr className="form-control btn btn-outline-primary" placeholder="Select Date" options={{mode: "range", dateFormat: "Y-m-d"}} ref={this.flatPicker1} />
                                          </InputGroup>
                                        </FormGroup>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"id"}
                                          responsive
                                          bordered={true}
                                          striped={true}
                                          hover={true}
                                          classes={"table align-middle table-nowrap"}
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                          noDataIndication={() => log_led_loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone{...paginationProps}/>
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone{...paginationProps}/>
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" className="mb-15">
                    <Card>
                      <CardBody className="border-bottom">
                        <span className="text-muted">Site Log - Static</span>
                      </CardBody>
                      <CardBody>
                        <PaginationProvider pagination={paginationFactory(pageOptionsStatic)} keyField='id' columns={staticColumns} data={log_statics.rows}>
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider keyField='id' columns={staticColumns} data={log_statics.rows} search>
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row>
                                    <Col md="6">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar{...toolkitProps.searchProps}/>
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className="text-sm-end">
                                        <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-secondary mr-5">Export to CSV</ExportCSVButton>
                                        <Button className="mr-5 btn-dark" onClick={() => this.flatPicker2.current.flatpickr.clear()}>Reset</Button>
                                        <FormGroup className="btn-datepicker">
                                          <InputGroup>
                                            <Flatpickr className="form-control" placeholder="Select Date" options={{mode: "range", dateFormat: "Y-m-d"}} ref={this.flatPicker2} />
                                          </InputGroup>
                                        </FormGroup>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"id"}
                                          responsive
                                          bordered={true}
                                          striped={true}
                                          hover={true}
                                          noDataIndication={() => log_static_loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                          classes={"table align-middle table-nowrap"}
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone{...paginationProps}/>
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone{...paginationProps}/>
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

MlpCampaignDetail.propTypes = {
  campaign: PropTypes.object,
  message: PropTypes.string,
  error: PropTypes.string,
  log_leds: PropTypes.object,
  log_led_loading: PropTypes.bool,
  log_statics: PropTypes.object,
  log_static_loading: PropTypes.bool,
  log_led_summaries: PropTypes.object,
  log_led_summary_loading: PropTypes.bool,
  getCampaignData: PropTypes.func,
  getLedLogsByCampaign: PropTypes.func,
  getStaticLogsByCampaign: PropTypes.func,
  getLedSummaryByCampaign: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object
}

const mapStateToProps = ({ campaigns, log }) => ({
  campaign: campaigns.campaignData && campaigns.campaignData.data ? campaigns.campaignData.data : null,
  message: campaigns.message,
  error: campaigns.error,
  log_leds: log.logs_led_sub && log.logs_led_sub.data ? log.logs_led_sub.data : { rows: [], count: 0, page: 1, page_count: 1 },
  log_led_loading: log.log_led_loading,
  log_statics: log.logs_static_sub && log.logs_static_sub.data ? log.logs_static_sub.data : { rows: [], count: 0, page: 1, page_count: 1 },
  log_static_loading: log.log_static_loading,
  log_led_summaries: log.log_led_summary_sub && log.log_led_summary_sub.data ? log.log_led_summary_sub.data : { rows: [], count: 0, page: 1, page_count: 1 },
  log_led_summary_loading: log.log_led_summary_loading
})

const mapDispatchToProps = dispatch => ({
  getCampaignData: id => dispatch(getCampaignData(id)),
  getLedLogsByCampaign: (campaignId, query) => dispatch(getLedLogsByCampaign(campaignId, query)),
  getStaticLogsByCampaign: (campaignId, query) => dispatch(getStaticLogsByCampaign(campaignId, query)),
  getLedSummaryByCampaign: (campaignId, query) => dispatch(getLedSummaryByCampaign(campaignId, query))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpCampaignDetail))