import React from "react"
import Select from "react-select"
import PropTypes from "prop-types"

export const CustomSelect = ({ field, form, options, onChanged, isMulti = false, ...more }) => {
  const onChange = option => {
    if (onChanged) {
      onChanged(option, form)
    }

    form.setFieldValue(field.name, isMulti ? option.map(item => item.value) : option.value)
  }

  const getValue = () => {
    const val = field.value

    if (!val) {
      return isMulti ? [] : val
    }

    if (options) {
      if (Array.isArray(options) && options.length && options[0].options) {
        options = options[0].options
      }

      return isMulti ? options.filter(option => val.indexOf(option.value) >= 0) : options.find(option => option.value === val)
    } else {
      return isMulti ? [] : ""
    }
  }

  return (
    <Select
      name={field.name}
      value={getValue()}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      {...more}
    />
  )
}

CustomSelect.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  field: PropTypes.object,
  form: PropTypes.object,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChanged: PropTypes.func
}

export default CustomSelect