import {
    GET_PLAYERS,
    GET_PLAYERS_SUCCESS,
    GET_PLAYERS_FAIL,
    GET_PLAYER_DATA,
    GET_PLAYER_DATA_SUCCESS,
    GET_PLAYER_DATA_FAIL,
    SET_PLAYER_MAP,
    SET_PLAYER_MAP_SUCCESS,
    SET_PLAYER_MAP_FAIL, CREATE_PLAYER_MAP, CREATE_PLAYER_MAP_SUCCESS, CREATE_PLAYER_MAP_FAIL
} from "./actionTypes"

export const getPlayers = () => ({
    type: GET_PLAYERS
})

export const getPlayersSuccess = players => ({
    type: GET_PLAYERS_SUCCESS,
    payload: players
})

export const getPlayersFail = error => ({
    type: GET_PLAYERS_FAIL,
    payload: error
})

export const getPlayerData = id => ({
    type: GET_PLAYER_DATA,
    payload: id
})

export const getPlayerDataSuccess = player => ({
    type: GET_PLAYER_DATA_SUCCESS,
    payload: player
})

export const getPlayerDataFail = error => ({
    type: GET_PLAYER_DATA_FAIL,
    payload: error
})

export const setPlayerMap = (data, callback) => ({
    type: SET_PLAYER_MAP,
    payload: { data, callback }
})

export const setPlayerMapSuccess = message => ({
    type: SET_PLAYER_MAP_SUCCESS,
    payload: message
})

export const setPlayerMapFail = error => ({
    type: SET_PLAYER_MAP_FAIL,
    payload: error
})

export const createPlayerMap = (data, callback) => ({
    type: CREATE_PLAYER_MAP,
    payload: { data, callback }
})

export const createPlayerMapSuccess = message => ({
    type: CREATE_PLAYER_MAP_SUCCESS,
    payload: message
})

export const createPlayerMapFail = error => ({
    type: CREATE_PLAYER_MAP_FAIL,
    payload: error
})