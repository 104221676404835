import {
  GET_ACCOUNT_DATA,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAIL,
  GET_ACCOUNTS,
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNTS_SUCCESS,
  ADD_NEW_ACCOUNT,
  ADD_NEW_ACCOUNT_SUCCESS,
  ADD_NEW_ACCOUNT_FAIL,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL,
  RESET_ACCOUNT_STATE
} from "./actionTypes"

export const getAccounts = () => ({
  type: GET_ACCOUNTS
})

export const getAccountsSuccess = accounts => ({
  type: GET_ACCOUNTS_SUCCESS,
  payload: accounts
})

export const getAccountsFail = error => ({
  type: GET_ACCOUNTS_FAIL,
  payload: error
})

export const getAccountData = id => ({
  type: GET_ACCOUNT_DATA,
  payload: id
})

export const getAccountDataSuccess = account => ({
  type: GET_ACCOUNT_DATA_SUCCESS,
  payload: account
})

export const getAccountDataFail = error => ({
  type: GET_ACCOUNT_DATA_FAIL,
  payload: error
})

export const addNewAccount = (account, callback) => ({
  type: ADD_NEW_ACCOUNT,
  payload: { account, callback }
})

export const addNewAccountSuccess = message => ({
  type: ADD_NEW_ACCOUNT_SUCCESS,
  payload: message
})

export const addNewAccountFail = error => ({
  type: ADD_NEW_ACCOUNT_FAIL,
  payload: error
})

export const updateAccount = (account, callback) => ({
  type: UPDATE_ACCOUNT,
  payload: { account, callback }
})

export const updateAccountSuccess = message => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  payload: message
})

export const updateAccountFail = error => ({
  type: UPDATE_ACCOUNT_FAIL,
  payload: error
})

export const deleteAccount = (account, callback) => ({
  type: DELETE_ACCOUNT,
  payload: { account, callback }
})

export const deleteAccountSuccess = message => ({
  type: DELETE_ACCOUNT_SUCCESS,
  payload: message
})

export const deleteAccountFail = error => ({
  type: DELETE_ACCOUNT_FAIL,
  payload: error
})

export const resetAccountState = () => ({
  type: RESET_ACCOUNT_STATE
})
