import PropTypes from "prop-types"
import React, { Component } from "react"
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { userForgetPassword } from "../../store/actions"
import profile from "../../assets/images/profile-img.png"
import nordfooter from "../../assets/images/component/nord-footer.png"
import nordicon from "../../assets/images/component/nord-icon-light.png"

class ForgetPasswordPage extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block hide">
          <Link to="/" className="text-dark"><i className="bx bx-home h2" /></Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Forgot your password ?</h5>
                          <p>Please enter your username to start recovering process.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={nordicon} alt="" className="rounded-circle" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.forgetError && this.props.forgetError ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.props.forgetError}
                        </Alert>
                      ) : null}
                      {this.props.forgetSuccessMsg ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.props.forgetSuccessMsg}
                        </Alert>
                      ) : null}
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          username: ""
                        }}
                        validationSchema={Yup.object().shape({
                          username: Yup.string().required("Please Enter Your Username")
                        })}
                        onSubmit={(values, helper) => {
                          this.props.userForgetPassword(values)

                          helper.resetForm()
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="username" className="form-label">Username</Label>
                              <Field
                                name="username"
                                type="text"
                                className={"form-control" + (errors.username && touched.username ? " is-invalid" : "")}
                              />
                              <ErrorMessage name="username" component="div" className="invalid-feedback" />
                            </div>
                            <div className="text-end">
                              <button className="btn btn-primary w-md" type="submit">Reset</button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link to="login" className="fw-medium text-primary">Login</Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()}
                    <img src={nordfooter} alt="" className="img-fluid img-nf" />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.string,
  forgetSuccessMsg: PropTypes.string,
  userForgetPassword: PropTypes.func,
}

const mapStateToProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword

  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStateToProps, { userForgetPassword })(ForgetPasswordPage)
)