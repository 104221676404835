import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { map } from "lodash"
import {Card, CardBody, Col, Container, Row, FormGroup, InputGroup, Button, Spinner} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Breadcrumbs from "components/Common/Breadcrumb"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import {getSiteData, getLedLogsBySite} from "../../store/actions"
import { DateTime } from "luxon"

const { ExportCSVButton } = CSVExport

class MlpLedDetail extends Component {
  constructor (props) {
    super(props)

    this.flatPicker = React.createRef()
  }

  componentDidMount () {
    const { getSiteData, getLedLogsBySite, match = {} } = this.props
    const { params = {} } = match
    const { id } = params

    if (id) {
      getSiteData(id)
      getLedLogsBySite(id)
    }
  }

  onDateRangeChange (data) {
    const { getLedLogsBySite, match = {} } = this.props
    const [from = false, to = false] = data
    const params = {}

    if (from) {
      params.from = DateTime.fromJSDate(from).toFormat('y-MM-dd')
    }

    if (to) {
      params.to = DateTime.fromJSDate(to).toFormat('y-MM-dd')
    }

    getLedLogsBySite(match.params.id, params)
  }

  render() {
    const { site, logs, loading } = this.props
    const { rows = [] } = logs || {}

    const ledColumns = [
      {
        dataField: 'id',
        text: 'Log ID',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'media_name',
        text: 'Media Name',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'player_id',
        text: 'Campaign Name',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => {
          if (log.PlayerMap && log.PlayerMap.Pillar && log.PlayerMap.Pillar.Site && log.PlayerMap.Pillar.Site.CampaignSites) {
              let selected = log.PlayerMap.Pillar.Site.CampaignSites.find(i => {
                let material_name = i.material_name.toLowerCase()
                let media_name = log.media_name.toLowerCase()

                return media_name.startsWith(material_name)
              })

              if (selected && selected.Campaign) {
                return selected.Campaign.name
              }
          }
        },
        csvFormatter: (cellContent, log) => {
          if (log.PlayerMap && log.PlayerMap.Pillar && log.PlayerMap.Pillar.Site && log.PlayerMap.Pillar.Site.CampaignSites) {
            let selected = log.PlayerMap.Pillar.Site.CampaignSites.find(i => {
              let material_name = i.material_name.toLowerCase()
              let media_name = log.media_name.toLowerCase()

              return media_name.startsWith(material_name)
            })

            if (selected && selected.Campaign) {
              return selected.Campaign.name
            }
          }
        }
      },
      {
        dataField: 'category',
        text: 'Category',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => {
          if (log.PlayerMap && log.PlayerMap.Pillar && log.PlayerMap.Pillar.Site && log.PlayerMap.Pillar.Site.CampaignSites) {
            let selected = log.PlayerMap.Pillar.Site.CampaignSites.find(i => {
              let material_name = i.material_name.toLowerCase()
              let media_name = log.media_name.toLowerCase()

              return media_name.startsWith(material_name)
            })

            if (selected && selected.Campaign) {
              return selected.Campaign.category.toLowerCase() == 'advertiser' ? "Advertiser" : "Promotional"
            }
          }
        },
        csvFormatter: (cellContent, log) => {
          if (log.PlayerMap && log.PlayerMap.Pillar && log.PlayerMap.Pillar.Site && log.PlayerMap.Pillar.Site.CampaignSites) {
            let selected = log.PlayerMap.Pillar.Site.CampaignSites.find(i => {
              let material_name = i.material_name.toLowerCase()
              let media_name = log.media_name.toLowerCase()

              return media_name.startsWith(material_name)
            })

            if (selected && selected.Campaign) {
              return selected.Campaign.category.toLowerCase() == 'advertiser' ? "Advertiser" : "Promotional"
            }
          }
        }
      },
      {
        dataField: 'duration',
        text: 'Duration',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => `${log.duration}s`,
        csvFormatter: (cellContent, log) => `${log.duration}`,
      },
      {
        dataField: 'start_time',
        text: 'Log Start',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => DateTime.fromSeconds(log.start_time).toFormat('dd MMM y'),
        csvFormatter: (cellContent, log) => DateTime.fromSeconds(log.start_time).toFormat('dd MMM y'),
      }
    ]

    const pageOptionsStatic = {sizePerPage: 10, totalSize: 10, custom: true}
    const { SearchBar } = Search

    return (
      site &&
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Media Log Proof Led | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Media Log Proof" breadcrumbItem="Led Detail" />
            <Row>
              <Col md="12">
                <Row>
                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Site Name</small>
                            <h6 className="mb-0">{site.name}</h6>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-carousel font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Lists of Pillar ({site.number_set})</small>
                            {
                              map(site.Pillars, (pillar, index) => (
                                  <Link to={"/pillars/view/" + pillar.id} className="badge badge-soft-primary font-size-11 m-1" key={"_skill_o_" + site.id + index}>
                                    {pillar.name}
                                  </Link>
                              ))
                            }
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-map-pin font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Address</small>
                            <h6 className="mb-0">{site.District ? site.District.name + ", " : ""}{site.City ? site.City.name + ", " : ""}{site.Province ? site.Province.name : ""}</h6>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-detail font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                <Row>
                  <Col md="12" className="mb-15">
                    <Card>
                      <CardBody className="border-bottom">
                        <span className="text-muted">Led Log</span>
                      </CardBody>
                      <CardBody>
                        <PaginationProvider pagination={paginationFactory(pageOptionsStatic)} keyField='id' columns={ledColumns} data={rows}>{({paginationProps, paginationTableProps}) => (
                          <ToolkitProvider keyField='id' columns={ledColumns} data={rows} search>
                              {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col md="6">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar{...toolkitProps.searchProps}/>
                                            <i className="bx bx-search-alt search-icon"/>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col md="6">
                                        <div className="text-sm-end">
                                          <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-secondary mr-5">Export to CSV</ExportCSVButton>
                                          <Button className="mr-5 btn-dark" onClick={() => this.flatPicker.current.flatpickr.clear()}>Reset</Button>
                                          <FormGroup className="btn-datepicker">
                                            <InputGroup>
                                              <Flatpickr className="form-control" placeholder="Select Date" options={{mode: "range", dateFormat: "Y-m-d"}} onChange={this.onDateRangeChange.bind(this)} ref={this.flatPicker} />
                                            </InputGroup>
                                          </FormGroup>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                              keyField={"id"}
                                              responsive
                                              bordered={true}
                                              striped={true}
                                              hover={true}
                                              classes={"table align-middle table-nowrap"}
                                              headerWrapperClasses={"thead-light"}
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                              noDataIndication={ loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        <div className="d-inline">
                                          <SizePerPageDropdownStandalone{...paginationProps}/>
                                        </div>
                                        <div className="text-md-right ms-auto">
                                          <PaginationListStandalone{...paginationProps}/>
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                              )}
                            </ToolkitProvider>
                        )}
                        </PaginationProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

MlpLedDetail.propTypes = {
  site: PropTypes.object,
  logs: PropTypes.object,
  loading: PropTypes.bool,
  match: PropTypes.object,
  getSiteData: PropTypes.func,
  getLedLogsBySite: PropTypes.func
}

const mapStateToProps = ({ sites, log }) => ({
  site: sites.siteData && sites.siteData.data ? sites.siteData.data : null,
  logs: log.logs_led_sub && log.logs_led_sub.data ? log.logs_led_sub.data : null,
  loading: log.log_led_loading
})

const mapDispatchToProps = dispatch => ({
  getSiteData: id => dispatch(getSiteData(id)),
  getLedLogsBySite: (siteId, query) => dispatch(getLedLogsBySite(siteId, query))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpLedDetail))
