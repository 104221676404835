import { call, put, takeEvery } from "redux-saga/effects"
import {
  GET_CAMPAIGNS,
  GET_CAMPAIGN_DATA,
  ADD_NEW_CAMPAIGN,
  DELETE_CAMPAIGN,
  DELETE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_STATUS,
  GET_CAMPAIGNS_BY_SITE
} from "./actionTypes"
import {
  getCampaignsSuccess,
  getCampaignsFail,
  getCampaignDataSuccess,
  getCampaignDataFail,
  addCampaignSuccess,
  addCampaignFail,
  updateCampaignSuccess,
  updateCampaignFail,
  deleteCampaignSuccess,
  deleteCampaignFail,
  updateCampaignStatusSuccess,
  updateCampaignStatusFail, getCampaignsBySiteSuccess, getCampaignsBySiteFail
} from "./actions";
import { getCampaigns, getCampaignData, addNewCampaign, updateCampaign, deleteCampaign, updateCampaignStatus, getCampaignsBySite } from "../../helpers/api_v1_helper"

function* fetchCampaigns({ payload: queries }) {
  try {
    const response = yield call(getCampaigns, queries)

    yield put(getCampaignsSuccess(response))
  } catch (error) {
    yield put(getCampaignsFail(error))
  }
}

function* fetchCampaignsBySite({ payload: id }) {
  try {
    const response = yield call(getCampaignsBySite, id)

    yield put(getCampaignsBySiteSuccess(response))
  } catch (error) {
    yield put(getCampaignsBySiteFail(error))
  }
}

function* fetchCampaignData({ payload: id }) {
  try {
    const response = yield call(getCampaignData, id)

    yield put(getCampaignDataSuccess(response))
  } catch (error) {
    yield put(getCampaignDataFail(error))
  }
}

function* onAddNewCampaign({ payload: { campaign, history } }) {
  try {
    yield call(addNewCampaign, campaign)
    yield put(addCampaignSuccess("Campaign added successfully"))

    history.push("/mlp-campaign-list")
  } catch (error) {
    yield put(addCampaignFail(error))
  }
}

function* onUpdateCampaign({ payload: { campaign, history } }) {
  try {
    yield call(updateCampaign, campaign)
    yield put(updateCampaignSuccess("Campaign updated successfully"))

    history.push("/mlp-campaign-list")
  } catch (error) {
    yield put(updateCampaignFail(error))
  }
}

function* onDeleteCampaign({ payload: campaign }) {
  try {
    yield call(deleteCampaign, campaign)
    yield put(deleteCampaignSuccess("Campaign delete successfully"))
  } catch (error) {
    yield put(deleteCampaignFail(error))
  }
}

function* onUpdateCampaignStatus({ payload: { data, callback } }) {
  try {
    yield call(updateCampaignStatus, data)
    yield put(updateCampaignStatusSuccess("Campaign status updated successfully"))

    if (callback) {
      callback()
    }
  } catch (error) {
    yield put(updateCampaignStatusFail(error))
  }
}

function* CampaignSaga() {
  yield takeEvery(GET_CAMPAIGNS, fetchCampaigns)
  yield takeEvery(GET_CAMPAIGNS_BY_SITE, fetchCampaignsBySite)
  yield takeEvery(GET_CAMPAIGN_DATA, fetchCampaignData)
  yield takeEvery(ADD_NEW_CAMPAIGN, onAddNewCampaign)
  yield takeEvery(UPDATE_CAMPAIGN, onUpdateCampaign)
  yield takeEvery(DELETE_CAMPAIGN, onDeleteCampaign)
  yield takeEvery(DELETE_CAMPAIGN_SUCCESS, fetchCampaigns)
  yield takeEvery(UPDATE_CAMPAIGN_STATUS, onUpdateCampaignStatus)
}

export default CampaignSaga
