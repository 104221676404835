import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE } from "./actionTypes"

const initialState = {
  profile: null,
  error: ""
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_SUCCESS:
      state = { ...state, profile: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
