import {
  GET_CAMPAIGNS, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_FAIL,
  GET_CAMPAIGNS_BY_SITE, GET_CAMPAIGNS_BY_SITE_SUCCESS, GET_CAMPAIGNS_BY_SITE_FAIL,
  ADD_NEW_CAMPAIGN, ADD_CAMPAIGN_FAIL, ADD_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN, UPDATE_CAMPAIGN_SUCCESS, UPDATE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN, DELETE_CAMPAIGN_SUCCESS, DELETE_CAMPAIGN_FAIL,
  GET_CAMPAIGN_DATA, GET_CAMPAIGN_DATA_SUCCESS, GET_CAMPAIGN_DATA_FAIL,
  RESET_CAMPAIGN_ERROR, RESET_CAMPAIGN_MESSAGE
} from "./actionTypes"

const INIT_STATE = {
  campaigns: [],
  campaignsData: null,
  error: null,
  message: null,
  loading: false
}

const store = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS:
      return { ...state, loading: true, message: null, error: null, campaigns: [] }
    case GET_CAMPAIGNS_SUCCESS:
      return { ...state, loading: false, message: null, error: null, campaigns: action.payload }
    case GET_CAMPAIGNS_FAIL:
      return { ...state, loading: false, message: null, error: action.payload, campaigns: [] }
    case GET_CAMPAIGNS_BY_SITE:
      return { ...state, loading: true, message: null, error: null, campaigns: [] }
    case GET_CAMPAIGNS_BY_SITE_SUCCESS:
      return { ...state, loading: false, message: null, error: null, campaigns: action.payload }
    case GET_CAMPAIGNS_BY_SITE_FAIL:
      return { ...state, loading: false, message: null, error: action.payload, campaigns: [] }
    case GET_CAMPAIGN_DATA:
      return { ...state, loading: true, message: null, error: null, campaignData: null }
    case GET_CAMPAIGN_DATA_SUCCESS:
      return { ...state, loading: false, message: null, error: null, campaignData: action.payload }
    case GET_CAMPAIGN_DATA_FAIL:
      return { ...state, loading: false, message: null, error: action.payload, campaignData: null }
    case ADD_NEW_CAMPAIGN:
      return { ...state, loading: true, message: null, error: null }
    case ADD_CAMPAIGN_SUCCESS:
      return { ...state, loading: false, message: action.payload, error: null }
    case ADD_CAMPAIGN_FAIL:
      return { ...state, loading: false, message: null, error: action.payload }
    case UPDATE_CAMPAIGN:
      return { ...state, loading: true, message: null, error: null }
    case UPDATE_CAMPAIGN_SUCCESS:
      return { ...state, loading: false, message: action.payload, error: null }
    case UPDATE_CAMPAIGN_FAIL:
      return { ...state, loading: false, message: null, error: action.payload }
    case DELETE_CAMPAIGN:
      return { ...state, loading: true, message: null, error: null }
    case DELETE_CAMPAIGN_SUCCESS:
      return { ...state, loading: false, message: action.payload, error: null }
    case DELETE_CAMPAIGN_FAIL:
      return { ...state, loading: false, message: null, error: action.payload }
    case RESET_CAMPAIGN_ERROR:
      return { ...state, error: null }
    case RESET_CAMPAIGN_MESSAGE:
      return { ...state, message: null }
    default:
      return state
  }
}

export default store