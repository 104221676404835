import { call, put, takeEvery } from "redux-saga/effects"
import { getPlayers, getPlayerData, setPlayerMap, createPlayerMap } from "../../helpers/api_v1_helper"
import {
    getPlayersSuccess,
    getPlayersFail,
    getPlayerDataFail,
    getPlayerDataSuccess,
    setPlayerMapSuccess,
    setPlayerMapFail,
    createPlayerMapSuccess, createPlayerMapFail
} from "./actions"
import {GET_PLAYERS, GET_PLAYER_DATA, SET_PLAYER_MAP, CREATE_PLAYER_MAP} from "./actionTypes"

function* fetchPlayers() {
    try {
        const response = yield call(getPlayers)

        yield put(getPlayersSuccess(response))
    } catch (error) {
        yield put(getPlayersFail(error))
    }
}

function* fetchPlayerData({ payload: id }) {
    try {
        const response = yield call(getPlayerData, id)

        yield put(getPlayerDataSuccess(response))
    } catch (error) {
        yield put(getPlayerDataFail(error))
    }
}

function* onUpdatePlayerMap({ payload: { data, callback } }) {
    try {
        yield call(setPlayerMap, data)

        yield put(setPlayerMapSuccess("Player has successfully mapped"))

        if (callback) {
            callback()
        }
    } catch (error) {
        yield put(setPlayerMapFail(error))
    }
}

function* onCreatePlayerMap({ payload: { data, callback } }) {
    try {
        yield call(createPlayerMap, data)

        yield put(createPlayerMapSuccess("New player map has successfully created"))

        if (callback) {
            callback()
        }
    } catch (error) {
        yield put(createPlayerMapFail(error))
    }
}

function* playerSaga() {
    yield takeEvery(GET_PLAYERS, fetchPlayers)
    yield takeEvery(GET_PLAYER_DATA, fetchPlayerData)
    yield takeEvery(SET_PLAYER_MAP, onUpdatePlayerMap)
    yield takeEvery(CREATE_PLAYER_MAP, onCreatePlayerMap)
}

export default playerSaga