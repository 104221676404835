import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

class ProfileMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      menu: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }))
  }

  render() {
    const { profile } = this.props
    return (
      profile &&
      <React.Fragment>
        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block">
          <DropdownToggle className="btn header-item" id="page-header-user-dropdown" tag="button">
            <div className="avatar-xs display-inherit">
              <span className="avatar-title rounded-circle">{profile.name.charAt(0)}</span>
            </div>
            {" "}
            <span className="d-none d-xl-inline-block ms-1">{profile.name}</span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag="a" href="/profile">
              <i className="bx bx-user font-size-16 align-middle ms-1" />
              {this.props.t("Profile")}
            </DropdownItem>
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    )
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  profile: PropTypes.object
}

const mapStateToProps = state => {
  const { profile } = state.Profile
  return { profile }
}

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
)
