import React, { Component } from "react"
import { connect } from "react-redux"
import {Row, Col, Modal, ModalHeader, ModalBody, Label} from "reactstrap"
import PropTypes from "prop-types"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { AccountStatusFieldOptions } from "../../constants/common"
import CustomSelect from "../../components/Common/CustomSelect"
import { addNewAccount, updateAccount } from "./../../store/actions"

class AccountDialogAction extends Component {
    render() {
        const { title, callback, data, loading, addNewAccount, updateAccount } = this.props

        return (
            <Modal isOpen={true}>
                <ModalHeader tag="h4" toggle={callback}>{title}</ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{
                            id: data ? data.id : "",
                            url: data ? data.url : "",
                            username: data ? data.username : "",
                            password: "",
                            is_enable: data && data.is_enable ? "1" : "0"
                        }}
                        validationSchema={Yup.object().shape({
                            url: Yup.string().required("Please Enter URL"),
                            username: Yup.string().required("Please Enter Username"),
                            password: data && data.id ? Yup.string() : Yup.string().required("Please Enter Password")
                        })}
                        onSubmit={values => {
                            if (data && data.id) {
                                updateAccount(values, callback)
                            } else {
                                addNewAccount(values, callback)
                            }
                        }}
                    >
                        {
                            ({ errors, touched }) => (
                                <Form>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mb-3">
                                                <Label className="form-label">Url</Label>
                                                <Field
                                                    name="url"
                                                    type="text"
                                                    className={"form-control" + (errors.url && touched.url ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="url" component="div" className="invalid-feedback"/>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Username</Label>
                                                <Field
                                                    name="username"
                                                    type="text"
                                                    className={"form-control" + (errors.username && touched.username ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="username" component="div" className="invalid-feedback"/>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Password</Label>
                                                <Field
                                                    name="password"
                                                    type="password"
                                                    autoComplete="off"
                                                    className={"form-control" + (errors.password && touched.password ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Status</Label>
                                                <Field name="is_enable" options={AccountStatusFieldOptions} component={CustomSelect} />
                                                <ErrorMessage name="is_enable" component="div" className="invalid-feedback"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <button type="submit" className="btn btn-success save-user" disabled={loading}>{loading ? "Saving" : "Save"}</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
        )
    }
}

AccountDialogAction.propTypes = {
    title: PropTypes.string,
    callback: PropTypes.func,
    data: PropTypes.object,
    loading: PropTypes.bool,
    addNewAccount: PropTypes.func,
    updateAccount: PropTypes.func
}

const mapStateToProps = ({ account }) => ({
    loading: account.loading
})

const mapDispatchToProps = dispatch => ({
    addNewAccount: (data, callback) => dispatch(addNewAccount(data, callback)),
    updateAccount: (data, callback) => dispatch(updateAccount(data, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountDialogAction)