import {
  GET_LED_LOGS,
  GET_LED_LOGS_SUCCESS,
  GET_LED_LOGS_FAIL,
  GET_LED_LOGS_BY_SITE,
  GET_LED_LOGS_BY_SITE_SUCCESS,
  GET_LED_LOGS_BY_SITE_FAIL,
  GET_LED_LOGS_BY_CAMPAIGN,
  GET_LED_LOGS_BY_CAMPAIGN_SUCCESS,
  GET_LED_LOGS_BY_CAMPAIGN_FAIL,
  RESET_LED_LOG_MESSAGE,
  RESET_LED_LOG_ERROR,
  GET_STATIC_LOGS,
  GET_STATIC_LOGS_SUCCESS,
  GET_STATIC_LOGS_FAIL,
  GET_STATIC_LOGS_BY_SITE,
  GET_STATIC_LOGS_BY_SITE_SUCCESS,
  GET_STATIC_LOGS_BY_SITE_FAIL,
  GET_STATIC_LOGS_BY_CAMPAIGN,
  GET_STATIC_LOGS_BY_CAMPAIGN_SUCCESS,
  GET_STATIC_LOGS_BY_CAMPAIGN_FAIL,
  RESET_STATIC_LOG_MESSAGE,
  RESET_STATIC_LOG_ERROR,
  GET_RAW_LOGS,
  GET_RAW_LOGS_SUCCESS,
  GET_RAW_LOGS_FAIL,
  ADD_STATIC_NEW_LOG,
  ADD_STATIC_LOG_SUCCESS,
  ADD_STATIC_LOG_FAIL,
  GET_STATIC_LOG_DATA,
  GET_STATIC_LOG_DATA_SUCCESS,
  GET_STATIC_LOG_DATA_FAIL,
  IMPORT_LED_LOGS,
  IMPORT_LED_LOGS_SUCCESS,
  IMPORT_LED_LOGS_FAIL,
  RESET_LED_RAW_MESSAGE,
  GET_LED_SUMMARY,
  GET_LED_SUMMARY_SUCCESS,
  GET_LED_SUMMARY_FAIL,
  GET_LED_SUMMARY_BY_CAMPAIGN, GET_LED_SUMMARY_BY_CAMPAIGN_SUCCESS, GET_LED_SUMMARY_BY_CAMPAIGN_FAIL
} from "./actionTypes"

const INIT_STATE = {
  logs_led: null,
  logs_led_sub: null,
  log_led_error: null,
  log_led_message: null,
  log_led_loading: false,
  logs_static: null,
  logs_static_sub: null,
  logs_static_data: null,
  log_static_error: null,
  log_static_message: null,
  log_static_loading: false,
  logs_raw: null,
  log_raw_error: null,
  log_raw_message: null,
  log_raw_loading: false,
  log_led_summary: null,
  log_led_summary_sub: null,
  log_led_summary_error: null,
  log_led_summary_message: null,
  log_led_summary_loading: false
}

const store = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_LED_LOGS:
      return { ...state, log_led_loading: true, log_led_message: null, log_led_error: null, logs_led: null }
    case GET_LED_LOGS_SUCCESS:
      return { ...state, log_led_loading: false, log_led_message: null, log_led_error: null, logs_led: action.payload }
    case GET_LED_LOGS_FAIL:
      return { ...state, log_led_loading: false, log_led_message: null, log_led_error: action.payload, logs_led: null }
    case GET_LED_LOGS_BY_SITE:
      return { ...state, log_led_loading: true, log_led_message: null, log_led_error: null, logs_led_sub: null }
    case GET_LED_LOGS_BY_SITE_SUCCESS:
      return { ...state, log_led_loading: false, log_led_message: null, log_led_error: null, logs_led_sub: action.payload }
    case GET_LED_LOGS_BY_SITE_FAIL:
      return { ...state, log_led_loading: false, log_led_message: null, log_led_error: action.payload, logs_led_sub: null }
    case GET_LED_LOGS_BY_CAMPAIGN:
      return { ...state, log_led_loading: true, log_led_message: null, log_led_error: null, logs_led_sub: null }
    case GET_LED_LOGS_BY_CAMPAIGN_SUCCESS:
      return { ...state, log_led_loading: false, log_led_message: null, log_led_error: null, logs_led_sub: action.payload }
    case GET_LED_LOGS_BY_CAMPAIGN_FAIL:
      return { ...state, log_led_loading: false, log_led_message: null, log_led_error: action.payload, logs_led_sub: null }
    case IMPORT_LED_LOGS:
      return { ...state }
    case IMPORT_LED_LOGS_SUCCESS:
      return { ...state, log_raw_message: action.payload }
    case IMPORT_LED_LOGS_FAIL:
      return { ...state }
    case GET_LED_SUMMARY:
      return { ...state, log_led_summary_loading: true, log_led_summary_message: null, log_led_summary_error: null, log_led_summary: null }
    case GET_LED_SUMMARY_SUCCESS:
      return { ...state, log_led_summary_loading: false, log_led_summary_message: null, log_led_summary_error: null, log_led_summary: action.payload }
    case GET_LED_SUMMARY_FAIL:
      return { ...state, log_led_summary_loading: false, log_led_summary_message: null, log_led_summary_error: action.payload, log_led_summary: null }
    case GET_LED_SUMMARY_BY_CAMPAIGN:
      return { ...state, log_led_summary_loading: true, log_led_summary_message: null, log_led_summary_error: null, log_led_summary_sub: null }
    case GET_LED_SUMMARY_BY_CAMPAIGN_SUCCESS:
      return { ...state, log_led_summary_loading: false, log_led_summary_message: null, log_led_summary_error: null, log_led_summary_sub: action.payload }
    case GET_LED_SUMMARY_BY_CAMPAIGN_FAIL:
      return { ...state, log_led_summary_loading: false, log_led_summary_message: null, log_led_summary_error: action.payload, log_led_summary_sub: null }
    case RESET_LED_LOG_MESSAGE:
      return { ...state, log_led_message: null }
    case RESET_LED_LOG_ERROR:
      return { ...state, log_led_error: null }
    case ADD_STATIC_NEW_LOG:
      return { ...state, log_static_loading: true, log_static_message: null, log_static_error: null }
    case ADD_STATIC_LOG_SUCCESS:
      return { ...state, log_static_loading: false, log_static_message: action.payload, log_static_error: null }
    case ADD_STATIC_LOG_FAIL:
      return { ...state, log_static_loading: false, log_static_message: null, log_static_error: action.payload }
    case GET_STATIC_LOGS:
      return { ...state, log_static_loading: true, log_static_message: null, log_static_error: null, logs_static: null }
    case GET_STATIC_LOGS_SUCCESS:
      return { ...state, log_static_loading: false, log_static_message: null, log_static_error: null, logs_static: action.payload }
    case GET_STATIC_LOGS_FAIL:
      return { ...state, log_static_loading: false, log_static_message: null, log_static_error: action.payload, logs_static: null }
    case GET_STATIC_LOG_DATA:
      return { ...state, log_static_loading: true, log_static_message: null, log_static_error: null, logs_static_data: null }
    case GET_STATIC_LOG_DATA_SUCCESS:
      return { ...state, log_static_loading: false, log_static_message: null, log_static_error: null, logs_static_data: action.payload }
    case GET_STATIC_LOG_DATA_FAIL:
      return { ...state, log_static_loading: false, log_static_message: null, log_static_error: action.payload, logs_static_data: null }
    case GET_STATIC_LOGS_BY_SITE:
      return { ...state, log_static_loading: true, log_static_message: null, log_static_error: null, logs_static_sub: null }
    case GET_STATIC_LOGS_BY_SITE_SUCCESS:
      return { ...state, log_static_loading: false, log_static_message: null, log_static_error: null, logs_static_sub: action.payload }
    case GET_STATIC_LOGS_BY_SITE_FAIL:
      return { ...state, log_static_loading: false, log_static_message: null, log_static_error: action.payload, logs_static_sub: null }
    case GET_STATIC_LOGS_BY_CAMPAIGN:
      return { ...state, log_static_loading: true, log_static_message: null, log_static_error: null, logs_static_sub: null }
    case GET_STATIC_LOGS_BY_CAMPAIGN_SUCCESS:
      return { ...state, log_static_loading: false, log_static_message: null, log_static_error: null, logs_static_sub: action.payload }
    case GET_STATIC_LOGS_BY_CAMPAIGN_FAIL:
      return { ...state, log_static_loading: false, log_static_message: null, log_static_error: action.payload, logs_static_sub: null }
    case RESET_STATIC_LOG_MESSAGE:
      return { ...state, log_static_message: null }
    case RESET_STATIC_LOG_ERROR:
      return { ...state, log_static_error: null }
    case GET_RAW_LOGS:
      return { ...state, log_raw_loading: true, log_raw_message: null, log_raw_error: null, logs_raw: null }
    case GET_RAW_LOGS_SUCCESS:
      return { ...state, log_raw_loading: false, log_raw_message: null, log_raw_error: null, logs_raw: action.payload }
    case GET_RAW_LOGS_FAIL:
      return { ...state, log_raw_loading: false, log_raw_message: null, log_raw_error: action.payload, logs_raw: null }
    case RESET_LED_RAW_MESSAGE:
      return { ...state, log_raw_message: null }
    default:
      return state
  }
}

export default store