import {
  GET_BOOKING_DATA,
  GET_BOOKING_DATA_FAIL,
  GET_BOOKING_DATA_SUCCESS,
  GET_BOOKINGS,
  GET_BOOKINGS_FAIL,
  GET_BOOKINGS_SUCCESS,
  ADD_NEW_BOOKING,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_FAIL,
  UPDATE_BOOKING,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAIL,
  DELETE_BOOKING,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAIL, RESET_BOOKING_ERROR, RESET_BOOKING_MESSAGE,
} from "./actionTypes"
import {RESET_SITE_ERROR, RESET_SITE_MESSAGE} from "../sites/actionTypes";

export const getBookings = queries => ({
  type: GET_BOOKINGS,
  payload: queries
})

export const getBookingsSuccess = bookings => ({
  type: GET_BOOKINGS_SUCCESS,
  payload: bookings
})

export const getBookingsFail = error => ({
  type: GET_BOOKINGS_FAIL,
  payload: error
})

export const getBookingData = id => ({
  type: GET_BOOKING_DATA,
  payload: id
})

export const getBookingDataSuccess = booking => ({
  type: GET_BOOKING_DATA_SUCCESS,
  payload: booking
})

export const getBookingDataFail = error => ({
  type: GET_BOOKING_DATA_FAIL,
  payload: error
})

export const addNewBooking = booking => ({
  type: ADD_NEW_BOOKING,
  payload: booking
})

export const addBookingSuccess = booking => ({
  type: ADD_BOOKING_SUCCESS,
  payload: booking
})

export const addBookingFail = error => ({
  type: ADD_BOOKING_FAIL,
  payload: error
})

export const updateBooking = booking => ({
  type: UPDATE_BOOKING,
  payload: booking
})

export const updateBookingSuccess = booking => ({
  type: UPDATE_BOOKING_SUCCESS,
  payload: booking
})

export const updateBookingFail = error => ({
  type: UPDATE_BOOKING_FAIL,
  payload: error
})

export const deleteBooking = booking => ({
  type: DELETE_BOOKING,
  payload: booking
})

export const deleteBookingSuccess = booking => ({
  type: DELETE_BOOKING_SUCCESS,
  payload: booking
})

export const deleteBookingFail = error => ({
  type: DELETE_BOOKING_FAIL,
  payload: error
})

export const resetBookingError = () => ({
  type: RESET_BOOKING_ERROR
})

export const resetBookingMessage = () => ({
  type: RESET_BOOKING_MESSAGE
})
