import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { map } from "lodash"
import { Card, CardBody, Col, Container, Row, FormGroup, InputGroup, } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Breadcrumbs from "components/Common/Breadcrumb"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { getStaticLogData, getStaticLogsBySite } from "./../../store/actions"
import { DateTime } from "luxon"
import CustomImage from "../../components/Common/CustomImage"
import CustomDownloadButton from "../../components/Common/CustomDownloadButton"

class MlpSiteDetail extends Component {
  componentDidMount() {
    const { match, getStaticLogData } = this.props
    const { params = {} } = match
    const { id } = params

    if (id) {
      getStaticLogData(id)
    }
  }

  componentDidUpdate(prevProps) {
    const current = this.props.log
    const prev = prevProps.log
    const getStaticLogsBySite = this.props.getStaticLogsBySite

    if (!prev && current) {
      getStaticLogsBySite(current.Site.id)
    }
  }

  render() {
    const { log, site_logs } = this.props
    const { count = 0, rows = [] } = site_logs || {}

    const staticColumns = [
      {
        dataField: 'id',
        text: 'Log ID',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'User.name',
        text: 'Updated By',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'log_date',
        text: 'Log Date',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => DateTime.fromSeconds(log.created).toFormat('dd MMM y - hh:mm:ss'),
        csvFormatter: (cellContent, log) => DateTime.fromSeconds(log.created).toFormat('dd MMM y - hh:mm:ss')
      },
      {
        dataField: 'Campaign.name',
        text: 'Campaign Name',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'log_image',
        text: 'Log Image',
        sort: true,
        csvExport: true,
        formatter:
        (cellContent, log) => (
          map(log.data.files, (image, index) => {
            return(
                <CustomDownloadButton to={image.name} mimetype={image.mimetype}><CustomImage src={image.name} className="img-table" key={"img_" + log.id + "_" + index} /></CustomDownloadButton>
            )
          })
        ),
      },
    ]

    const pageOptionsStatic = {sizePerPage: 10, totalSize: count, custom: true}
    const { SearchBar } = Search
    return (
      !log ? <React.Fragment /> : <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Media Log Proof Static | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Media Log Proof" breadcrumbItem="Static Detail" />
            <Row>
              <Col md="12">
                <Row>
                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Site Static Name</small>
                            <h6 className="mb-0">{log.Site.name}</h6>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-carousel font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Lists of Pillar ({ log.Site.Pillars.length })</small>
                            {map(
                                log.Site.Pillars,
                              (pillar, index) =>
                                (
                                  <Link to={"pillar-detail"} className="badge badge-soft-primary font-size-11 m-1" key={"_pillar_" + pillar.id + index}>
                                    {pillar.name}
                                  </Link>
                                )
                            )}
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-map-pin font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <small className="text-muted fw-medium mb-2 display-block">Address</small>
                            <h6 className="mb-0">{log.Site.address}</h6>
                          </div>
                          <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-detail font-size-24"/>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                <Row>
                  <Col md="12" className="mb-15">
                    <Card>
                      <CardBody className="border-bottom" key="cb_title">
                        <span className="text-muted">Static Log</span>
                      </CardBody>
                      <CardBody>
                        <PaginationProvider pagination={paginationFactory(pageOptionsStatic)} keyField='id' columns={staticColumns} data={rows}>
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider keyField='id' columns={staticColumns} data={rows} search>
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row>
                                    <Col md="6">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="6">
                                      <div className="text-sm-end">
                                        <FormGroup className="btn-datepicker">
                                          <InputGroup>
                                            <Flatpickr
                                              className="form-control"
                                              placeholder="Select Date"
                                              options={{
                                                mode: "range",
                                                dateFormat: "Y-m-d"
                                              }}
                                            />
                                          </InputGroup>
                                        </FormGroup>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"id"}
                                          responsive
                                          bordered={true}
                                          striped={true}
                                          hover={true}
                                          classes={"table align-middle table-nowrap"}
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="d-inline">
                                        <SizePerPageDropdownStandalone{...paginationProps}/>
                                      </div>
                                      <div className="text-md-right ms-auto">
                                        <PaginationListStandalone{...paginationProps}/>
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

MlpSiteDetail.propTypes = {
  match: PropTypes.object,
  log: PropTypes.object,
  site_logs: PropTypes.object,
  getStaticLogData: PropTypes.func,
  getStaticLogsBySite: PropTypes.func
}

const mapStateToProps = ({ log }) => ({
  log: log.logs_static_data && log.logs_static_data.data ? log.logs_static_data.data : null,
  site_logs: log.logs_static && log.logs_static.data ? log.logs_static.data : null
})

const mapDispatchToProps = dispatch => ({
  getStaticLogData: id => dispatch(getStaticLogData(id)),
  getStaticLogsBySite: (siteId, query) => dispatch(getStaticLogsBySite(siteId, query))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpSiteDetail))