import Flatpickr from "react-flatpickr"
import React from "react"
import PropTypes from "prop-types"
import { DateTime } from "luxon"

export const CustomFlatpickr = ({ field, form, options, ...more }) => {
    const dOptions = {
        mode: "range",
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        ...options
    }

    const onChange = data => {
        let newValue

        if (dOptions.mode === "single") {
            newValue = DateTime.fromJSDate(data[0]).toFormat('y-MM-dd')
        }

        if (dOptions.mode === "range") {
            let [from = false, to = false] = data

            if (from) {
                from = DateTime.fromJSDate(from).toFormat('y-MM-dd')
            }

            if (to) {
                to = DateTime.fromJSDate(to).toFormat('y-MM-dd')
            }

            newValue = [from, to]
        }

        form.setFieldValue(field.name, newValue)
    }

    const getValue = () => {
        return field.value
    }

    return (
        <Flatpickr options={dOptions} onChange={onChange} value={getValue()} {...more} />
    )
}

CustomFlatpickr.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    field: PropTypes.object,
    form: PropTypes.object,
    options: PropTypes.object
}

export default CustomFlatpickr