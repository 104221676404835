import {
  GET_CAMPAIGN_DATA, GET_CAMPAIGN_DATA_FAIL, GET_CAMPAIGN_DATA_SUCCESS,
  GET_CAMPAIGNS, GET_CAMPAIGNS_FAIL, GET_CAMPAIGNS_SUCCESS,
  ADD_NEW_CAMPAIGN, ADD_CAMPAIGN_SUCCESS, ADD_CAMPAIGN_FAIL,
  UPDATE_CAMPAIGN, UPDATE_CAMPAIGN_SUCCESS, UPDATE_CAMPAIGN_FAIL,
  DELETE_CAMPAIGN, DELETE_CAMPAIGN_SUCCESS, DELETE_CAMPAIGN_FAIL,
  RESET_CAMPAIGN_ERROR, RESET_CAMPAIGN_MESSAGE,
  UPDATE_CAMPAIGN_STATUS, UPDATE_CAMPAIGN_STATUS_SUCCESS, UPDATE_CAMPAIGN_STATUS_FAIL,
  GET_CAMPAIGNS_BY_SITE, GET_CAMPAIGNS_BY_SITE_SUCCESS, GET_CAMPAIGNS_BY_SITE_FAIL
} from "./actionTypes";

export const getCampaigns = queries => ({
  type: GET_CAMPAIGNS,
  payload: queries
})

export const getCampaignsSuccess = campaigns => ({
  type: GET_CAMPAIGNS_SUCCESS,
  payload: campaigns
})

export const getCampaignsFail = error => ({
  type: GET_CAMPAIGNS_FAIL,
  payload: error
})

export const getCampaignData = id => ({
  type: GET_CAMPAIGN_DATA,
  payload: id
})

export const getCampaignDataSuccess = campaign => ({
  type: GET_CAMPAIGN_DATA_SUCCESS,
  payload: campaign
})

export const getCampaignDataFail = error => ({
  type: GET_CAMPAIGN_DATA_FAIL,
  payload: error
})

export const addNewCampaign = (campaign, history) => ({
  type: ADD_NEW_CAMPAIGN,
  payload: { campaign, history }
})

export const addCampaignSuccess = message => ({
  type: ADD_CAMPAIGN_SUCCESS,
  payload: message
})

export const addCampaignFail = error => ({
  type: ADD_CAMPAIGN_FAIL,
  payload: error
})

export const updateCampaign = (campaign, history) => ({
  type: UPDATE_CAMPAIGN,
  payload: { campaign, history }
})

export const updateCampaignSuccess = message => ({
  type: UPDATE_CAMPAIGN_SUCCESS,
  payload: message
})

export const updateCampaignFail = error => ({
  type: UPDATE_CAMPAIGN_FAIL,
  payload: error
})

export const deleteCampaign = campaign => ({
  type: DELETE_CAMPAIGN,
  payload: campaign
})

export const deleteCampaignSuccess = message => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  payload: message
})

export const deleteCampaignFail = error => ({
  type: DELETE_CAMPAIGN_FAIL,
  payload: error
})

export const resetCampaignError = () => ({
  type: RESET_CAMPAIGN_ERROR
})

export const resetCampaignMessage = () => ({
  type: RESET_CAMPAIGN_MESSAGE
})

export const updateCampaignStatus = (data, callback) => ({
  type: UPDATE_CAMPAIGN_STATUS,
  payload: { data, callback }
})

export const updateCampaignStatusSuccess = message => ({
  type: UPDATE_CAMPAIGN_STATUS_SUCCESS,
  payload: message
})

export const updateCampaignStatusFail = error => ({
  type: UPDATE_CAMPAIGN_STATUS_FAIL,
  payload: error
})

export const getCampaignsBySite = siteId => ({
  type: GET_CAMPAIGNS_BY_SITE,
  payload: siteId
})

export const getCampaignsBySiteSuccess = campaigns => ({
  type: GET_CAMPAIGNS_BY_SITE_SUCCESS,
  payload: campaigns
})

export const getCampaignsBySiteFail = error => ({
  type: GET_CAMPAIGNS_BY_SITE_FAIL,
  payload: error
})

