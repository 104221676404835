import {
  GET_SITE_DATA,
  GET_SITE_DATA_FAIL,
  GET_SITE_DATA_SUCCESS,
  GET_SITES_FAIL,
  GET_SITES_SUCCESS,
  ADD_SITE_SUCCESS,
  ADD_SITE_FAIL,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_FAIL,
  DELETE_SITE_SUCCESS,
  DELETE_SITE_FAIL,
  RESET_SITE_ERROR,
  RESET_SITE_MESSAGE, GET_SITES
} from "./actionTypes"

const INIT_STATE = {
  sites: [],
  siteData: null,
  error: null,
  message: null,
  loading: false
}

const store = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SITES:
      return { ...state, loading: true, message: null, error: null, sites: action.payload, siteData: null }
    case GET_SITES_SUCCESS:
      return { ...state, loading: false, message: null, error: null, sites: action.payload, siteData: null }
    case GET_SITES_FAIL:
      return { ...state, loading: false, message: null, error: action.payload, siteData: null }
    case GET_SITE_DATA:
      return { ...state, siteData: null }
    case GET_SITE_DATA_SUCCESS:
        return { ...state, siteData: action.payload }
    case ADD_SITE_SUCCESS:
      return { ...state, message: action.payload }
    case ADD_SITE_FAIL:
      return { ...state, error: action.payload }
    case UPDATE_SITE_SUCCESS:
      return { ...state, message: action.payload }
    case UPDATE_SITE_FAIL:
      return { ...state, error: action.payload }
    case DELETE_SITE_SUCCESS:
      return { ...state, message: action.payload }
    case DELETE_SITE_FAIL:
      return { ...state, error: action.payload }
    case GET_SITE_DATA_FAIL:
      return { ...state, error: action.payload }
    case RESET_SITE_ERROR:
      return { ...state, error: null }
    case RESET_SITE_MESSAGE:
      return { ...state, message: null }
    default:
      return state
  }
}

export default store
