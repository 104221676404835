import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_FAIL,
  UPDATE_USER_FAIL,
  DELETE_USER_FAIL,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAIL,
  CLEAR_USER_ERROR, GET_USERS, ADD_NEW_USER
} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userData: {},
  error: "",
  loading: false
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
      return { ...state, loading: true }
    case GET_USERS_SUCCESS:
      return { ...state, loading: false, users: action.payload }
    case GET_USERS_FAIL:
      return { ...state, loading: false, error: action.payload }
    case ADD_NEW_USER:
      return { ...state, loading: true }
    case ADD_USER_FAIL:
      return { ...state, loading: false, error: action.payload }
    case GET_USER_DATA_SUCCESS:
      return { ...state, loading: false, userData: action.payload }
    case UPDATE_USER_FAIL:
      return { ...state, loading: false, error: action.payload }
    case DELETE_USER_FAIL:
      return { ...state, loading: false, error: action.payload }
    case GET_USER_DATA_FAIL:
      return { ...state, loading: false, error: action.payload }
    case CLEAR_USER_ERROR:
      return { ...state, error: "" }
    default:
      return state
  }
}

export default users
