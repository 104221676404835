import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { DateTime } from "luxon"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Row, InputGroup, FormGroup, Button, Spinner } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { getStaticLogs } from "../../store/log/actions"

const { ExportCSVButton } = CSVExport

class MlpStaticList extends Component {
  constructor (props) {
    super(props)

    this.flatPicker = React.createRef()

    this.onDateRangeChange = this.onDateRangeChange.bind(this)
  }

  componentDidMount() {
    const { getStaticLogs } = this.props

    getStaticLogs()
  }

  onDateRangeChange (data) {
    const { getStaticLogs } = this.props
    const [from = false, to = false] = data
    const params = {}

    if (from) {
      params.from = DateTime.fromJSDate(from).toFormat('y-MM-dd')
    }

    if (to) {
      params.to = DateTime.fromJSDate(to).toFormat('y-MM-dd')
    }

    getStaticLogs(params)
  }

  render() {
    const { logs, loading } = this.props
    const { rows = [], limit = 10, count = 0 } = logs || {}
    const columns = [
      {
        dataField: 'id',
        text: 'Site ID',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'site_name',
        text: 'Site Name',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => <Link to={"/mlp-static-detail/" + log.id}>{log.Site.name}</Link>,
        csvFormatter: (cellContent, log) => log.Site.name
      },
      {
        dataField: 'Campaign.name',
        text: 'Campaign Name',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => <Link to={"/mlp-campaign-detail/" + log.Campaign.id}>{log.Campaign.name}</Link>,
        csvFormatter: (cellContent, log) => log.Campaign.name
      },
      {
        dataField: 'created',
        text: 'Last Updated',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => DateTime.fromSeconds(log.created).toFormat('dd MMM y - hh:mm:ss'),
        csvFormatter: (cellContent, log) => DateTime.fromSeconds(log.created).toFormat('dd MMM y - hh:mm:ss')
      },
      {
        dataField: 'User.name',
        text: 'Updated By',
        sort: true,
        csvExport: true,
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        formatter: (cellContent, log) => (
          <>
            <div className="d-flex gap-3">
              <Link to={"/mlp-static-detail/" + log.id} className="badge badge-soft-primary" title="View">
                <i className="mdi mdi-eye-outline font-size-18"/>
              </Link>
            </div>
          </>
        ),
      }
    ]

    const pageOptions = { sizePerPage: limit, totalSize: count, custom: true }
    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Media Log Proof Static | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Media Log Proof" breadcrumbItem="Static List" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={columns} data={rows}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider keyField='id' columns={columns} data={rows} search>
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar{...toolkitProps.searchProps}/>
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="text-sm-end">
                                    <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-secondary mr-5 hide">Export to CSV</ExportCSVButton>
                                    <Link to="mlp-static-create" className="font-16 btn-block btn btn-primary mr-5">Create New Log</Link>
                                    <Button className="mr-5 btn-dark" onClick={() => this.flatPicker.current.flatpickr.clear()}>Reset</Button>
                                    <FormGroup className="btn-datepicker">
                                      <InputGroup>
                                        <Flatpickr className="form-control btn btn-outline-primary" placeholder="Select Date" options={{mode: "range", dateFormat: "Y-m-d"}} onChange={this.onDateRangeChange} ref={this.flatPicker} />
                                      </InputGroup>
                                    </FormGroup>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="id"
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      hover={true}
                                      classes="table align-middle table-nowrap"
                                      headerWrapperClasses="thead-light"
                                      noDataIndication={ loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone{...paginationProps}/>
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone{...paginationProps}/>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

MlpStaticList.propTypes = {
  logs: PropTypes.object,
  message: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  getStaticLogs: PropTypes.func,
  history: PropTypes.object
}

const mapStateToProps = ({ log }) => ({
  logs: log.logs_static && log.logs_static.data ? log.logs_static.data : null,
  message: log.log_static_message,
  error: log.log_static_error,
  loading: log.log_static_loading
})

const mapDispatchToProps = dispatch => ({
  getStaticLogs: queries => dispatch(getStaticLogs(queries))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpStaticList))