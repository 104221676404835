import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Container, Row, Table, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getPillarData } from "../../store/pillars/actions"

class PillarDetail extends Component {
  componentDidMount () {
    const { getPillarData, match = {} } = this.props
    const { params = {} } = match
    const { id } = params

    if (id) {
      getPillarData(id)
    }
  }

  render() {
    const { pillar } = this.props

    return (
      !pillar ? <React.Fragment /> :
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Pillar Detail | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Pillars" breadcrumbItem="Pillar Detail" />
            <Row>
              <Col md="6">
                <Row>
                    <Col md="12">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <small className="text-muted fw-medium mb-2 display-block">Pillar Name</small>
                              <h5 className="mb-0">{pillar.name}</h5>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i className="bx bx-map-pin font-size-24"/>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    {
                      pillar && (pillar.Province || pillar.City || pillar.District) &&
                      <Col md="12">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <small className="text-muted fw-medium mb-2 display-block">Pillar Address</small>
                                <h5 className="mb-0">
                                {pillar.District ? pillar.District.name + ", " : ""}{pillar.City ? pillar.City.name + ", " : ""}{pillar.Province ? pillar.Province.name : ""}
                                </h5>
                              </div>
                              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                  <i className="bx bx-map font-size-24"/>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    }
                    <Col md="12">
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <small className="text-muted fw-medium mb-2 display-block">Pillar Location</small>
                              <h5>
                                <a href={`https://maps.google.com/maps?q=${pillar.latitude},${pillar.longitude}`}
                                  target="_blank" rel="noopener noreferrer">
                                    {pillar.latitude}, {pillar.longitude}
                                    <i className="bx bx-meteor ml-5"></i>
                                </a>
                              </h5>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i className="bx bx-map-pin font-size-24"/>
                              </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                </Row>
              </Col>
              <Col md="6">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      <Row>
                        <Col md="6">
                          <h5>Pillar Detail</h5>
                        </Col>
                        <Col md="6" className="text-sm-end">
                          <Link to={"/pillars/edit/" + pillar.id} className="btn btn-outline-primary btn-sm text-right mb-2">
                            <i className="bx bx-edit font-size-16 align-middle me-2"></i>
                            Edit Pillar Detail
                          </Link>
                        </Col>
                      </Row>
                    </CardTitle>
                    <Table striped bordered hover size="sm">
                      <tbody>
                        <tr>
                          <th width="200">Pillar ID</th>
                          <td>{pillar.id}</td>
                        </tr>
                        <tr>
                          <th width="200">Pillar Name</th>
                          <td>{pillar.name}</td>
                        </tr>
                        <tr>
                          <th width="200">Lat, Long</th>
                          <td>{pillar.latitude}, {pillar.longitude}</td>
                        </tr>
                        {
                          pillar && pillar.Province &&
                          <tr>
                            <th width="200">Province</th>
                            <td>{pillar.Province.name}</td>
                          </tr>
                        }
                        {
                          pillar && pillar.City &&
                          <tr>
                            <th width="200">City</th>
                            <td>{pillar.City.name}</td>
                          </tr>
                        }
                        {
                          pillar && pillar.District &&
                          <tr>
                            <th width="200">District</th>
                            <td>{pillar.District.name}</td>
                          </tr>
                        }
                        <tr>
                          <th width="200">Width</th>
                          <td>{pillar.width}</td>
                        </tr>
                        <tr>
                          <th width="200">Height</th>
                          <td>{pillar.height}</td>
                        </tr>
                        <tr>
                          <th width="200">Orientation</th>
                          <td>{pillar.orientation}</td>
                        </tr>
                        <tr>
                          <th width="200">Side</th>
                          <td>{pillar.side}</td>
                        </tr>
                        <tr>
                          <th width="200">OOH Type</th>
                          <td>{pillar.ooh_type}</td>
                        </tr>
                        <tr>
                          <th width="200">Lighting</th>
                          <td>{pillar.lighting}</td>
                        </tr>
                        {
                          pillar.Site &&
                          <tr>
                            <th width="200">Site Name</th>
                            <td>{pillar.Site.name}</td>
                          </tr>
                        }
                        <tr>
                          <th width="200">Last Update</th>
                          <td>{new Date(pillar.updated * 1000).toLocaleString()}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>

            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

PillarDetail.propTypes = {
  pillar: PropTypes.object,
  getPillarData: PropTypes.func,
  match: PropTypes.object
}

const mapStateToProps = ({ pillars }) => ({
  pillar: pillars.pillarData && pillars.pillarData.data ? pillars.pillarData.data : null
})

const mapDispatchToProps = dispatch => ({
  getPillarData: id => dispatch(getPillarData(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PillarDetail))
