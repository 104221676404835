import React, { Component } from "react"
import PropTypes from "prop-types"
import { getFileContent } from "./../../helpers/api_v1_helper"
import { Spinner } from "reactstrap"

class CustomImage extends Component {
    constructor (props) {
        super(props)

        this.state = {
            img: null
        }
    }
    componentDidMount() {
        const { src } = this.props

        getFileContent(`${src}?format=base64`).then(data => {
            this.setState({ img: `data:image/png;base64,${data}` })
        })
    }

    render () {
        const { src, ...opts } = this.props

        return !this.state.img ? <Spinner size="sm" color="primary" /> : <img src={this.state.img} {...opts} />
    }
}

CustomImage.propTypes = {
    src: PropTypes.string
}

export default CustomImage