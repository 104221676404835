import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Row, InputGroup, FormGroup, Spinner } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import DeleteModal from "components/Common/DeleteModal"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { getBookings, deleteBooking, resetBookingError } from '../../store/actions'
import { DateTime } from "luxon"
import AlertModal from "../../components/Common/AlertModal";

class BookingList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      selected: null,
      deleteModal: false
    }
  }

  componentDidMount() {
    const { getBookings } = this.props

    getBookings()
  }

  onClickDelete = booking => {
    this.setState({ selected: booking.Booking })
    this.setState({ deleteModal: true })
  }

  render() {
    const { deleteModal } = this.state
    const { bookings, deleteBooking, loading, getBookings, resetBookingError, message, error } = this.props
    const columns = [
      {
        dataField: 'Booking.id',
        text: 'Booking ID',
        sort: true
      },
      {
        dataField: 'name',
        text: 'Campaign Name',
        sort: true
      },
      {
        dataField: 'brand',
        text: 'Brand Name',
        sort: true
      },
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: (cellContent, booking) => (
          <div>
            {
              booking.status.toLowerCase() === 'running'? (
                <span className="font-size-12 badge bg-primary rounded-pill">Running</span>
              ) : 
              booking.status.toLowerCase() === 'cancelled'? (
                <span className="font-size-12 badge bg-danger rounded-pill">Cancelled</span>
              ) :
              booking.status.toLowerCase() === 'completed'? (
                <span className="font-size-12 badge bg-success rounded-pill">Completed</span>
              ) : null
            }
          </div>
        ),
      },
      {
        dataField: 'Booking.updated',
        text: 'Last Update',
        sort: true,
        formatter: (cellContent, booking) => booking.Booking.updated ? DateTime.fromSeconds(booking.Booking.updated).toFormat('dd MMM y - hh:mm:ss') : '',
      },
      {
        dataField: 'period_start',
        text: 'Period',
        sort: true,
        csvExport: true,
        formatter: (cellContent, booking) => (
          <span>{booking.period_start} - {booking.period_end}</span>
        ),
      },
      {
        dataField: 'Booking.sales',
        text: 'Sales',
        sort: true
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        formatter: (cellContent, booking) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="badge badge-soft-danger" title="Delete">
                <i className="bx bx-trash font-size-18" onClick={() => this.onClickDelete(booking)}/>
              </Link>
              <Link to={"booking/edit/" + booking.Booking.id} className="badge badge-soft-secondary" title="Edit">
                <i className="bx bx-edit font-size-18"/>
              </Link>
              <Link to={"booking/detail/" + booking.Booking.id} className="badge badge-soft-primary" title="View">
                <i className="mdi mdi-eye-outline font-size-18"/>
              </Link>
            </div>
          </>
        )
      }
    ]

    this.onClickDelete = this.onClickDelete.bind(this)

    const pageOptions = {sizePerPage: 10, totalSize: bookings.length, custom: true}
    const { SearchBar } = Search

    let alertShow = false
    let alertTitle, alertMessage, alertType, alertCallback

    if (message && message.length) {
      alertShow = true
      alertTitle = 'Success'
      alertMessage = message
      alertType = 'success'
      alertCallback = () => getBookings()
    }

    if (error && error.length) {
      alertShow = true
      alertTitle = 'Error'
      alertMessage = error
      alertType = 'error'
      alertCallback = () => resetBookingError()
    }

    return (
      <React.Fragment>
        <DeleteModal show={deleteModal} onDeleteClick={() => {
          this.setState({ deleteModal: false })

          if (this.state.selected) {
            return deleteBooking(this.state.selected)
          }
        }} onCloseClick={() => this.setState({ deleteModal: false })}/>
        <AlertModal show={alertShow} onCloseClick={alertCallback} title={alertTitle} message={alertMessage} type={alertType} />
        <div className="page-content">
          <MetaTags>
            <title>Booking List | Nord & Smith Dashboard</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Inventory" breadcrumbItem="Booking List" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={columns} data={bookings}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider keyField='id' columns={columns} data={bookings} search>
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar{...toolkitProps.searchProps}/>
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="text-sm-end">
                                    <Link to="booking/create" className="font-16 btn-block btn btn-primary mr-10">Create New Booking</Link>
                                    <FormGroup className="btn-datepicker">
                                      <InputGroup className="">
                                        <Flatpickr className="form-control btn btn-outline-primary" placeholder="Select Date" options={{mode: "range", dateFormat: "Y-m-d"}}/>
                                      </InputGroup>
                                    </FormGroup>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      hover={true}
                                      classes={"table align-middle table-nowrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      noDataIndication={() => loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone{...paginationProps}/>
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone{...paginationProps}/>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

BookingList.propTypes = {
  message: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  history: PropTypes.object,
  getBookings: PropTypes.func,
  deleteBooking: PropTypes.func,
  resetBookingError: PropTypes.func,
  bookings: PropTypes.array
}

const mapStateToProps = ({ booking }) => ({
  bookings: booking.bookings && booking.bookings.data ? booking.bookings.data : [],
  error: booking.error,
  message: booking.message,
  loading: booking.loading
})

const mapDispatchToProps = dispatch => ({
  getBookings: queries => dispatch(getBookings(queries)),
  deleteBooking: (booking, history) => dispatch(deleteBooking(booking, history)),
  resetBookingError: () => dispatch(resetBookingError())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookingList))