import {
  GET_USER_DATA,
  GET_USER_DATA_FAIL,
  GET_USER_DATA_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  ADD_NEW_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_USER_ERROR
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users
})

export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error
})

export const getUserData = id => ({
  type: GET_USER_DATA,
  payload: id
})

export const getUserDataSuccess = userData => ({
  type: GET_USER_DATA_SUCCESS,
  payload: userData
})

export const getUserDataFail = error => ({
  type: GET_USER_DATA_FAIL,
  payload: error
})

export const addNewUser = user => ({
  type: ADD_NEW_USER,
  payload: user
})

export const addUserSuccess = user => ({
  type: ADD_USER_SUCCESS,
  payload: user
})

export const addUserFail = error => ({
  type: ADD_USER_FAIL,
  payload: error
})

export const updateUser = user => ({
  type: UPDATE_USER,
  payload: user
})

export const updateUserSuccess = user => ({
  type: UPDATE_USER_SUCCESS,
  payload: user
})

export const updateUserFail = error => ({
  type: UPDATE_USER_FAIL,
  payload: error
})

export const deleteUser = user => ({
  type: DELETE_USER,
  payload: user
})

export const deleteUserSuccess = user => ({
  type: DELETE_USER_SUCCESS,
  payload: user
})

export const deleteUserFail = error => ({
  type: DELETE_USER_FAIL,
  payload: error
})

export const clearUserError = () => ({
  type: CLEAR_USER_ERROR
})
