import {
  GET_LED_LOGS,
  GET_LED_LOGS_SUCCESS,
  GET_LED_LOGS_FAIL,
  GET_LED_LOGS_BY_SITE,
  GET_LED_LOGS_BY_SITE_SUCCESS,
  GET_LED_LOGS_BY_SITE_FAIL,
  GET_LED_LOGS_BY_CAMPAIGN,
  GET_LED_LOGS_BY_CAMPAIGN_SUCCESS,
  GET_LED_LOGS_BY_CAMPAIGN_FAIL,
  GET_STATIC_LOGS,
  GET_STATIC_LOGS_SUCCESS,
  GET_STATIC_LOGS_FAIL,
  GET_STATIC_LOGS_BY_SITE,
  GET_STATIC_LOGS_BY_SITE_SUCCESS,
  GET_STATIC_LOGS_BY_SITE_FAIL,
  GET_STATIC_LOGS_BY_CAMPAIGN,
  GET_STATIC_LOGS_BY_CAMPAIGN_SUCCESS,
  GET_STATIC_LOGS_BY_CAMPAIGN_FAIL,
  GET_RAW_LOGS,
  GET_RAW_LOGS_SUCCESS,
  GET_RAW_LOGS_FAIL,
  ADD_STATIC_NEW_LOG,
  ADD_STATIC_LOG_SUCCESS,
  ADD_STATIC_LOG_FAIL,
  GET_STATIC_LOG_DATA,
  GET_STATIC_LOG_DATA_SUCCESS,
  GET_STATIC_LOG_DATA_FAIL,
  IMPORT_LED_LOGS,
  IMPORT_LED_LOGS_SUCCESS,
  IMPORT_LED_LOGS_FAIL,
  RESET_LED_RAW_MESSAGE,
  GET_LED_SUMMARY,
  GET_LED_SUMMARY_SUCCESS,
  GET_LED_SUMMARY_FAIL,
  GET_LED_SUMMARY_BY_CAMPAIGN, GET_LED_SUMMARY_BY_CAMPAIGN_SUCCESS, GET_LED_SUMMARY_BY_CAMPAIGN_FAIL
} from "./actionTypes"

export const getLedLogs = queries => ({
  type: GET_LED_LOGS,
  payload: queries
})

export const getLedLogsSuccess = logs => ({
  type: GET_LED_LOGS_SUCCESS,
  payload: logs
})

export const getLedLogsFail = error => ({
  type: GET_LED_LOGS_FAIL,
  payload: error
})

export const getLedLogsBySite = (siteId, query) => ({
  type: GET_LED_LOGS_BY_SITE,
  payload: {siteId, query}
})

export const getLedLogsBySiteSuccess = logs => ({
  type: GET_LED_LOGS_BY_SITE_SUCCESS,
  payload: logs
})

export const getLedLogsBySiteFail = error => ({
  type: GET_LED_LOGS_BY_SITE_FAIL,
  payload: error
})

export const getLedLogsByCampaign = (campaignId, query) => ({
  type: GET_LED_LOGS_BY_CAMPAIGN,
  payload: {campaignId, query}
})

export const getLedLogsByCampaignSuccess = logs => ({
  type: GET_LED_LOGS_BY_CAMPAIGN_SUCCESS,
  payload: logs
})

export const getLedLogsByCampaignFail = error => ({
  type: GET_LED_LOGS_BY_CAMPAIGN_FAIL,
  payload: error
})

export const importLedLogs = (data, callback) => ({
  type: IMPORT_LED_LOGS,
  payload: { data, callback }
})

export const importLedLogsSuccess = message => ({
  type: IMPORT_LED_LOGS_SUCCESS,
  payload: message
})

export const importLedLogsFail = error => ({
  type: IMPORT_LED_LOGS_FAIL,
  payload: error
})

export const getLedSummary = queries => ({
  type: GET_LED_SUMMARY,
  payload: queries
})

export const getLedSummarySuccess = logs => ({
  type: GET_LED_SUMMARY_SUCCESS,
  payload: logs
})

export const getLedSummaryFail = error => ({
  type: GET_LED_SUMMARY_FAIL,
  payload: error
})

export const getLedSummaryByCampaign = (campaignId, query) => ({
  type: GET_LED_SUMMARY_BY_CAMPAIGN,
  payload: {campaignId, query}
})

export const getLedSummaryByCampaignSuccess = logs => ({
  type: GET_LED_SUMMARY_BY_CAMPAIGN_SUCCESS,
  payload: logs
})

export const getLedSummaryByCampaignFail = error => ({
  type: GET_LED_SUMMARY_BY_CAMPAIGN_FAIL,
  payload: error
})

export const addNewStaticLog = (log, callback) => ({
  type: ADD_STATIC_NEW_LOG,
  payload: { log, callback }
})

export const addNewStaticLogSuccess = message => ({
  type: ADD_STATIC_LOG_SUCCESS,
  payload: message
})

export const addNewStaticLogFail = error => ({
  type: ADD_STATIC_LOG_FAIL,
  payload: error
})

export const getStaticLogs = queries => ({
  type: GET_STATIC_LOGS,
  payload: queries
})

export const getStaticLogsSuccess = logs => ({
  type: GET_STATIC_LOGS_SUCCESS,
  payload: logs
})

export const getStaticLogsFail = error => ({
  type: GET_STATIC_LOGS_FAIL,
  payload: error
})

export const getStaticLogsBySite = (siteId, query) => ({
  type: GET_STATIC_LOGS_BY_SITE,
  payload: {siteId, query}
})

export const getStaticLogsBySiteSuccess = logs => ({
  type: GET_STATIC_LOGS_BY_SITE_SUCCESS,
  payload: logs
})

export const getStaticLogsBySiteFail = error => ({
  type: GET_STATIC_LOGS_BY_SITE_FAIL,
  payload: error
})

export const getStaticLogsByCampaign = (campaignId, query) => ({
  type: GET_STATIC_LOGS_BY_CAMPAIGN,
  payload: {campaignId, query}
})

export const getStaticLogsByCampaignSuccess = logs => ({
  type: GET_STATIC_LOGS_BY_CAMPAIGN_SUCCESS,
  payload: logs
})

export const getStaticLogsByCampaignFail = error => ({
  type: GET_STATIC_LOGS_BY_CAMPAIGN_FAIL,
  payload: error
})

export const getStaticLogData = id => ({
  type: GET_STATIC_LOG_DATA,
  payload: id
})

export const getStaticLogDataSuccess = log => ({
  type: GET_STATIC_LOG_DATA_SUCCESS,
  payload: log
})

export const getStaticLogDataFail = error => ({
  type: GET_STATIC_LOG_DATA_FAIL,
  payload: error
})

export const getRawLogs = queries => ({
  type: GET_RAW_LOGS,
  payload: queries
})

export const getRawLogsSuccess = logs => ({
  type: GET_RAW_LOGS_SUCCESS,
  payload: logs
})

export const getRawLogsFail = error => ({
  type: GET_RAW_LOGS_FAIL,
  payload: error
})

export const resetLedRawMessage = () => ({
  type: RESET_LED_RAW_MESSAGE
})

