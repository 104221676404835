import PropTypes from "prop-types"
import React, { Component } from "react"
import { Alert, Card, CardBody, Col, Container, Label, Row } from "reactstrap"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import profile from "../../assets/images/profile-img.png"
import nordfooter from "../../assets/images/component/nord-footer.png"
import nordicon from "../../assets/images/component/nord-icon-light.png"
import { userRecoverAccount } from "../../store/actions"


class NewPasswordPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showNewPassword: false,
      showPasswordConfirmation: false
    }
  }

  render() {
    const { location, history } = this.props
    const qs = location.search && location.search.length ? new URLSearchParams(location.search.substring(1)) : null
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block hide">
          <Link to="/" className="text-dark"><i className="bx bx-home h2" /></Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Please set your password.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={nordicon} alt="" className="rounded-circle" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.props.recoverAccountError && this.props.recoverAccountError ? (<Alert color="danger" style={{ marginTop: "13px" }}>{this.props.recoverAccountError}</Alert>) : null}
                      {this.props.recoverAccountSuccessMsg ? (<Alert color="success" style={{ marginTop: "13px" }}>{this.props.recoverAccountSuccessMsg}</Alert>) : null}
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          password: "",
                          password_confirmation: "",
                          session_id: qs && qs.has("id") ? qs.get("id") : "",
                          session_key: qs && qs.has("key") ? qs.get("key") : ""
                        }}
                        validationSchema={Yup.object().shape({
                          password: Yup.string().required("Please enter your password"),
                          password_confirmation: Yup.string().required("Please confirm your password"),
                        })}
                        onSubmit={(values) => {
                          this.props.userRecoverAccount(values, history)
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <div className="mb-3">
                              <Label for="password" className="form-label">New Password</Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="password"
                                  type={this.state.showNewPassword ? "text" : "password"}
                                  placeholder="Enter new password"
                                  className={"form-control" + (errors.password && touched.password ? " is-invalid" : "")}
                                />
                                <button
                                  className="btn btn-light"
                                  type="button"
                                  id="password-addon"
                                  onClick={() => this.setState({ showNewPassword: !this.state.showNewPassword })}
                                >
                                  <i className={"mdi " + (this.state.showNewPassword ? "mdi-eye-off-outline" : "mdi-eye-outline") }></i>
                                </button>
                              </div>
                              <ErrorMessage name="password" component="div" className="invalid-feedback" />
                            </div>
                            <div className="mb-3">
                              <Label for="password_confirmation" className="form-label">Confirm Password</Label>
                              <div className="input-group auth-pass-inputgroup">
                                <Field
                                  name="password_confirmation"
                                  type={this.state.showPasswordConfirmation ? "text" : "password"}
                                  placeholder="Retype new password"
                                  className={"form-control" + (errors.password_confirmation && touched.password_confirmation ? " is-invalid" : "")}
                                />
                                <button
                                  className="btn btn-light"
                                  type="button"
                                  id="password-addon"
                                  onClick={() => this.setState({ showPasswordConfirmation: !this.state.showPasswordConfirmation })}
                                >
                                  <i className={"mdi " + (this.state.showPasswordConfirmation ? "mdi-eye-off-outline" : "mdi-eye-outline") }></i>
                                </button>
                              </div>
                              <ErrorMessage name="password_confirmation" component="div" className="invalid-feedback" />
                            </div>
                            <div className="text-end">
                              <button className="btn btn-primary w-md" type="submit">Submit</button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link to="login" className="fw-medium text-primary">Login</Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()}
                    <img src={nordfooter} alt="" className="img-fluid img-nf" />
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

NewPasswordPage.propTypes = {
  recoverAccountError: PropTypes.string,
  recoverAccountSuccessMsg: PropTypes.string,
  userRecoverAccount: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object
}

const mapStateToProps = state => {
  const { recoverAccountError, recoverAccountSuccessMsg } = state.ForgetPassword
  return { recoverAccountError, recoverAccountSuccessMsg }
}

export default withRouter(
  connect(mapStateToProps, { userRecoverAccount })(NewPasswordPage)
)