export const GET_LED_LOGS = "GET_LED_LOGS"
export const GET_LED_LOGS_SUCCESS = "GET_LED_LOGS_SUCCESS"
export const GET_LED_LOGS_FAIL = "GET_LED_LOGS_FAIL"
export const GET_LED_LOGS_BY_SITE = "GET_LED_LOGS_BY_SITE"
export const GET_LED_LOGS_BY_SITE_SUCCESS = "GET_LED_LOGS_BY_SITE_SUCCESS"
export const GET_LED_LOGS_BY_SITE_FAIL = "GET_LED_LOGS_BY_SITE_FAIL"
export const GET_LED_LOGS_BY_CAMPAIGN = "GET_LED_LOGS_BY_CAMPAIGN"
export const GET_LED_LOGS_BY_CAMPAIGN_SUCCESS = "GET_LED_LOGS_BY_CAMPAIGN_SUCCESS"
export const GET_LED_LOGS_BY_CAMPAIGN_FAIL = "GET_LED_LOGS_BY_CAMPAIGN_FAIL"
export const IMPORT_LED_LOGS = "IMPORT_LED_LOGS"
export const IMPORT_LED_LOGS_SUCCESS = "IMPORT_LED_LOGS_SUCCESS"
export const IMPORT_LED_LOGS_FAIL = "IMPORT_LED_LOGS_FAIL"
export const RESET_LED_LOG_ERROR = "RESET_LED_LOG_ERROR"
export const RESET_LED_LOG_MESSAGE = "RESET_LED_LOG_MESSAGE"
export const RESET_LED_RAW_MESSAGE = "RESET_LED_RAW_MESSAGE"
export const GET_LED_SUMMARY = "GET_LED_SUMMARY"
export const GET_LED_SUMMARY_SUCCESS = "GET_LED_SUMMARY_SUCCESS"
export const GET_LED_SUMMARY_FAIL = "GET_LED_SUMMARY_FAIL"
export const GET_LED_SUMMARY_BY_CAMPAIGN = "GET_LED_SUMMARY_BY_CAMPAIGN"
export const GET_LED_SUMMARY_BY_CAMPAIGN_SUCCESS = "GET_LED_SUMMARY_BY_CAMPAIGN_SUCCESS"
export const GET_LED_SUMMARY_BY_CAMPAIGN_FAIL = "GET_LED_SUMMARY_BY_CAMPAIGN_FAIL"
export const GET_STATIC_LOGS = "GET_STATIC_LOGS"
export const GET_STATIC_LOGS_SUCCESS = "GET_STATIC_LOGS_SUCCESS"
export const GET_STATIC_LOGS_FAIL = "GET_STATIC_LOGS_FAIL"
export const GET_STATIC_LOGS_BY_SITE = "GET_STATIC_LOGS_BY_SITE"
export const GET_STATIC_LOGS_BY_SITE_SUCCESS = "GET_STATIC_LOGS_BY_SITE_SUCCESS"
export const GET_STATIC_LOGS_BY_SITE_FAIL = "GET_STATIC_LOGS_BY_SITE_FAIL"
export const GET_STATIC_LOGS_BY_CAMPAIGN = "GET_STATIC_LOGS_BY_CAMPAIGN"
export const GET_STATIC_LOGS_BY_CAMPAIGN_SUCCESS = "GET_STATIC_LOGS_BY_CAMPAIGN_SUCCESS"
export const GET_STATIC_LOGS_BY_CAMPAIGN_FAIL = "GET_STATIC_LOGS_BY_CAMPAIGN_FAIL"
export const GET_STATIC_LOG_DATA = "GET_STATIC_LOG_DATA"
export const GET_STATIC_LOG_DATA_SUCCESS = "GET_STATIC_LOG_DATA_SUCCESS"
export const GET_STATIC_LOG_DATA_FAIL = "GET_STATIC_LOG_DATA_FAIL"
export const ADD_STATIC_NEW_LOG = "ADD_STATIC_NEW_LOG"
export const ADD_STATIC_LOG_SUCCESS = "ADD_STATIC_LOG_SUCCESS"
export const ADD_STATIC_LOG_FAIL = "ADD_STATIC_LOG_FAIL"
export const RESET_STATIC_LOG_ERROR = "RESET_STATIC_LOG_ERROR"
export const RESET_STATIC_LOG_MESSAGE = "RESET_STATIC_LOG_MESSAGE"
export const GET_RAW_LOGS = "GET_RAW_LOGS"
export const GET_RAW_LOGS_SUCCESS = "GET_RAW_LOGS_SUCCESS"
export const GET_RAW_LOGS_FAIL = "GET_RAW_LOGS_FAIL"


