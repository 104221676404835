import {
    GET_PLAYERS,
    GET_PLAYERS_SUCCESS,
    GET_PLAYERS_FAIL,
    GET_PLAYER_DATA,
    GET_PLAYER_DATA_SUCCESS,
    GET_PLAYER_DATA_FAIL,
    SET_PLAYER_MAP_SUCCESS,
    SET_PLAYER_MAP_FAIL, CREATE_PLAYER_MAP_SUCCESS, CREATE_PLAYER_MAP_FAIL, CREATE_PLAYER_MAP, SET_PLAYER_MAP
} from "./actionTypes"

const INIT_STATE = {
    players: [],
    playerData: null,
    error: null,
    message: null,
    loading: false
}

const player = (state = INIT_STATE, action) => {
    switch (action.type) {
    case GET_PLAYERS:
        return { ...state, loading: true, message: null, error: null, players: [] }
    case GET_PLAYERS_SUCCESS:
        return { ...state, loading: false, message: null, error: null, players: action.payload }
    case GET_PLAYERS_FAIL:
        return { ...state, loading: false, message: null, error: action.payload, players: [] }
    case GET_PLAYER_DATA:
        return { ...state, playerData: null }
    case GET_PLAYER_DATA_SUCCESS:
        return { ...state, playerData: action.payload }
    case GET_PLAYER_DATA_FAIL:
        return { ...state, error: action.payload }
    case SET_PLAYER_MAP:
        return { ...state, loading: true, message: null, error: null }
    case SET_PLAYER_MAP_SUCCESS:
        return { ...state, loading: false, message: action.payload, error: null }
    case SET_PLAYER_MAP_FAIL:
        return { ...state, loading: false, message: null, error: action.payload }
    case CREATE_PLAYER_MAP:
        return { ...state, loading: true, message: null, error: null }
    case CREATE_PLAYER_MAP_SUCCESS:
        return { ...state, loading: false, message: action.payload, error: null }
    case CREATE_PLAYER_MAP_FAIL:
        return { ...state, loading: false, message: null, error: action.payload }
    default:
        return state
    }
}

export default player