import { all, fork } from "redux-saga/effects"
// import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
// import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
// import cryptoSaga from "./crypto/saga"
// import invoiceSaga from "./invoices/saga"
// import projectsSaga from "./projects/saga"
// import tasksSaga from "./tasks/saga"
// import mailsSaga from "./mails/saga"
import userSaga from "./users/saga"
import bookingSaga from "./booking/saga"
import dashboardSaga from "./dashboard/saga"
import dashboardSaasSaga from "./dashboard-saas/saga"
import siteSaga from "./sites/saga"
import pillarSaga from "./pillars/saga"
import campaignSaga from "./campaign/saga"
import logSaga from "./log/saga"
import playerSaga from "./player/saga"
import commonSaga from "./common/saga"
// import contactsSaga from "./contacts/saga"
import accountSaga from "./accounts/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(accountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    // fork(calendarSaga),
    fork(chatSaga),
    // fork(mailsSaga),
    // fork(cryptoSaga),
    // fork(invoiceSaga),
    // fork(projectsSaga),
    // fork(tasksSaga),
    fork(userSaga),
    fork(bookingSaga),
    //   fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(siteSaga),
    fork(pillarSaga),
    fork(campaignSaga),
    fork(logSaga),
    fork(commonSaga),
    fork(playerSaga)
  ])
}
