import { del, get, post, postFormData, put, download, putFormData } from "./api_helper";
import * as url from "./url_v1_helper"

const getProvinces = () => get(url.GET_PROVINCES)
const getCities = action => get(`${url.GET_CITIES}/${action.payload}`)
const getDistricts = action => get(`${url.GET_DISTRICTS}/${action.payload}`)
const login = data => post(url.POST_LOGIN, data)
const logout = () => post(url.POST_LOGOUT)
const forgotPassword = data => post(url.POST_PASSWORD_FORGET, data)
const recoverAccount = data => post(url.POST_RECOVER, data)
const getMyProfile = () => get(url.PROFILE)
const updateMyProfile = data => post(url.PROFILE, data)
const getSites = params => get(url.SITES_URL, { params })
const getSiteData = id => get(`${url.SITES_URL}/${id}`)
const addNewSite = site => post(url.SITES_URL, site)
const updateSite = site => put(`${url.SITES_URL}/${site.id}`, site)
const deleteSite = site => del(`${url.SITES_URL}/${site.id}`)
const getPillars = () => get(url.PILLARS_URL)
const getPillarData = id => get(`${url.PILLARS_URL}/${id}`)
const getUnassignedPillars = siteId => get(`${url.PILLARS_URL}/unassigned/${siteId}`)
const addNewPillar = pillar => post(url.PILLARS_URL, pillar)
const updatePillar = pillar => put(`${url.PILLARS_URL}/${pillar.id}`, pillar)
const deletePillar = pillar => del(`${url.PILLARS_URL}/${pillar.id}`)
const getUsers = () => get(url.USERS_URL)
const getUserData = id => get(`${url.USERS_URL}/${id}`)
const addNewUser = user => post(url.USERS_URL, user)
const updateUser = user => put(`${url.USERS_URL}/${user.id}`, user)
const deleteUser = user => del(`${url.USERS_URL}/${user.id}`)
const getCampaigns = params => get(url.CAMPAIGNS_URL, { params })
const getCampaignsBySite = id => get(`${url.CAMPAIGNS_BYSITE_URL}/${id}`)
const getCampaignData = id => get(`${url.CAMPAIGNS_URL}/${id}`)
const addNewCampaign = campaign => post(url.CAMPAIGNS_URL, campaign)
const updateCampaign = campaign => put(`${url.CAMPAIGNS_URL}/${campaign.id}`, campaign)
const deleteCampaign = campaign => del(`${url.CAMPAIGNS_URL}/${campaign.id}`)
const getLedLogs = params => get(url.GET_LED_LOGS, { params })
const getLedSummary = params => get(url.GET_LED_SUMMARY, { params })
const getLedSummaryByCampaign = (campaignId, params) => get(`${url.GET_LED_SUMMARY}/by_campaign/${campaignId}`, { params })
const getLedLogsBySite = (siteId, params) => get(`${url.GET_LED_LOGS}/by_site/${siteId}`, { params })
const getLedLogsByCampaign = (campaignId, params) => get(`${url.GET_LED_LOGS}/by_campaign/${campaignId}`, { params })
const addStaticLog = data => postFormData(url.GET_STATIC_LOGS, data)
const getStaticLogs = params => get(url.GET_STATIC_LOGS, { params })
const getStaticLogData = id => get(`${url.GET_STATIC_LOGS}/${id}`)
const getStaticLogsBySite = (siteId, params) => get(`${url.GET_STATIC_LOGS}/by_site/${siteId}`, { params })
const getStaticLogsByCampaign = (campaignId, params) => get(`${url.GET_STATIC_LOGS}/by_campaign/${campaignId}`, { params })
const getRawLogs = params => get(url.GET_RAW_LOGS, { params })
const addNewBooking = booking => postFormData(url.BOOKINGS_URL, booking)
const updateBooking = booking => putFormData(`${url.BOOKINGS_URL}/${booking.get('id')}`, booking)
const deleteBooking = booking => del(`${url.BOOKINGS_URL}/${booking.id}`)
const getBookings = params => get(url.BOOKINGS_URL, { params })
const getBookingData = id => get(`${url.BOOKINGS_URL}/${id}`)
const downloadFile = (fileName, mimeType) => download(`${url.FILES_URL}/${fileName}`, {}, fileName, mimeType)
const getFileContent = (fileName) => get(`${url.FILES_URL}/${fileName}`)
const getSubIndustry = () => get(url.GET_SUB_INDUSTRY)
const getAdvertiser = () => get(url.GET_ADVERTISER)
const getSales = () => get(url.GET_SALES)
const updateCampaignStatus = data => post(`${url.CAMPAIGN_STATUS_URL}`, data)
const getPlayers = () => get(url.GET_PLAYERS)
const getPlayerData = id => get(`${url.GET_PLAYERS}/${id}`)
const createPlayerMap = data => post(`${url.CREATE_PLAYER_MAP_URL}`, data)
const setPlayerMap = data => post(`${url.PLAYER_MAP_URL}`, data)
const addNewAccount = data => post(url.GET_ACCOUNTS, data)
const updateAccount = data => put(`${url.GET_ACCOUNTS}/${data.id}`, data)
const getAccounts = () => get(url.GET_ACCOUNTS)
const getAccountData = id => get(`${url.GET_ACCOUNTS}/${id}`)
const deleteAccount = data => del(`${url.GET_ACCOUNTS}/${data.id}`)
const importRawLedLogs = data => postFormData(url.IMPORT_RAW_LED_LOGS, data)

export {
  getProvinces,
  getCities,
  getDistricts,
  login,
  logout,
  forgotPassword,
  recoverAccount,
  getMyProfile,
  updateMyProfile,
  getSites,
  addNewSite,
  updateSite,
  deleteSite,
  getSiteData,
  getPillars,
  getPillarData,
  getUnassignedPillars,
  addNewPillar,
  updatePillar,
  deletePillar,
  getUsers,
  getUserData,
  addNewUser,
  updateUser,
  deleteUser,
  getCampaigns,
  getCampaignsBySite,
  getCampaignData,
  addNewCampaign,
  updateCampaign,
  deleteCampaign,
  getLedLogs,
  getLedLogsBySite,
  getLedLogsByCampaign,
  addStaticLog,
  getStaticLogs,
  getStaticLogData,
  getStaticLogsBySite,
  getStaticLogsByCampaign,
  getRawLogs,
  addNewBooking,
  updateBooking,
  deleteBooking,
  getBookings,
  getBookingData,
  downloadFile,
  getSubIndustry,
  getAdvertiser,
  getSales,
  updateCampaignStatus,
  getPlayers,
  getPlayerData,
  setPlayerMap,
  createPlayerMap,
  addNewAccount,
  updateAccount,
  getAccounts,
  getAccountData,
  deleteAccount,
  getFileContent,
  importRawLedLogs,
  getLedSummary,
  getLedSummaryByCampaign
}
