import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Button, Label, FormGroup } from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import CustomSelect from "./../../components/Common/CustomSelect"
import Breadcrumbs from "components/Common/Breadcrumb"
import { addNewSite, getUnassignedPillars, getProvinces, getCities, getDistricts, resetSiteError } from './../../store/actions'
import  { OrientationFieldOptions, OOHTypeFieldOptions, ShareSlotFieldOptions } from "./../../constants/common"
import AlertModal from "../../components/Common/AlertModal"

class SiteCreate extends Component {
  componentDidMount () {
    const { onGetProvinces, getUnassignedPillars } = this.props

    onGetProvinces()
    getUnassignedPillars(0)
  }

  onProvinceChange (province, form) {
    form.setFieldValue("city", "")
    form.setFieldValue("district", "")

    this.props.onGetCities(province.value)
  }

  onCityChange (city, form) {
    form.setFieldValue("district", "")

    this.props.onGetDistricts(city.value)
  }

  render() {
    const { addNewSite, history, pillars, provinces, cities, districts, message, error, resetSiteError, loading } = this.props
    const provincesMap = provinces.map(item => ({ "value": item.id, "label": item.name }))
    const citiesMap = cities.map(item => ({ "value": item.id, "label": item.name }))
    const districtsMap = districts.map(item => ({ "value": item.id, "label": item.name }))
    const pillarOptions = pillars.map(item => ({ value: item.id, label: item.name }))

    let alertShow = false
    let alertTitle, alertMessage, alertType, alertCallback

    if (message && message.length) {
      alertShow = true
      alertTitle = 'Success'
      alertMessage = message
      alertType = 'success'
      alertCallback = () => history.push("/sites")
    }

    if (error && error.length) {
      alertShow = true
      alertTitle = 'Error'
      alertMessage = error
      alertType = 'error'
      alertCallback = () => resetSiteError()
    }

    return (
      <>
        <div className="page-content">
          <AlertModal show={alertShow} onCloseClick={alertCallback} title={alertTitle} message={alertMessage} type={alertType} />
          <MetaTags>
            <title>Create New Site | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Sites" breadcrumbItem="New Site" />
            <Row>
              <Col lg="12">
                <Formik
                  enableReinitialize={false}
                  initialValues={{
                    name: "",
                    latitude: "",
                    longitude: "",
                    address: "",
                    directional_view: "",
                    province: "",
                    city: "",
                    district: "",
                    type: "",
                    orientation: "",
                    number_set: "",
                    side_position: "",
                    share_slot: "",
                    total_spot_day: "",
                    pillars: []
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Please enter Site Name"),
                    latitude: Yup.string().required("Please enter Site Latitude"),
                    longitude: Yup.string().required("Please enter Site Longitude"),
                    address: Yup.string().required("Please enter Site Address"),
                    directional_view: Yup.string().required("Please enter Site Directional View"),
                    province: Yup.number().required("Please select Site Province"),
                    city: Yup.number().required("Please select Site City"),
                    district: Yup.number().required("Please select Site District"),
                    type: Yup.string().required("Please select Site Type"),
                    orientation: Yup.string().required("Please select Site Orientation"),
                    number_set: Yup.string().required("Please enter Site Numbers Set"),
                    side_position: Yup.string().required("Please enter Site Side Position"),
                    share_slot: Yup.string().required("Please select Site Share Slot"),
                    total_spot_day: Yup.string().required("Please enter Spot Day"),
                    pillars: Yup.array()
                  })}
                  onSubmit={values => {
                      let newValues = Object.keys(values).reduce((r, i) => {
                        let cur = values[i]

                        if (cur && cur.value) {
                          r[i] = cur.value
                        } else {
                          r[i] = cur
                        }

                        return r
                      }, {})

                      addNewSite(newValues)
                  }}
                  >
                  {({ errors, touched }) => (
                    <Form className="needs-validation">
                      <Card>
                        <div className="bg-transparent border-primary card-header">
                          <h5 className="my-0 text-primary">
                            <i className="bx bx-map-pin me-3"></i>
                            Site Name &amp; Location
                          </h5>
                        </div>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Site Name</Label>
                                <Field
                                  name="name"
                                  placeholder="Site name"
                                  type="text"
                                  className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="name" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Select Pillars</Label>
                                <Field
                                  name="pillars"
                                  placeholder="Select Pillars"
                                  options={pillarOptions}
                                  component={CustomSelect}
                                  classNamePrefix="select2-selection"
                                  isMulti={true}
                                />
                                <ErrorMessage name="pillars" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>

                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Latitude</Label>
                                <Field
                                  name="latitude"
                                  type="text"
                                  placeholder="Latitude"
                                  className={"form-control" + (errors.latitude && touched.latitude ? " is-invalid" : "")
                                  }
                                />
                                <ErrorMessage name="latitude" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Longitude</Label>
                                <Field
                                  name="longitude"
                                  placeholder="Longitude"
                                  type="text"
                                  className={"form-control" + (errors.longitude && touched.longitude ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="longitude" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Address</Label>
                                <Field
                                  name="address"
                                  placeholder="Address"
                                  as="textarea"
                                  rows="3"
                                  className={"form-control" + (errors.address && touched.address ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="address" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Directional View</Label>
                                <Field
                                  name="directional_view"
                                  placeholder="Directional View"
                                  as="textarea"
                                  rows="3"
                                  className={"form-control" + (errors.directional_view && touched.directional_view ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="directional_view" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label>Province</Label>
                                <Field name="province" placeholder="Province" options={provincesMap} onChanged={this.onProvinceChange.bind(this)} component={CustomSelect} />
                                <ErrorMessage name="province" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label>City</Label>
                                <Field name="city" placeholder="City" options={citiesMap} onChanged={this.onCityChange.bind(this)} component={CustomSelect} />
                                <ErrorMessage name="city" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label>District</Label>
                                <Field name="district" placeholder="District" options={districtsMap} component={CustomSelect} />
                                <ErrorMessage name="district" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card>
                        <div className="bg-transparent border-primary card-header">
                          <h5 className="my-0 text-primary">
                            <i className="bx bx-screenshot me-3"></i>
                            Site Size &amp; Detail
                          </h5>
                        </div>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Type</Label>
                                <Field name="type" placeholder="Type" options={OOHTypeFieldOptions} component={CustomSelect} />
                                <ErrorMessage name="type" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Orientation</Label>
                                <Field name="orientation" placeholder="Orientation" options={OrientationFieldOptions} component={CustomSelect} />
                                <ErrorMessage name="orientation" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Numbers Set</Label>
                                <Field
                                  name="number_set"
                                  type="text"
                                  placeholder="Numbers Set"
                                  className={"form-control" + (errors.number_set && touched.number_set ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="number_set" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Side Position</Label>
                                <Field
                                  name="side_position"
                                  placeholder="Side Position"
                                  type="text"
                                  className={"form-control" + (errors.side_position && touched.side_position ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="side_position" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Share Slot</Label>
                                <Field name="share_slot" placeholder="Share Slot" options={ShareSlotFieldOptions} component={CustomSelect} />
                                <ErrorMessage name="share_slot" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Total Spot Day</Label>
                                <Field
                                  name="total_spot_day"
                                  placeholder="Total Spot Day"
                                  type="text"
                                  className={"form-control" + (errors.total_spot_day && touched.total_spot_day ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="total_spot_day" component="div" className="invalid-feedback" />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" className="text-right">
                              <FormGroup className="mb-3">
                                <Button color="primary" type="submit" disabled={loading}>{loading ? 'Saving' : 'Save'}</Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}

SiteCreate.propTypes = {
  provinces: PropTypes.array,
  cities: PropTypes.array,
  districts: PropTypes.array,
  onGetProvinces: PropTypes.func,
  onGetCities: PropTypes.func,
  onGetDistricts: PropTypes.func,
  getUnassignedPillars: PropTypes.func,
  addNewSite: PropTypes.func,
  resetSiteError: PropTypes.func,
  history: PropTypes.object,
  pillars: PropTypes.array,
  message: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
}

const mapStateToProps = ({ common, pillars, sites }) => ({
  provinces: common.provinces,
  cities: common.cities,
  districts: common.districts,
  pillars: pillars && pillars.pillars && pillars.pillars.data ? pillars.pillars.data : [],
  message: sites.message,
  error: sites.error,
  loading: sites.loading
})
const mapDispatchToProps = dispatch => ({
  onGetProvinces: () => dispatch(getProvinces()),
  onGetCities: provinceId => dispatch(getCities(provinceId)),
  onGetDistricts: cityId => dispatch(getDistricts(cityId)),
  getUnassignedPillars: siteId => dispatch(getUnassignedPillars(siteId)),
  addNewSite: data => dispatch(addNewSite(data)),
  resetSiteError: () => dispatch(resetSiteError())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SiteCreate))