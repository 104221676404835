import { call, put, takeEvery } from "redux-saga/effects"
import { GET_PROVINCES, GET_CITIES, GET_DISTRICTS, GET_SUB_INDUSTRY, GET_ADVERTISER, GET_SALES } from "./actionTypes"
import {
  getProvincesSuccess,
  getProvincesFail,
  getCitiesSuccess,
  getCitiesFail,
  getDistrictsSuccess,
  getDistrictsFail,
  getSubIndustrySuccess,
  getSubIndustryFail,
  getAdvertiserSuccess,
  getAdvertiserFail,
  getSalesSuccess,
  getSalesFail
} from "./actions"
import { getProvinces, getCities, getDistricts, getSubIndustry, getAdvertiser, getSales } from "../../helpers/api_v1_helper"

function* fetchProvinces() {
  try {
    const response = yield call(getProvinces)
    yield put(getProvincesSuccess(response.data))
  } catch (error) {
    yield put(getProvincesFail(error))
  }
}

function* fetchCities (provinceId) {
  try {
    const response = yield call(getCities, provinceId)
    yield put(getCitiesSuccess(response.data))
  } catch (error) {
    yield put(getCitiesFail(error))
  }
}

function* fetchDistricts(cityId) {
  try {
    const response = yield call(getDistricts, cityId)
    yield put(getDistrictsSuccess(response.data))
  } catch (error) {
    yield put(getDistrictsFail(error))
  }
}

function* fetchSubIndustry() {
  try {
    const response = yield call(getSubIndustry)
    yield put(getSubIndustrySuccess(response.data))
  } catch (error) {
    yield put(getSubIndustryFail(error))
  }
}

function* fetchAdvertiser() {
  try {
    const response = yield call(getAdvertiser)
    yield put(getAdvertiserSuccess(response.data))
  } catch (error) {
    yield put(getAdvertiserFail(error))
  }
}

function* fetchSales() {
  try {
    const response = yield call(getSales)
    yield put(getSalesSuccess(response.data))
  } catch (error) {
    yield put(getSalesFail(error))
  }
}

function* siteSaga() {
  yield takeEvery(GET_PROVINCES, fetchProvinces)
  yield takeEvery(GET_CITIES, fetchCities)
  yield takeEvery(GET_DISTRICTS, fetchDistricts)
  yield takeEvery(GET_SUB_INDUSTRY, fetchSubIndustry)
  yield takeEvery(GET_ADVERTISER, fetchAdvertiser)
  yield takeEvery(GET_SALES, fetchSales)
}

export default siteSaga