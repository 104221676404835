import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Modal, Button, ModalHeader, ModalBody, Label, Spinner } from "reactstrap"
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator"
import CustomSelect from "./../../../components/Common/CustomSelect"
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { getUsers, addNewUser, updateUser, deleteUser, clearUserError } from "store/users/actions"
import { UserRoleFieldOptions } from "constants/common"
import AlertModal from "../../../components/Common/AlertModal"

const { ExportCSVButton } = CSVExport
class UsersList extends Component {
  constructor(props) {
    super(props)
    this.node = React.createRef()
    this.state = {
      users: [],
      selected: false,
      modal: false,
      deleteModal: false
    }

    this.handleUserClick = this.handleUserClick.bind(this)
    this.toggle = this.toggle.bind(this)
    this.handleUserClicks = this.handleUserClicks.bind(this)
    this.onClickDelete = this.onClickDelete.bind(this)
  }

  componentDidMount() {
    const { onGetUsers } = this.props

    onGetUsers()
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }

  handleUserClicks = () => {
    this.setState({ selected: false, isEdit: false })
    this.toggle()
  }

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page)
    }
  }

  onClickDelete = selected => {
    this.setState({ selected })
    this.setState({ deleteModal: true })
  }

  handleDeleteUser = () => {
    const { onDeleteUser } = this.props
    const { selected } = this.state
    if (selected !== false) {
      onDeleteUser(selected)
      this.setState({ deleteModal: false })
    }
  }

  handleUserClick = selected => {
    this.setState({ selected, isEdit: true })
    this.toggle()
  }

  render() {
    const { SearchBar } = Search
    const { isEdit, deleteModal, selected } = this.state
    const { onAddNewUser, onUpdateUser, users, error, clearUserError, loading } = this.props
    const pageOptions = {sizePerPage: 10, totalSize: users.length, custom: true,}

    const userListColumns = [
      {
        text: "id",
        dataField: "id",
        sort: true,
        hidden: true,
        csvExport: false,
        formatter: (cellContent, user) => <>{user.id}</>,
      },
      {
        dataField: "img",
        text: "Initial",
        csvExport: false,
        formatter: (cellContent, user) => (
          <>
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">{user.name.charAt(0)}</span>
            </div>
          </>
        ),
      },
      {
        text: "Name",
        dataField: "name",
        sort: true,
        formatter: (cellContent, user) => (
          <>
            <h5 className="font-size-14 mb-1">
              <Link to={"/users/" + user.id}>{user.name}</Link>
            </h5>
          </>
        ),
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "role",
        text: "Role",
        sort: true,
        formatter: (cellContent, user) => {
          let role = UserRoleFieldOptions.find(item => item.value === user.role)

          return role ? role.label : user.role
        }
      },
      {
        dataField: "menu",
        isDummyField: true,
        editable: false,
        csvExport: false,
        text: "Action",
        formatter: (cellContent, user) => (
          <div className="d-flex gap-3">
            <Link className="badge badge-soft-danger" to="#">
              <i className="bx bx-trash font-size-18" id="deletetooltip" onClick={() => this.onClickDelete(user)}></i>
            </Link>
            <Link className="badge badge-soft-secondary" to="#">
              <i className="bx bx-edit font-size-18" id="edittooltip" onClick={() => this.handleUserClick(user)}></i>
            </Link>
            <Link to={"/users/" + user.id} className="badge badge-soft-primary" title="View">
              <i className="mdi mdi-eye-outline font-size-18" />
            </Link>
          </div>
        ),
      },
    ]

    return (
      <React.Fragment>
        <AlertModal show={error.length > 0} onCloseClick={clearUserError} title="Error" message={error} type="error" />
        <DeleteModal show={deleteModal} onDeleteClick={this.handleDeleteUser} onCloseClick={() => this.setState({ deleteModal: false })} />
        <div className="page-content">
          <MetaTags>
            <title>Users List | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Users" breadcrumbItem="Users List" />
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField="id" columns={userListColumns} data={users}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider keyField="id" columns={userListColumns} data={users} search exportCSV>
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar{...toolkitprops.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <ExportCSVButton {...toolkitprops.csvProps} className="btn btn-secondary mr-10">Export to CSV</ExportCSVButton>
                                    <Button color="primary" className="font-16 btn-block btn btn-primary" onClick={this.handleUserClicks}>
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New User
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      classes={"table align-middle table-nowrap table-hover"}
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                      noDataIndication={loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                    />
                                    <Modal isOpen={this.state.modal} className={this.props.className}>
                                      <ModalHeader toggle={this.toggle} tag="h4">{isEdit ? "Edit User" : "Add User"}</ModalHeader>
                                      <ModalBody>
                                        <Formik
                                          enableReinitialize={true}
                                          initialValues={{
                                            id: isEdit && selected ? selected.id : "",
                                            name: isEdit && selected ? selected.name : "",
                                            email: isEdit && selected ? selected.email : "",
                                            role: isEdit && selected ? selected.role : "",
                                          }}
                                          validationSchema={Yup.object().shape({
                                            name: Yup.string().required("Please Enter Your Name"),
                                            email: Yup.string().required("Please Enter Your Email"),
                                            role: Yup.string().required("Please Enter Your role"),
                                          })}
                                          onSubmit={values => {
                                            if (isEdit) {
                                              const updateUser = {
                                                id: selected.id,
                                                name: values.name,
                                                email: values.email,
                                                role: values.role,
                                              }

                                              onUpdateUser(updateUser)
                                            } else {
                                              const newUser = {
                                                name: values.name,
                                                email: values.email,
                                                role: values.role,
                                              }

                                              onAddNewUser(newUser)
                                            }
                                            this.setState({ selectedUser: null })
                                            this.toggle()
                                          }}
                                        >
                                          {({ errors, touched, isSubmitting }) => (
                                            <Form>
                                              <Row>
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <Label className="form-label">Name</Label>
                                                    <Field
                                                      name="name"
                                                      type="text"
                                                      className={"form-control" + (errors.name && touched.name ? " is-invalid" : "")}
                                                    />
                                                    <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">Email</Label>
                                                    <Field
                                                      name="email"
                                                      type="text"
                                                      className={"form-control" + (errors.email && touched.email ? " is-invalid" : "")}
                                                    />
                                                    <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">Role</Label>
                                                    <Field name="role" options={UserRoleFieldOptions} component={CustomSelect} />
                                                    <ErrorMessage name="role" component="div" className="invalid-feedback"/>
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="text-end">
                                                    <button type="submit" className="btn btn-success save-user" disabled={isSubmitting}>Save</button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Form>
                                          )}
                                        </Formik>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone{...paginationProps}/>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

UsersList.propTypes = {
  users: PropTypes.array,
  error: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
  clearUserError: PropTypes.func
}

const mapStateToProps = ({ users }) => ({
  users: users && users.users && users.users.data ? users.users.data : [],
  loading: users.loading,
  error: users.error
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
  clearUserError: () => dispatch(clearUserError())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersList))
