import React, { Component } from "react"
import { connect } from "react-redux"
import {Row, Col, Modal, ModalHeader, ModalBody, Label} from "reactstrap"
import PropTypes from "prop-types"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import CustomSelect from "../../components/Common/CustomSelect"
import { getAccounts, importLedLogs } from "./../../store/actions"
import CustomFileInput from "../../components/Common/CustomFileInput"

class ImportDialogAction extends Component {
    componentDidMount() {
        const { getAccounts } = this.props

        getAccounts()
    }

    render() {
        const { title, callback, loading, accounts, importLedLogs } = this.props
        const optSources = accounts.map(item => ({ value: item.id, label: `${item.url} (${item.username})` }))

        return (
            <Modal isOpen={true}>
                <ModalHeader tag="h4" toggle={callback}>{title}</ModalHeader>
                <ModalBody>
                    <Formik
                        initialValues={{
                            source: "",
                            file: ""
                        }}
                        validationSchema={Yup.object().shape({
                            source: Yup.string().required("Please Enter Source")
                        })}
                        onSubmit={values => {
                            const formData = new FormData()

                            let newValues = Object.keys(values).reduce((r, i) => {
                                let cur = values[i]
                                let val = cur && cur.value ? cur.value : cur

                                r.append(i, val)

                                return r
                            }, formData)

                            importLedLogs(newValues, callback)
                        }}
                    >
                        {
                            ({ errors, touched }) => (
                                <Form>
                                    <Row>
                                        <Col className="col-12">
                                            <div className="mb-3">
                                                <Label className="form-label">File</Label>
                                                <Field
                                                    name="file"
                                                    type="file"
                                                    multiple={false}
                                                    component={CustomFileInput}
                                                    accept=".csv"
                                                    placeholder="Upload File"
                                                    className={"form-control" + (errors.image && touched.image ? " is-invalid" : "")}
                                                />
                                                <ErrorMessage name="file" component="div" className="invalid-feedback"/>
                                            </div>
                                            <div className="mb-3">
                                                <Label className="form-label">Source</Label>
                                                <Field
                                                    name="source"
                                                    placeholder="Select Source"
                                                    component={CustomSelect}
                                                    options={[{label: "Select Source", options: optSources }]}
                                                    classNamePrefix="select2-selection"
                                                />
                                                <ErrorMessage name="source" component="div" className="invalid-feedback"/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="text-end">
                                                <button type="submit" className="btn btn-success save-user" disabled={loading}>{loading ? "Saving" : "Save"}</button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            )
                        }
                    </Formik>
                </ModalBody>
            </Modal>
        )
    }
}

ImportDialogAction.propTypes = {
    accounts: PropTypes.array,
    title: PropTypes.string,
    callback: PropTypes.func,
    data: PropTypes.object,
    loading: PropTypes.bool,
    getAccounts: PropTypes.func,
    importLedLogs: PropTypes.func
}

const mapStateToProps = ({ account }) => ({
    accounts: account.accounts && account.accounts.data ? account.accounts.data : [],
    loading: account.loading
})

const mapDispatchToProps = dispatch => ({
    getAccounts: () => dispatch(getAccounts()),
    importLedLogs: (payload, callback) => dispatch(importLedLogs(payload, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(ImportDialogAction)