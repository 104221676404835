import {
  GET_BOOKINGS,
  GET_BOOKINGS_SUCCESS,
  GET_BOOKINGS_FAIL,
  ADD_NEW_BOOKING,
  ADD_BOOKING_SUCCESS,
  ADD_BOOKING_FAIL,
  UPDATE_BOOKING,
  UPDATE_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAIL,
  DELETE_BOOKING,
  DELETE_BOOKING_SUCCESS,
  DELETE_BOOKING_FAIL,
  GET_BOOKING_DATA,
  GET_BOOKING_DATA_SUCCESS,
  GET_BOOKING_DATA_FAIL,
  RESET_BOOKING_MESSAGE,
  RESET_BOOKING_ERROR
} from "./actionTypes"

const INIT_STATE = {
  bookings: [],
  bookingData: {},
  error: null,
  message: null,
  loading: false
}

const bookings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_NEW_BOOKING:
      return { ...state, loading: true, message: null, error: null }
    case ADD_BOOKING_SUCCESS:
      return { ...state, loading: false, message: action.payload, error: null }
    case ADD_BOOKING_FAIL:
      return { ...state, loading: false, message: null, error: action.payload }
    case UPDATE_BOOKING:
      return { ...state, loading: true, message: null, error: null }
    case UPDATE_BOOKING_SUCCESS:
      return { ...state, loading: false, message: action.payload, error: null }
    case UPDATE_BOOKING_FAIL:
      return { ...state, loading: false, message: null, error: action.payload }
    case DELETE_BOOKING:
      return { ...state, loading: true, message: null, error: null }
    case DELETE_BOOKING_SUCCESS:
      return { ...state, loading: false, message: action.payload, error: null }
    case DELETE_BOOKING_FAIL:
      return { ...state, loading: false, message: null, error: action.payload }
    case GET_BOOKINGS:
      return { ...state, loading: true, message: null, error: null, bookings: [] }
    case GET_BOOKINGS_SUCCESS:
      return { ...state, loading: false, message: null, error: null, bookings: action.payload }
    case GET_BOOKINGS_FAIL:
      return { ...state, loading: false, message: null, error: action.payload, bookings: [] }
    case GET_BOOKING_DATA:
      return { ...state, loading: true, message: null, error: null, bookingData: null }
    case GET_BOOKING_DATA_SUCCESS:
      return { ...state, loading: false, message: null, error: null, bookingData: action.payload }
    case GET_BOOKING_DATA_FAIL:
      return { ...state, loading: false, message: null, error: action.payload, bookingData: null }
    case RESET_BOOKING_ERROR:
      return { ...state, error: null }
    case RESET_BOOKING_MESSAGE:
      return { ...state, message: null }
    default:
      return state
  }
}

export default bookings
