import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { DateTime } from "luxon"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Row, InputGroup, FormGroup, Button, Spinner } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { getLedSummary } from "../../store/actions"

const { ExportCSVButton } = CSVExport

class MlpLedSummary extends Component {
    constructor (props) {
        super(props)

        this.state = {
            queryParams: {}
        }

        this.flatPicker = React.createRef()
        this.onDateRangeChange = this.onDateRangeChange.bind(this)
        this.onTableChange = this.onTableChange.bind(this)
    }

    componentDidMount() {
        const { getLedSummary } = this.props

        getLedSummary()
    }

    onDateRangeChange (data) {
        const { getLedSummary } = this.props
        const [from = false, to = false] = data
        const queryParams = this.state.queryParams

        if (from) {
            queryParams.from = DateTime.fromJSDate(from).toFormat('y-MM-dd')
        }

        if (to) {
            queryParams.to = DateTime.fromJSDate(to).toFormat('y-MM-dd')
        }

        this.setState({ queryParams })

        getLedSummary(queryParams)
    }

    onTableChange (e, data) {
        const { getLedSummary } = this.props
        const { searchText, page, sizePerPage, sortField, sortOrder } = data
        const queryParams = this.state.queryParams

        queryParams.page = '1'
        queryParams.limit = '10'
        queryParams.search = ''

        if (!isNaN(page) && page > 1) {
            queryParams.page = page
        }

        if (!isNaN(sizePerPage) && sizePerPage > 2) {
            queryParams.limit = sizePerPage
        }

        if (searchText && searchText.length) {
            queryParams.search = searchText
        }

        this.setState({ queryParams })

        getLedSummary(queryParams)
    }

    render() {
        const { logs, loading } = this.props
        const { rows = [], limit = 10, count = 0, page } = logs || {}
        const columns = [
            {
                dataField: 'start_time',
                text: 'Period',
                sort: true,
                csvExport: true,
                formatter: (cellContent, item) => DateTime.fromSeconds(item.start_time).toFormat('dd MMM y'),
                csvFormatter: (cellContent, item) => DateTime.fromSeconds(item.start_time).toFormat('dd MMM y')
            },
            {
                dataField: 'player_name',
                text: 'Player Name',
                sort: true,
                csvExport: true
            },
            {
                dataField: 'media_name',
                text: 'Campaign',
                sort: true,
                csvExport: true
            },
            {
                dataField: 'media_name',
                text: 'Media Name',
                sort: true,
                csvExport: true
            },
            {
                dataField: 'play_count',
                text: 'Play Count',
                sort: true,
                csvExport: true
            },
            {
                dataField: 'total_duration',
                text: 'Duration',
                sort: true,
                csvExport: true
            }
        ]

        const pageOptions = { sizePerPage: limit, totalSize: count, custom: true, page: Number(page) }
        const { SearchBar } = Search

        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Media Log Proof Led | Nord &amp; Smith Dashboard</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Media Log Proof" breadcrumbItem="Led Summary" />
                        <Row>
                            <Col className="col-12">
                                <Card>
                                    <CardBody>
                                        <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={columns} data={rows}>
                                            {({ paginationProps, paginationTableProps }) => (
                                                <ToolkitProvider keyField='id' columns={columns} data={rows} search>
                                                    {toolkitProps => (
                                                        <React.Fragment>
                                                            <Row className="mb-2">
                                                                <Col md="6">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar{...toolkitProps.searchProps}/>
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col md="6">
                                                                    <div className="text-sm-end">
                                                                        <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-secondary mr-5">Export to CSV</ExportCSVButton>
                                                                        <Button className="mr-5 btn-dark" onClick={() => this.flatPicker.current.flatpickr.clear()}>Reset</Button>
                                                                        <FormGroup className="btn-datepicker">
                                                                            <InputGroup>
                                                                                <Flatpickr className="form-control btn btn-outline-primary" placeholder="Select Date" options={{mode: "range", dateFormat: "Y-m-d"}} onChange={this.onDateRangeChange} ref={this.flatPicker} />
                                                                            </InputGroup>
                                                                        </FormGroup>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col xl="12">
                                                                    <div className="table-responsive">
                                                                        <BootstrapTable
                                                                            keyField="id"
                                                                            responsive
                                                                            bordered={true}
                                                                            striped={true}
                                                                            hover={true}
                                                                            classes="table align-middle table-nowrap"
                                                                            headerWrapperClasses="thead-light"
                                                                            noDataIndication={ loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                                                            {...toolkitProps.baseProps}
                                                                            {...paginationTableProps}
                                                                            loading={true}
                                                                            remote={{filter: true, pagination: true, sort: true, cellEdit: false}}
                                                                            onTableChange={this.onTableChange}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row className="align-items-md-center mt-30">
                                                                <Col className="inner-custom-pagination d-flex">
                                                                    <div className="d-inline">
                                                                        <SizePerPageDropdownStandalone{...paginationProps}/>
                                                                    </div>
                                                                    <div className="text-md-right ms-auto">
                                                                        <PaginationListStandalone{...paginationProps}/>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </React.Fragment>
                                                    )}
                                                </ToolkitProvider>
                                            )
                                            }</PaginationProvider>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

MlpLedSummary.propTypes = {
    logs: PropTypes.object,
    message: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    getLedSummary: PropTypes.func,
    history: PropTypes.object
}

const mapStateToProps = ({ log }) => ({
    logs: log.log_led_summary && log.log_led_summary.data ? log.log_led_summary.data : null,
    message: log.log_led_message,
    error: log.log_led_error,
    loading: log.log_led_loading
})

const mapDispatchToProps = dispatch => ({
    getLedSummary: queries => dispatch(getLedSummary(queries))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpLedSummary))