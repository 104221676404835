/* PILLARS */
export const GET_PILLARS = "GET_PILLARS"
export const GET_PILLARS_SUCCESS = "GET_PILLARS_SUCCESS"
export const GET_PILLARS_FAIL = "GET_PILLARS_FAIL"

/* PILLARS PROFILE */
export const GET_PILLAR_PROFILE = "GET_PILLAR_PROFILE"
export const GET_PILLAR_PROFILE_SUCCESS = "GET_PILLAR_PROFILE_SUCCESS"
export const GET_PILLAR_PROFILE_FAIL = "GET_PILLAR_PROFILE_FAIL"

export const ADD_NEW_PILLAR = "ADD_NEW_PILLAR"
export const ADD_PILLAR_SUCCESS = "ADD_PILLAR_SUCCESS"
export const ADD_PILLAR_FAIL = "ADD_PILLAR_FAIL"

export const UPDATE_PILLAR = "UPDATE_PILLAR"
export const UPDATE_PILLAR_SUCCESS = "UPDATE_PILLAR_SUCCESS"
export const UPDATE_PILLAR_FAIL = "UPDATE_PILLAR_FAIL"

export const DELETE_PILLAR = "DELETE_PILLAR"
export const DELETE_PILLAR_SUCCESS = "DELETE_PILLAR_SUCCESS"
export const DELETE_PILLAR_FAIL = "DELETE_PILLAR_FAIL"