import React, { Component } from "react"
import MetaTags from "react-meta-tags"
import { Card, CardBody, Col, Row, Spinner, Table } from "reactstrap"
import Select from "react-select"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import BarLineChart from "./BarLineChart"
import BarChart from "./BarChart"
import BarChart2 from "./BarChart2"
import BootstrapTable from 'react-bootstrap-table-next'
import { Link } from "react-router-dom"
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { DateTime } from "luxon"
import { getRawLogs } from "../../store/actions"
import { connect } from "react-redux"
import PropTypes from "prop-types";

const yearData = [
  {
    label: "Select Year",
    options: [
      {value:"1", label:"2021"},
      {value:"2", label:"2022"},
      {value:"3", label:"2023"},
    ]
  },
]

class Overview extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { getRawLogs } = this.props

    getRawLogs({

    })
  }

  render() {
    const { logs, loading } = this.props
    const { rows = [], limit = 10, count = 0, page = 1 } = logs || {}
    const columns = [
      {
        dataField: 'player_id',
        text: 'Player ID',
        sort: true,
        csvExport: true
      },
      {
        dataField: 'player_name',
        text: 'Player Name',
        sort: true,
        csvExport: true
      },
      {
        dataField: 'media_name',
        text: 'Media Name',
        sort: true,
        csvExport: true
      },
      {
        dataField: 'duration',
        text: 'Duration',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => `${log.duration} sec`,
        csvFormatter: (cellContent, log) => log.duration
      },
      {
        dataField: 'start_time',
        text: 'Start Time',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => DateTime.fromSeconds(log.start_time).toFormat('dd MMM y, HH:mm:ss'),
        csvFormatter: (cellContent, log) => DateTime.fromSeconds(log.start_time).toFormat('dd MMM y, HH:mm:ss')
      },
      {
        dataField: 'end_time',
        text: 'End Time',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => DateTime.fromSeconds(log.end_time).toFormat('dd MMM y, HH:mm:ss'),
        csvFormatter: (cellContent, log) => DateTime.fromSeconds(log.end_time).toFormat('dd MMM y, HH:mm:ss')
      },
      {
        dataField: 'source',
        text: 'Source',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => log.source,
        csvFormatter: (cellContent, log) => log.source
      },
      {
        dataField: 'user',
        text: 'User',
        sort: true,
        csvExport: true,
        formatter: (cellContent, log) => log.user,
        csvFormatter: (cellContent, log) => log.user
      }
    ]

    const pageOptions = { sizePerPage: limit, totalSize: count, custom: true, page: Number(page) }

    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>Overview | Nord &amp; Smith Dashboard</title>
            </MetaTags>
            <div className="container-fluid">
              <Breadcrumbs title="Dashboard" breadcrumbItem="Overview" />
              <Row>
                <Col className="col-12">
                  <Row>
                    <Col md={4}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h5 className="mb-0">
                                <Link to="pillars" className="btn btn-lg btn-primary text-block">
                                  <i className="bx bx-map-pin font-size-16 align-middle me-2"/>
                                  Pillar List
                                </Link>
                              </h5>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={4}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h5 className="mb-0">
                                <Link to="sites" className="btn btn-lg btn-primary text-block">
                                  <i className="bx bx-carousel font-size-16 align-middle me-2"/>
                                  Site List
                                </Link>
                              </h5>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={4}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h5 className="mb-0">
                                <Link to="booking" className="btn btn-lg btn-primary text-block">
                                  <i className="bx bx-receipt font-size-16 align-middle me-2"/>
                                  Booking List
                                </Link>
                              </h5>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={4}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h5 className="mb-0">
                                <Link to="mlp-campaign-list" className="btn btn-lg btn-primary text-block">
                                  <i className="bx bx-receipt font-size-16 align-middle me-2"/>
                                  Campaign
                                </Link>
                              </h5>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={4}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h5 className="mb-0">
                                <Link to="mlp-led-list" className="btn btn-lg btn-primary text-block">
                                  <i className="bx bx-receipt font-size-16 align-middle me-2"/>
                                  LED
                                </Link>
                              </h5>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={4}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h5 className="mb-0">
                                <Link to="mlp-static-list" className="btn btn-lg btn-primary text-block">
                                  <i className="bx bx-receipt font-size-16 align-middle me-2"/>
                                  Static
                                </Link>
                              </h5>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h5 className="mb-0">
                                <Link to="mlp-raw-list" className="btn btn-lg btn-primary text-block">
                                  <i className="bx bx-receipt font-size-16 align-middle me-2"/>
                                  Raw
                                </Link>
                              </h5>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <h5 className="mb-0">
                                <Link to="mlp-mapping-list" className="btn btn-lg btn-primary text-block">
                                  <i className="bx bx-receipt font-size-16 align-middle me-2"/>
                                  Mapping
                                </Link>
                              </h5>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                  </Row>
                </Col>
                <Col className="col-12">
                  <Card>
                    <CardBody>
                      <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={columns} data={rows}>
                        {({ paginationTableProps }) => (
                            <ToolkitProvider keyField='id' columns={columns} data={rows}>
                              {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                              keyField="id"
                                              responsive
                                              bordered={true}
                                              striped={true}
                                              hover={true}
                                              classes="table align-middle table-nowrap"
                                              headerWrapperClasses="thead-light"
                                              noDataIndication={ loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                              {...toolkitProps.baseProps}
                                              {...paginationTableProps}
                                              loading={true}
                                              remote={{filter: true, pagination: true, sort: true, cellEdit: false}}
                                              onTableChange={this.onTableChange}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                              )}
                            </ToolkitProvider>
                        )}

                      </PaginationProvider>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="col-12 hide">
                  <Row>
                    <Col md={8}>
                      <Card>
                        <CardBody className="border-bottom">
                          <Row>
                            <Col md={9}>
                              <h6 className="mt-10">Monthly Booking Value</h6>
                            </Col>
                            <Col md={3}>
                              <Select placeholder="Select Year" options={yearData} classNamePrefix="select2-selection" />
                            </Col>
                          </Row>
                        </CardBody>
                        <CardBody>
                          <BarLineChart />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={4}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <small className="text-muted fw-medium mb-2 display-block">Booking Total Value</small>
                              <h5 className="mb-0">
                                Rp. 999.999.999.999
                              </h5>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <i className="bx bx-layer font-size-24"/>
                            </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <small className="text-muted fw-medium mb-15 text-block">Top 5 Brand Spending</small>
                          <ol className="do-ol">
                            <li>
                              <b className="display-block">Brand Name</b>
                              <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry</small>
                            </li>
                            <li>
                              <b className="display-block">Brand Name</b>
                              <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small>
                            </li>
                            <li>
                              <b className="display-block">Brand Name</b>
                              <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small>
                            </li>
                            <li>
                              <b className="display-block">Brand Name</b>
                              <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small>
                            </li>
                            <li>
                              <b className="display-block">Brand Name</b>
                              <small>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</small>
                            </li>
                          </ol>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-12 hide">
                  <Row>
                    <Col md={4}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <small className="text-muted fw-medium mb-2 display-block">Occupancy Rate LED</small>
                              <h5 className="mb-0">99.99%</h5>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <span className="font-size-24">%</span>
                            </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <small className="text-muted fw-medium mb-15 text-block">Site with Least Occupancy</small>
                          <Table className="table table-bordered">
                            <tbody>
                            <tr>
                              <th>ST. ASEAN LED SET 1</th>
                              <td>35%</td>
                            </tr>
                            <tr>
                              <th>ST. ASEAN LED SET 2</th>
                              <td>35%</td>
                            </tr>
                            <tr>
                              <th>ST. ASEAN LED SET 3</th>
                              <td>35%</td>
                            </tr>
                            <tr>
                              <th>ST. ASEAN LED SET 4</th>
                              <td>35%</td>
                            </tr>
                            <tr>
                              <th>ST. ASEAN LED SET 5</th>
                              <td>35%</td>
                            </tr>
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={8}>
                      <Card>
                        <CardBody className="border-bottom">
                          <Row>
                            <Col md={9}>
                              <h6 className="mt-10">Monthly Occupancy Rate LED</h6>
                            </Col>
                            <Col md={3}>
                              <Select placeholder="Select Year" options={yearData} classNamePrefix="select2-selection" />
                            </Col>
                          </Row>
                        </CardBody>
                        <CardBody>
                          <BarChart />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col className="col-12 hide">
                  <Row>
                    <Col md={8}>
                      <Card>
                        <CardBody className="border-bottom">
                          <Row>
                            <Col md={9}>
                              <h6 className="mt-10">Monthly Occupancy Rate Static</h6>
                            </Col>
                            <Col md={3}>
                              <Select placeholder="Select Year" options={yearData} classNamePrefix="select2-selection" />
                            </Col>
                          </Row>
                        </CardBody>
                        <CardBody>
                          <BarChart2 />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={4}>
                      <Card>
                        <CardBody className="mini-stats-wid">
                          <div className="d-flex">
                            <div className="flex-grow-1">
                              <small className="text-muted fw-medium mb-2 display-block">Occupancy Rate Static</small>
                              <h5 className="mb-0">99.99%</h5>
                            </div>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                            <span className="avatar-title">
                              <span className="font-size-24">%</span>
                            </span>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <small className="text-muted fw-medium mb-15 text-block">Site Available with most value</small>
                          <Table className="table table-bordered">
                            <tbody>
                            <tr>
                              <th>ST. ASEAN STATIC SET 1</th>
                              <td>35%</td>
                            </tr>
                            <tr>
                              <th>ST. ASEAN STATIC SET 2</th>
                              <td>35%</td>
                            </tr>
                            <tr>
                              <th>ST. ASEAN STATIC SET 3</th>
                              <td>35%</td>
                            </tr>
                            <tr>
                              <th>ST. ASEAN STATIC SET 4</th>
                              <td>35%</td>
                            </tr>
                            <tr>
                              <th>ST. ASEAN STATIC SET 5</th>
                              <td>35%</td>
                            </tr>
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </React.Fragment>
    )
  }
}

Overview.propTypes = {
  logs: PropTypes.object,
  error: PropTypes.string,
  loading: PropTypes.bool,
  getRawLogs: PropTypes.func
}

const mapStateToProps = ({ log }) => ({
  logs: log.logs_raw && log.logs_raw.data ? log.logs_raw.data : null,
  error: log.log_raw_error,
  loading: log.log_raw_loading
})

const mapDispatchToProps = dispatch => ({
  getRawLogs: queries => dispatch(getRawLogs(queries))
})

export default connect(mapStateToProps, mapDispatchToProps)(Overview)