import {
  GET_SITE_DATA,
  GET_SITE_DATA_FAIL,
  GET_SITE_DATA_SUCCESS,
  GET_SITES,
  GET_SITES_FAIL,
  GET_SITES_SUCCESS,
  ADD_NEW_SITE,
  ADD_SITE_SUCCESS,
  ADD_SITE_FAIL,
  UPDATE_SITE,
  UPDATE_SITE_SUCCESS,
  UPDATE_SITE_FAIL,
  DELETE_SITE,
  DELETE_SITE_SUCCESS,
  DELETE_SITE_FAIL,
  RESET_SITE_ERROR,
  RESET_SITE_MESSAGE
} from "./actionTypes"

export const getSites = queries => ({
  type: GET_SITES,
  payload: queries
})

export const getSitesSuccess = sites => ({
  type: GET_SITES_SUCCESS,
  payload: sites
})

export const getSitesFail = error => ({
  type: GET_SITES_FAIL,
  payload: error
})

export const getSiteData = id => ({
  type: GET_SITE_DATA,
  payload: id
})

export const getSiteDataSuccess = site => ({
  type: GET_SITE_DATA_SUCCESS,
  payload: site
})

export const getSiteDataFail = error => ({
  type: GET_SITE_DATA_FAIL,
  payload: error
})

export const addNewSite = site => ({
  type: ADD_NEW_SITE,
  payload: site
})

export const addSiteSuccess = message => ({
  type: ADD_SITE_SUCCESS,
  payload: message
})

export const addSiteFail = error => ({
  type: ADD_SITE_FAIL,
  payload: error
})

export const updateSite = site => ({
  type: UPDATE_SITE,
  payload: site
})

export const updateSiteSuccess = message => ({
  type: UPDATE_SITE_SUCCESS,
  payload: message
})

export const updateSiteFail = error => ({
  type: UPDATE_SITE_FAIL,
  payload: error
})

export const deleteSite = site => ({
  type: DELETE_SITE,
  payload: site
})

export const deleteSiteSuccess = message => ({
  type: DELETE_SITE_SUCCESS,
  payload: message
})

export const deleteSiteFail = error => ({
  type: DELETE_SITE_FAIL,
  payload: error
})

export const resetSiteError = () => ({
  type: RESET_SITE_ERROR
})

export const resetSiteMessage = () => ({
  type: RESET_SITE_MESSAGE
})