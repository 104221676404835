import React, { Component } from "react"
import PropTypes from "prop-types"
import { DateTime } from "luxon"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Spinner } from "reactstrap"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import DeleteModal from "components/Common/DeleteModal"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { getPillars, deletePillar, resetPillarError } from '../../store/actions'
import  { OrientationFieldOptions } from "./../../constants/common"
import AlertModal from "../../components/Common/AlertModal"

const { ExportCSVButton } = CSVExport
const { SearchBar } = Search

class PillarList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: null,
      deleteModal: false
    }
  }

  onClickDelete = pillar => {
    this.setState({ selected: pillar })
    this.setState({ deleteModal: true })
  }

  componentDidMount () {
    const { onGetPillars } = this.props

    onGetPillars()
  }

  render() {
    const { deleteModal } = this.state
    const { pillars, history, deletePillar, message, onGetPillars, error, resetPillarError, loading } = this.props
    const columns = [
      {
        dataField: 'name',
        text: 'Pillar Name',
        sort: true,
        csvExport: true,
        formatter: (cellContent, pillar) => <Link to={"/pillars/view/" + pillar.id}>{pillar.name}</Link>
      },
      {
        dataField: 'ooh_type',
        text: 'OOH Type',
        sort: true,
        csvExport: true,
        formatter: (cellContent, pillar) => (
          <div>{pillar.ooh_type === "static" ? <span className="font-size-12 badge bg-info rounded-pill">Static</span> : <span className="font-size-12 badge bg-success rounded-pill">Led</span>}</div>
        ),
        csvFormatter: (cellContent, pillar) => (pillar.ooh_type === "static" ? "Static" : "Led")
      },
      {
        dataField: 'width',
        text: 'Size',
        csvText: 'Width',
        sort: true,
        csvExport: true,
        formatter: (cellContent, pillar) => {
          if (pillar.width && pillar.height) {
            return <span>{pillar.width} x {pillar.height}</span>
          }

          return <span />
        }
      },
      {
        dataField: 'height',
        text: 'Height',
        csvExport: true,
        hidden: true
      },
      {
        dataField: 'orientation',
        text: 'Orientation',
        sort: true,
        csvExport: true,
        formatter: (cellContent, pillar) => {
          let sel = OrientationFieldOptions.find(item => item.value === pillar.orientation)

          return sel ? sel.label : pillar.orientation
        },
        csvFormatter: (cellContent, pillar) => {
          let sel = OrientationFieldOptions.find(item => item.value === pillar.orientation)

          return sel ? sel.label : pillar.orientation
        }
      },
      {
        dataField: 'city_id',
        text: 'City',
        sort: true,
        csvExport: true,
        formatter: (cellContent, pillar) => pillar.City && pillar.City.name ? pillar.City.name : "",
        csvFormatter: (cellContent, pillar) => pillar.City && pillar.City.name ? pillar.City.name : ""
      },
      {
        dataField: 'district_id',
        text: 'District',
        sort: true,
        csvExport: true,
        formatter: (cellContent, pillar) => pillar.District && pillar.District.name ? pillar.District.name : "",
        csvFormatter: (cellContent, pillar) => pillar.District && pillar.District.name ? pillar.District.name : ""
      },
      {
        dataField: 'updated',
        text: 'Last Update',
        sort: true,
        csvExport: true,
        formatter: (cellContent, pillar) => DateTime.fromSeconds(pillar.updated).toFormat("dd MMM y, HH:mm:ss"),
        csvFormatter: (cellContent, pillar) => DateTime.fromSeconds(pillar.updated).toFormat("dd MMM y, HH:mm:ss")
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        csvExport: false,
        formatter: (cellContent, pillar) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="badge badge-soft-danger" title="Delete">
                <i className="bx bx-trash font-size-18" onClick={() => this.onClickDelete(pillar)}/>
              </Link>
              <Link to={"/pillars/edit/" + pillar.id} className="badge badge-soft-secondary" title="Edit">
                <i className="bx bx-edit font-size-18"/>
              </Link>
              <Link to={"/pillars/view/" + pillar.id} className="badge badge-soft-primary" title="View">
                <i className="mdi mdi-eye-outline font-size-18"/>
              </Link>
            </div>
          </>
        ),
      }
    ]

    this.onClickDelete = this.onClickDelete.bind(this)

    const pageOptions = { sizePerPage: 10, totalSize: pillars.length, custom: true }

    let alertShow = false
    let alertTitle, alertMessage, alertType, alertCallback

    if (message && message.length) {
      alertShow = true
      alertTitle = 'Success'
      alertMessage = message
      alertType = 'success'
      alertCallback = () => onGetPillars()
    }

    if (error && error.length) {
      alertShow = true
      alertTitle = 'Error'
      alertMessage = error
      alertType = 'error'
      alertCallback = () => resetPillarError()
    }

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {

            this.setState({ deleteModal: false })

            if (this.state.selected) {
              return deletePillar(this.state.selected, history)
            }
          }}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <AlertModal show={alertShow} onCloseClick={alertCallback} title={alertTitle} message={alertMessage} type={alertType} />
          <MetaTags>
            <title>Pillar List | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Pillars" breadcrumbItem="List" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={columns} data={pillars}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider keyField='id' columns={columns} data={pillars} search>
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar{...toolkitProps.searchProps}/>
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="text-sm-end">
                                    <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-secondary mr-10">Export to CSV</ExportCSVButton>
                                    <Link to="/pillars/create" className="font-16 btn-block btn btn-primary">Create New Pillar</Link>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="id"
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      hover={true}
                                      classes="table align-middle table-nowrap"
                                      headerWrapperClasses="thead-light"
                                      noDataIndication={loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone{...paginationProps}/>
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone{...paginationProps}/>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

PillarList.propTypes = {
  pillars: PropTypes.array,
  message: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onGetPillars: PropTypes.func,
  deletePillar: PropTypes.func,
  resetPillarError: PropTypes.func,
  history: PropTypes.object
}

const mapStateToProps = ({ pillars }) => ({
  pillars: pillars.pillars && pillars.pillars.data ? pillars.pillars.data : [],
  message: pillars.message,
  error: pillars.error,
  loading: pillars.loading
})

const mapDispatchToProps = dispatch => ({
  onGetPillars: () => dispatch(getPillars()),
  deletePillar: (pillar, history) => dispatch(deletePillar(pillar, history)),
  resetPillarError: () => dispatch(resetPillarError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PillarList))