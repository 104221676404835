import PropTypes from "prop-types"
import React, { Component } from "react"
import SimpleBar from "simplebar-react"
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

class SidebarContent extends Component {
  constructor(props) {
    super(props)

    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null

    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")

    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname.startsWith(items[i].pathname)) {
        matchingMenuItem = items[i]
        break
      }

      let submenu = items[i].attributes.getNamedItem("submenu")

      submenu = submenu ? submenu.value : ''

      if (submenu && submenu.length) {
        let submenuValue = submenu.split(",")

        for (let j = 0; j < submenuValue.length; j++) {
          if (this.props.location.pathname.startsWith("/" + submenuValue[j])) {
            matchingMenuItem = items[i]
            break
          }
        }
      }
    }

    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current) {
              this.refDiv.current.getScrollElement().scrollTop = currentPosition - 300
            }
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")

    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    const { profile } = this.props

    return (
        <React.Fragment>
          <SimpleBar className="h-100" ref={this.refDiv}>
            <div id="sidebar-menu">
              <ul className="metismenu list-unstyled" id="side-menu">
                <li className="menu-title">{this.props.t("Menu")}</li>
                <li>
                  <Link to="/dashboard">
                    <i className="bx bx-home-alt" />
                    <span>{this.props.t("Overview")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/pillars">
                    <i className="bx bx-map-pin" />
                    <span>{this.props.t("Pillars")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/sites">
                    <i className="bx bx-carousel" />
                    <span>{this.props.t("Sites")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/booking">
                    <i className="bx bx-calendar" />
                    <span>{this.props.t("Booking")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/scheduling-list" className="hide hidden">
                    <i className="bx bx-timer" />
                    <span>{this.props.t("Scheduling")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/mlp-menu">
                    <i className="bx bx-receipt" />
                    <span>{this.props.t("Media Log Proof")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/mlp-campaign-list" submenu={["mlp-campaign-detail"]}>{this.props.t("Campaign")}</Link>
                    </li>
                    <li>
                      <Link to="/mlp-led-list" submenu={["mlp-led-detail"]}>{this.props.t("Led")}</Link>
                    </li>
                    <li>
                      <Link to="/mlp-led-summary" submenu={[]}>{this.props.t("Led Summary")}</Link>
                    </li>
                    <li>
                      <Link to="/mlp-static-list" submenu={["mlp-static-create", "mlp-static-detail"]}>{this.props.t("Static")}</Link>
                    </li>
                    <li>
                      <Link to="/mlp-raw-list" submenu={["mlp-raw-detail"]}>{this.props.t("Raw")}</Link>
                    </li>
                    <li>
                      <Link to="/mlp-mapping-list" submenu={["mlp-mapping-detail"]}>{this.props.t("Mapping")}</Link>
                    </li>
                  </ul>
                </li>
                <li className="menu-title">{this.props.t("Settings")}</li>
                {
                  profile && profile.role == 'SUPER' &&
                  <li>
                    <Link to="/users">
                      <i className="bx bx-user-circle" />
                      <span>{this.props.t("User Management")}</span>
                    </Link>
                  </li>
                }
                {
                    profile && profile.role == 'SUPER' &&
                    <li>
                      <Link to="/vnnox_config">
                        <i className="bx bx-list-plus" />
                        <span>{this.props.t("VNNOX Config")}</span>
                      </Link>
                    </li>
                }
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  profile: PropTypes.object,
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string
}

const mapStateToProps = ({ Profile }) => ({
  profile: Profile.profile
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SidebarContent)))