import { takeEvery, put, call, takeLatest } from "redux-saga/effects"
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginSuccess, apiError } from "./actions"
import { login, logout } from "./../../../helpers/api_v1_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(login, {
      username: user.username,
      password: user.password,
    })

    localStorage.setItem("accessToken", response.access_token)

    yield put(loginSuccess(response))

    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    yield call(logout)
  } catch (error) {
    yield put(apiError(error))
  }

  localStorage.removeItem("accessToken")

  history.push("/login")
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga