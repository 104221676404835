import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Container, Row, Table, } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import profile1 from "assets/images/profile-img.png"
import { getUserData } from "store/actions"
import { UserRoleFieldOptions } from "constants/common"

class UsersProfile extends Component {
  componentDidMount () {
    const { getUserData, match = {} } = this.props
    const { params = {} } = match
    const { id } = params

    if (id) {
      getUserData(id)
    }
  }

  getRole (user) {
    let role = UserRoleFieldOptions.find(item => item.value === user.role)

    return role ? role.label : user.role
  }

  render() {
    const { user } = this.props

    return (
      user && <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Profile | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="Users" breadcrumbItem="Profile" />
            <Row>
              <Col xl="4" md="6">
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-3">
                          <h5 className="text-primary">Welcome Back!</h5>
                        </div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        <img src={profile1} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm={12}>
                        <div className="avatar-md profile-user-wid mb-4">
                          <div className="me-3">
                            <div className="avatar-md display-inherit">
                              <span className="avatar-title rounded-circle">
                                {user.name.charAt(0)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={12}>
                        <div className="table-responsive">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">Full Name</th>
                                <td>: {user.name}</td>
                              </tr>
                              <tr>
                                <th scope="row">E-mail</th>
                                <td>: {user.email}</td>
                              </tr>
                              <tr>
                                <th scope="row">Role</th>
                                <td>: {this.getRole(user)}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="8" md="6" className="hide">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 h4">Edit Personal Information</CardTitle>
                    <p>Form edit here</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

UsersProfile.propTypes = {
  userProfile: PropTypes.any,
  getUserData: PropTypes.func,
  user: PropTypes.object,
  match: PropTypes.object
}

const mapStateToProps = ({ users }) => ({
  user: users && users.userData && users.userData.data ? users.userData.data : null
})

const mapDispatchToProps = dispatch => ({
  getUserData: id => dispatch(getUserData(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UsersProfile))
