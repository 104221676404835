export const GET_PILLARS = "GET_PILLARS"
export const GET_PILLARS_SUCCESS = "GET_PILLARS_SUCCESS"
export const GET_PILLARS_FAIL = "GET_PILLARS_FAIL"
export const GET_UNASSIGNED_PILLARS = "GET_UNASSIGNED_PILLARS"
export const GET_UNASSIGNED_PILLARS_SUCCESS = "GET_UNASSIGNED_PILLARS_SUCCESS"
export const GET_UNASSIGNED_PILLARS_FAIL = "GET_UNASSIGNED_PILLARS_FAIL"
export const GET_PILLAR_DATA = "GET_PILLAR_DATA"
export const GET_PILLAR_DATA_SUCCESS = "GET_PILLAR_DATA_SUCCESS"
export const GET_PILLAR_DATA_FAIL = "GET_PILLAR_DATA_FAIL"
export const ADD_NEW_PILLAR = "ADD_NEW_PILLAR"
export const ADD_PILLAR_SUCCESS = "ADD_PILLAR_SUCCESS"
export const ADD_PILLAR_FAIL = "ADD_PILLAR_FAIL"
export const UPDATE_PILLAR = "UPDATE_PILLAR"
export const UPDATE_PILLAR_SUCCESS = "UPDATE_PILLAR_SUCCESS"
export const UPDATE_PILLAR_FAIL = "UPDATE_PILLAR_FAIL"
export const DELETE_PILLAR = "DELETE_PILLAR"
export const DELETE_PILLAR_SUCCESS = "DELETE_PILLAR_SUCCESS"
export const DELETE_PILLAR_FAIL = "DELETE_PILLAR_FAIL"
export const RESET_PILLAR_ERROR = "RESET_PILLAR_ERROR"
export const RESET_PILLAR_MESSAGE = "RESET_PILLAR_MESSAGE"