import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { DateTime } from "luxon"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Row, InputGroup, FormGroup, Button, Spinner } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { getCampaigns } from '../../store/actions'

const { ExportCSVButton } = CSVExport

class MlpCampaignList extends Component {
  constructor (props) {
    super(props)

    this.flatPicker = React.createRef()

    this.onDateRangeChange = this.onDateRangeChange.bind(this)
  }

  componentDidMount () {
    const { getCampaigns } = this.props

    getCampaigns()
  }

  onDateRangeChange (data) {
    const { getCampaigns } = this.props
    const [from = false, to = false] = data
    const params = {}

    if (from) {
      params.from = DateTime.fromJSDate(from).toFormat('y-MM-dd')
    }

    if (to) {
      params.to = DateTime.fromJSDate(to).toFormat('y-MM-dd')
    }

    getCampaigns(params)
  }

  render () {
    const { campaigns, loading } = this.props
    const columns = [
      {
        dataField: 'id',
        text: 'Campaign ID',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'name',
        text: 'Campaign Name',
        sort: true,
        csvExport: true,
        formatter: (cellContent, campaign) => <Link to={"mlp-campaign-detail/" + campaign.id}>{campaign.name}</Link>,
      },
      {
        dataField: 'brand',
        text: 'Brand Name',
        sort: true,
        csvExport: true,
      },
      {
        dataField: 'category',
        text: 'Category',
        sort: true,
        csvExport: true,
        formatter: (cellContent, campaign) => (
          <div>
            {
              campaign.category.toLowerCase() === 'advertiser' ? (
                <span className="font-size-12 badge bg-info rounded-pill">Advertiser</span>
              ) : (
                <span className="font-size-12 badge bg-success rounded-pill">Promotional</span>
              )
            }
          </div>
        ),
        csvFormatter: (cellContent, campaign) => campaign.category.toLowerCase() === 'advertiser' ? "Advertiser" : "Promotional"
      },
      {
        dataField: 'period_start',
        text: 'Period Start',
        sort: true,
        csvExport: true,
        formatter: (cellContent, campaign) => campaign.period_start ? DateTime.fromFormat(campaign.period_start,'y-MM-dd').toFormat('dd MMM y') : '',
        csvFormatter: (cellContent, campaign) => campaign.period_start ? DateTime.fromFormat(campaign.period_start,'y-MM-dd').toFormat('dd MMM y') : ''
      },
      {
        dataField: 'period_end',
        text: 'Period End',
        sort: true,
        csvExport: true,
        formatter: (cellContent, campaign) => campaign.period_end ? DateTime.fromFormat(campaign.period_end,'y-MM-dd').toFormat('dd MMM y') : '',
        csvFormatter: (cellContent, campaign) => campaign.period_end ? DateTime.fromFormat(campaign.period_end,'y-MM-dd').toFormat('dd MMM y') : ''
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        formatter: (cellContent, campaign) => (
          <>
            <div className="d-flex gap-3">
              <Link to={"mlp-campaign-detail/" + campaign.id} className="badge badge-soft-primary" title="View"><i className="mdi mdi-eye-outline font-size-18" /></Link>
            </div>
          </>
        ),
      }
    ]

    const pageOptions = {sizePerPage: 10, totalSize: campaigns.length, custom: true}
    const { SearchBar } = Search

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Media Log Proof Campaign | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Media Log Proof" breadcrumbItem="Campaign List" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={columns} data={campaigns}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider keyField='id' columns={columns} data={campaigns} search>
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar{...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="text-sm-end">
                                    <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-secondary mr-5 hide">Export to CSV</ExportCSVButton>
                                    <Button className="mr-5 btn-dark" onClick={() => this.flatPicker.current.flatpickr.clear()}>Reset</Button>
                                    <FormGroup className="btn-datepicker">
                                      <InputGroup className="">
                                        <Flatpickr className="form-control btn btn-outline-primary" placeholder="Select Date" options={{ mode: "range", dateFormat: "Y-m-d" }} onChange={this.onDateRangeChange} ref={this.flatPicker} />
                                      </InputGroup>
                                    </FormGroup>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="id"
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      hover={true}
                                      classes="table align-middle table-nowrap"
                                      noDataIndication={ loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                      headerWrapperClasses="thead-light"
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone{...paginationProps} />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone{...paginationProps} />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

MlpCampaignList.propTypes = {
  campaigns: PropTypes.array,
  message: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  getCampaigns: PropTypes.func,
  history: PropTypes.object
}

const mapStateToProps = ({ campaigns }) => ({
  campaigns: campaigns.campaigns && campaigns.campaigns.data ? campaigns.campaigns.data : [],
  message: campaigns.message,
  error: campaigns.error,
  loading: campaigns.loading
})

const mapDispatchToProps = dispatch => ({
  getCampaigns: queries => dispatch(getCampaigns(queries))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpCampaignList))