import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"
import logoNord from "../../assets/images/component/nord-logo.png"
import logoNordLight from "../../assets/images/component/nord-logo-light.png"
import iconNord from "../../assets/images/component/nord-icon.png"
import iconNordLight from "../../assets/images/component/nord-icon-light.png"

class Sidebar extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div className="navbar-brand-box">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <img src={iconNord} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoNord} alt="" height="32" />
              </span>
            </Link>
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={iconNordLight} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoNordLight} alt="" height="32" />
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100">
            <SidebarContent />
          </div>
          <div className="sidebar-background"></div>
        </div>
      </React.Fragment>
    )
  }
}

Sidebar.propTypes = {
  type: PropTypes.string
}

const mapStateToProps = state => ({ layout: state.Layout })

export default connect(mapStateToProps, {})(withRouter(withTranslation()(Sidebar)));
