export const GET_PLAYERS = "GET_PLAYERS"
export const GET_PLAYERS_SUCCESS = "GET_PLAYERS_SUCCESS"
export const GET_PLAYERS_FAIL = "GET_PLAYERS_FAIL"
export const GET_PLAYER_DATA = "GET_PLAYER_DATA"
export const GET_PLAYER_DATA_SUCCESS = "GET_PLAYER_DATA_SUCCESS"
export const GET_PLAYER_DATA_FAIL = "GET_PLAYER_DATA_FAIL"
export const SET_PLAYER_MAP = "SET_PLAYER_MAP"
export const SET_PLAYER_MAP_SUCCESS = "SET_PLAYER_MAP_SUCCESS"
export const SET_PLAYER_MAP_FAIL = "SET_PLAYER_MAP_FAIL"
export const CREATE_PLAYER_MAP = "CREATE_PLAYER_MAP"
export const CREATE_PLAYER_MAP_SUCCESS = "CREATE_PLAYER_MAP_SUCCESS"
export const CREATE_PLAYER_MAP_FAIL = "CREATE_PLAYER_MAP_FAIL"