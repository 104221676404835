import {
  GET_ACCOUNT_DATA,
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ACCOUNT_DATA_FAIL,
  GET_ACCOUNTS,
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNTS_SUCCESS,
  ADD_NEW_ACCOUNT,
  ADD_NEW_ACCOUNT_SUCCESS,
  ADD_NEW_ACCOUNT_FAIL,
  UPDATE_ACCOUNT,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAIL,
  DELETE_ACCOUNT,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL,
  RESET_ACCOUNT_STATE
} from "./actionTypes"

const INIT_STATE = {
  accounts: [],
  accountData: null,
  error: null,
  message: null,
  loading: false
}

const accounts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ACCOUNTS:
      return { ...state, accounts: [], error: null, message: null, loading: true }
    case GET_ACCOUNTS_SUCCESS:
      return { ...state, accounts: action.payload, error: null, message: null, loading: false }
    case GET_ACCOUNTS_FAIL:
      return { ...state, accounts: [], error: action.payload, message: null, loading: false }
    case GET_ACCOUNT_DATA:
      return { ...state, accountData: null, error: null, message: null, loading: true }
    case GET_ACCOUNT_DATA_SUCCESS:
      return { ...state, accountData: action.payload, error: null, message: null, loading: false }
    case GET_ACCOUNT_DATA_FAIL:
      return { ...state, accountData: null, error: action.payload, message: null, loading: false }
    case ADD_NEW_ACCOUNT:
      return { ...state, error: null, message: null, loading: true }
    case ADD_NEW_ACCOUNT_SUCCESS:
      return { ...state, error: null, message: action.payload, loading: false }
    case ADD_NEW_ACCOUNT_FAIL:
      return { ...state, error: action.payload, message: null, loading: false }
    case UPDATE_ACCOUNT:
      return { ...state, error: null, message: null, loading: true }
    case UPDATE_ACCOUNT_SUCCESS:
      return { ...state, message: action.payload, error: null, loading: false }
    case UPDATE_ACCOUNT_FAIL:
      return { ...state, error: action.payload, message: null, loading: false }
     case DELETE_ACCOUNT:
      return { ...state, message: null, error: null, loading: true }
    case DELETE_ACCOUNT_SUCCESS:
      return { ...state, error: null, message: action.payload, loading: false }
    case DELETE_ACCOUNT_FAIL:
      return { ...state, error: action.payload, message: null, loading: false }
    case RESET_ACCOUNT_STATE:
      return { ...state, error: null, message: null, loading: false }
    default:return state
  }
}

export default accounts
