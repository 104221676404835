import { call, put, takeEvery } from "redux-saga/effects"
import { GET_BOOKINGS, GET_BOOKING_DATA, ADD_NEW_BOOKING, DELETE_BOOKING, UPDATE_BOOKING, DELETE_BOOKING_SUCCESS } from "./actionTypes"
import {
  getBookingsSuccess,
  getBookingsFail,
  getBookingDataSuccess,
  getBookingDataFail,
  addBookingFail,
  addBookingSuccess,
  updateBookingSuccess,
  updateBookingFail,
  deleteBookingSuccess,
  deleteBookingFail,
} from "./actions"
import { getBookings, getBookingData, addNewBooking, updateBooking, deleteBooking } from "../../helpers/api_v1_helper"

function* fetchBookings({ payload: queries }) {
  try {
    const response = yield call(getBookings, queries)

    yield put(getBookingsSuccess(response))
  } catch (error) {
    yield put(getBookingsFail(error))
  }
}

function* fetchBookingData({ payload: id }) {
  try {
    const response = yield call(getBookingData, id)

    yield put(getBookingDataSuccess(response))
  } catch (error) {
    yield put(getBookingDataFail(error))
  }
}

function* onAddNewBooking({ payload: booking }) {
  try {
    yield call(addNewBooking, booking)
    yield put(addBookingSuccess("Booking added successfully"))
  } catch (error) {
    yield put(addBookingFail(error))
  }
}

function* onUpdateBooking({ payload: booking }) {
  try {
    yield call(updateBooking, booking)
    yield put(updateBookingSuccess("Booking updated successfully"))
  } catch (error) {
    yield put(updateBookingFail(error))
  }
}

function* onDeleteBooking({ payload: booking }) {
  try {
    yield call(deleteBooking, booking)
    yield put(deleteBookingSuccess("Booking delete successfully"))
  } catch (error) {
    yield put(deleteBookingFail(error))
  }
}

function* bookingsSaga() {
  yield takeEvery(GET_BOOKINGS, fetchBookings)
  yield takeEvery(GET_BOOKING_DATA, fetchBookingData)
  yield takeEvery(ADD_NEW_BOOKING, onAddNewBooking)
  yield takeEvery(UPDATE_BOOKING, onUpdateBooking)
  yield takeEvery(DELETE_BOOKING, onDeleteBooking)
}

export default bookingsSaga
