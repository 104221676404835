import React, { Component } from "react"
import PropTypes from "prop-types"
import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import { apiError, loginUser } from "../../store/actions"
import profile from "../../assets/images/profile-img.png"
import nordfooter from "../../assets/images/component/nord-footer.png"
import nordicon from "../../assets/images/component/nord-icon-light.png"

class Login extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showPassword: false
    }
  }

  componentDidMount() {
    this.props.apiError("")
  }

  render() {
    return (
        <React.Fragment>
          <div className="home-btn d-none d-sm-block hide">
            <Link to="/" className="text-dark"><i className="bx bx-home h2" /></Link>
          </div>
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="overflow-hidden">
                    <div className="bg-primary bg-soft">
                      <Row>
                        <Col className="col-7">
                          <div className="text-primary p-4">
                            <h5 className="text-primary">Welcome Back !</h5>
                            <p>Sign in to continue to Dashboard.</p>
                          </div>
                        </Col>
                        <Col className="col-5 align-self-end">
                          <img src={profile} alt="" className="img-fluid" />
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-0">
                      <div className="auth-logo">
                        <Link to="/" className="auth-logo-light">
                          <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={nordicon} alt="" className="rounded-circle" height="34" />
                          </span>
                          </div>
                        </Link>
                        <Link to="/" className="auth-logo-dark">
                          <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={nordicon} alt="" className="rounded-circle" height="34" />
                          </span>
                          </div>
                        </Link>
                      </div>
                      <div className="p-2">
                        {this.props.error && this.props.error ? (<Alert color="danger">{this.props.error}</Alert>) : null}
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                              username: (this.state && this.state.username) || "",
                              password: (this.state && this.state.password) || "",
                            }}
                            validationSchema={Yup.object().shape({
                              username: Yup.string().required("Please Enter Your Username"),
                              password: Yup.string().required("Please Enter Valid Password"),
                            })}
                            onSubmit={values => {
                              this.props.loginUser(values, this.props.history)
                            }}
                        >
                          {({ errors, status, touched }) => (
                              <Form className="form-horizontal">
                                <div className="mb-3">
                                  <Label for="username" className="form-label">Username</Label>
                                  <Field
                                      name="username"
                                      type="text"
                                      className={"form-control" + (errors.username && touched.username ? " is-invalid" : "")}
                                  />
                                  <ErrorMessage name="username" component="div" className="invalid-feedback" />
                                </div>
                                <div className="mb-3">
                                  <Label for="password" className="form-label">Password</Label>
                                  <div className="input-group auth-pass-inputgroup">
                                    <Field
                                        name="password"
                                        type={this.state.showPassword ? "text" : "password"}
                                        autoComplete="true"
                                        className={"form-control" + (errors.password && touched.password ? " is-invalid" : "")}
                                    />
                                    <button
                                        className="btn btn-light"
                                        type="button"
                                        id="password-addon"
                                        onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                    >
                                      <i className={"mdi " + (this.state.showPassword ? "mdi-eye-off-outline" : "mdi-eye-outline") }></i>
                                    </button>
                                  </div>
                                  <ErrorMessage name="password" component="div" className="invalid-feedback"
                                  />
                                </div>
                                <div className="form-check">
                                  <input type="checkbox" className="form-check-input" id="customControlInline" />
                                  <label className="form-check-label" htmlFor="customControlInline">Remember me</label>
                                </div>
                                <div className="mt-3 d-grid">
                                  <button className="btn btn-primary btn-block" type="submit">Log In</button>
                                </div>
                                <div className="mt-4 text-center">
                                  <Link to="/forgot_password" className="text-muted"><i className="mdi mdi-lock me-1" /> Forgot your password?</Link>
                                </div>
                              </Form>
                          )}
                        </Formik>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-5 text-center">
                    <p>
                      © {new Date().getFullYear()}
                      <img src={nordfooter} alt="" className="img-fluid img-nf" />
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
    connect(mapStateToProps, { loginUser, apiError })(Login)
)
