import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import CustomDownloadButton from "../../components/Common/CustomDownloadButton";

class CardBooking extends Component {
  render() {
    const { site } = this.props

    return (
      !site ? <React.Fragment /> :
      <React.Fragment>
        <Col md={12}>
          <Card outline color="dark" className="border border-dark">
            <CardHeader className="bg-transparent border-dark">
              <h5 className="my-0 text-dark">
                <i className="bx bx-map-pin me-3"></i>
                {site.Site.name}
              </h5>
            </CardHeader>
            <CardBody>
              <Table striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <th width="200">Total Spot / Site</th>
                    <td>{site.total_spot}</td>
                  </tr>
                  <tr>
                    <th width="200">Distribution Period</th>
                    <td>{site.distribution_start} - {site.distribution_end}</td>
                  </tr>
                  <tr>
                    <th width="200">Material Name</th>
                    <td>{site.material_name}</td>
                  </tr>
                  <tr>
                    <th width="200">File Material</th>
                    <td>
                      {
                        site.material_files && site.material_files.length && site.material_files.map((file, index) => (
                              <CustomDownloadButton to={file.name} className="btn btn-outline-dark btn-sm text-right mb-5 mr-5" key={file.name} mimetype={file.mimetype}>
                                <i className="bx bx-download font-size-16 align-middle me-2"></i>
                                download
                              </CustomDownloadButton>
                        ))
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

CardBooking.propTypes = {
  site: PropTypes.object
}

export default CardBooking
