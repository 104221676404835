import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { DateTime } from "luxon"
import { withRouter, Link } from "react-router-dom"
import {Card, CardBody, Col, Row, Spinner} from "reactstrap"
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit"
import DeleteModal from "components/Common/DeleteModal"
import AlertModal from "components/Common/AlertModal"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import { getSites, deleteSite, resetSiteError } from "../../store/sites/actions"
import  { OrientationFieldOptions } from "./../../constants/common"

const { ExportCSVButton } = CSVExport
class SiteList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      page: 1,
      sizePerPage: 10,
      siteData: []
    }
  }

  onClickDelete = site => {
    this.setState({ selected: site })
    this.setState({ deleteModal: true })
  }

  componentDidMount () {
    const { onGetSites } = this.props

    onGetSites()
  }

  render() {
    const { deleteModal } = this.state
    const { sites, deleteSite, message, error, resetSiteError, loading, onGetSites } = this.props
    const columns = [
      {
        dataField: 'name',
        text: 'Site Name',
        sort: true,
        csvExport: true,
        formatter: (cellContent, site) => <Link to={"/sites/view/" + site.id}>{site.name}</Link>
      },
      {
        dataField: 'orientation',
        text: 'Orientation',
        sort: true,
        csvExport: true,
        formatter: (cellContent, site) => {
          let sel = OrientationFieldOptions.find(item => item.value === site.orientation)

          return sel ? sel.label : site.orientation
        },
        csvFormatter: (cellContent, site) => {
          let sel = OrientationFieldOptions.find(item => item.value === site.orientation)

          return sel ? sel.label : site.orientation
        }
      },
      // {
      //   dataField: 'Province',
      //   text: 'Province',
      //   sort: true,
      //   csvExport: true,
      //   formatter: (cellContent, site) => site.Province ? site.Province.name : "",
      //   csvFormatter: (cellContent, site) => site.Province ? site.Province.name : ""
      // },
      {
        dataField: 'City',
        text: 'City',
        sort: true,
        csvExport: true,
        formatter: (cellContent, site) => site.City ? site.City.name : "",
        csvFormatter: (cellContent, site) => site.City ? site.City.name : ""
      },
      {
        dataField: 'District',
        text: 'District',
        sort: true,
        csvExport: true,
        formatter: (cellContent, site) => site.District ? site.District.name : "",
        csvFormatter: (cellContent, site) => site.District ? site.District.name : ""
      },
      {
        dataField: 'updated',
        text: 'Last Update',
        sort: true,
        csvExport: true,
        formatter: (cellContent, site) => DateTime.fromSeconds(site.updated).toFormat("dd MMM y, HH:mm:ss"),
        csvFormatter: (cellContent, site) => DateTime.fromSeconds(site.updated).toFormat("dd MMM y, HH:mm:ss")
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        csvExport: false,
        formatter: (cellContent, site) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="badge badge-soft-danger" title="Delete">
                <i className="bx bx-trash font-size-18" onClick={() => this.onClickDelete(site)} />
              </Link>
              <Link to={"/sites/edit/" + site.id} className="badge badge-soft-secondary" title="Edit">
                <i className="bx bx-edit font-size-18" />
              </Link>
              <Link to={"/sites/view/" + site.id} className="badge badge-soft-primary" title="View">
                <i className="mdi mdi-eye-outline font-size-18" />
              </Link>
            </div>
          </>
        ),
      }
    ]

    this.onClickDelete = this.onClickDelete.bind(this)

    const pageOptions = {sizePerPage: 10, totalSize: sites.length, custom: true}
    const { SearchBar } = Search

    let alertShow = false
    let alertTitle, alertMessage, alertType, alertCallback

    if (message && message.length) {
      alertShow = true
      alertTitle = 'Success'
      alertMessage = message
      alertType = 'success'
      alertCallback = () => onGetSites()
    }

    if (error && error.length) {
      alertShow = true
      alertTitle = 'Error'
      alertMessage = error
      alertType = 'error'
      alertCallback = () => resetSiteError()
    }

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => {
            this.setState({ deleteModal: false })

            if (this.state.selected) {
              return deleteSite(this.state.selected)
            }
          }}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <AlertModal show={alertShow} onCloseClick={alertCallback} title={alertTitle} message={alertMessage} type={alertType} />
        <div className="page-content">
          <MetaTags>
            <title>Site List | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Sites" breadcrumbItem="Site List" />
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider pagination={paginationFactory(pageOptions)} keyField='id' columns={columns} data={sites}>
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider keyField='id' columns={columns} data={sites} search>
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="6">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar{...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="text-sm-end">
                                    <ExportCSVButton {...toolkitProps.csvProps} className="btn btn-secondary mr-10">Export to CSV</ExportCSVButton>
                                    <Link to="/sites/create" className="font-16 btn-block btn btn-primary">Create New Site</Link>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      hover={true}
                                      classes={"table align-middle table-nowrap"}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      noDataIndication={loading ? <><Spinner size="sm" as="span" /> Loading...</> : "No result found"}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone{...paginationProps} />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone{...paginationProps} />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

SiteList.propTypes = {
  sites: PropTypes.array,
  message: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onGetSites: PropTypes.func,
  deleteSite: PropTypes.func,
  resetSiteError: PropTypes.func,
}

const mapStateToProps = ({ sites }) => ({
  sites: sites.sites && sites.sites.data ? sites.sites.data : [],
  message: sites.message,
  error: sites.error,
  loading: sites.loading
})

const mapDispatchToProps = dispatch => ({
  onGetSites: () => dispatch(getSites()),
  deleteSite: site => dispatch(deleteSite(site)),
  resetSiteError: () => dispatch(resetSiteError()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SiteList))
