import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RECOVER_ACCOUNT,
  RECOVER_ACCOUNT_SUCCESS,
  RECOVER_ACCOUNT_ERROR
} from "./actionTypes"

export const userForgetPassword = data => {
  return {
    type: FORGET_PASSWORD,
    payload: data
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message
  }
}

export const userRecoverAccount = (data, history) => {
  return {
    type: RECOVER_ACCOUNT,
    payload: { data, history }
  }
}

export const userRecoverAccountSuccess = message => {
  return {
    type: RECOVER_ACCOUNT_SUCCESS,
    payload: message
  }
}

export const userRecoverAccountError = message => {
  return {
    type: RECOVER_ACCOUNT_ERROR,
    payload: message
  }
}
