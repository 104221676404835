export const GET_PROVINCES = "GET_PROVINCES"
export const GET_PROVINCES_FAIL = "GET_PROVINCES_FAIL"
export const GET_PROVINCES_SUCCESS = "GET_PROVINCES_SUCCESS"
export const GET_CITIES = "GET_CITIES"
export const GET_CITIES_FAIL = "GET_CITIES_FAIL"
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS"
export const GET_DISTRICTS = "GET_DISTRICTS"
export const GET_DISTRICTS_FAIL = "GET_DISTRICTS_FAIL"
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS"
export const GET_SUB_INDUSTRY = "GET_SUB_INDUSTRY"
export const GET_SUB_INDUSTRY_FAIL = "GET_SUB_INDUSTRY_FAIL"
export const GET_SUB_INDUSTRY_SUCCESS = "GET_SUB_INDUSTRY_SUCCESS"
export const GET_ADVERTISER = "GET_ADVERTISER"
export const GET_ADVERTISER_FAIL = "GET_ADVERTISER_FAIL"
export const GET_ADVERTISER_SUCCESS = "GET_ADVERTISER_SUCCESS"
export const GET_SALES = "GET_SALES"
export const GET_SALES_FAIL = "GET_SALES_FAIL"
export const GET_SALES_SUCCESS = "GET_SALES_SUCCESS"