import Flatpickr from "react-flatpickr"
import React from "react"
import PropTypes from "prop-types"
import {DateTime} from "luxon"

export const CustomFileInput = ({ field, form, multiple, ...more }) => {
    const onChange = e => {
        let files = e.currentTarget.files
        let name = []

        if (multiple) {
            for (let i = 0; i < files.length; i++) {
                form.setFieldValue(`${field.name}[${i}]`, files[i])

                let [filename, ] = files[i].name.split(".")

                name.push(filename)
            }
        } else {
            form.setFieldValue(field.name, files[0])

            let [filename, ] = files[0].name.split(".")

            name.push(filename)
        }

        if (more.onChanged) {
            more.onChanged(name)
        }
    }

    return (
        <input type="file" onChange={onChange} multiple={multiple} {...more} />
    )
}

CustomFileInput.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    field: PropTypes.object,
    form: PropTypes.object,
    multiple: PropTypes.bool,
    onChanged: PropTypes.func
}

export default CustomFileInput