export const GET_CAMPAIGNS = "GET_CAMPAIGNS"
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS"
export const GET_CAMPAIGNS_FAIL = "GET_CAMPAIGNS_FAIL"
export const GET_CAMPAIGN_DATA = "GET_CAMPAIGN_DATA"
export const GET_CAMPAIGN_DATA_SUCCESS = "GET_CAMPAIGN_DATA_SUCCESS"
export const GET_CAMPAIGN_DATA_FAIL = "GET_CAMPAIGN_DATA_FAIL"
export const GET_CAMPAIGNS_BY_SITE = "GET_CAMPAIGNS_BY_SITE"
export const GET_CAMPAIGNS_BY_SITE_SUCCESS = "GET_CAMPAIGNS_BY_SITE_SUCCESS"
export const GET_CAMPAIGNS_BY_SITE_FAIL = "GET_CAMPAIGNS_BY_SITE_FAIL"
export const ADD_NEW_CAMPAIGN = "ADD_NEW_CAMPAIGN"
export const ADD_CAMPAIGN_SUCCESS = "ADD_CAMPAIGN_SUCCESS"
export const ADD_CAMPAIGN_FAIL = "ADD_CAMPAIGN_FAIL"
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN"
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS"
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL"
export const UPDATE_CAMPAIGN_STATUS = "UPDATE_CAMPAIGN_STATUS"
export const UPDATE_CAMPAIGN_STATUS_SUCCESS = "UPDATE_CAMPAIGN_STATUS_SUCCESS"
export const UPDATE_CAMPAIGN_STATUS_FAIL = "UPDATE_CAMPAIGN_STATUS_FAIL"
export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS"
export const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL"
export const RESET_CAMPAIGN_ERROR = "RESET_CAMPAIGN_ERROR"
export const RESET_CAMPAIGN_MESSAGE = "RESET_CAMPAIGN_MESSAGE"