export const OrientationFieldOptions = [
  { value: "vertical", label: "Vertical" },
  { value: "horizontal", label: "Horizontal" }
]

export const OOHTypeFieldOptions = [
  { value: "static", label: "Static" },
  { value: "led", label: "Led" }
]

export const ShareSlotFieldOptions = [
  { value: "1", label: "Yes" },
  { value: "0", label: "No" }
]

export const LightingDataFieldOptions = [
  { value: "backlit", label: "Backlit" },
  { value: "frontlit", label: "Frontlit" },
  { value: "led", label: "Led" }
]

export const UserRoleFieldOptions = [
  { label: "Super Admin", value: "SUPER" },
  { label: "Admin", value: "ADMIN" },
  { label: "Staff", value: "STAFF" },
  { label: "Sales", value: "SALES" }
]

export const CampaignCategoryFieldOptions = [
  { value:"advertisement", label:"Advertisement" },
  { value:"psa", label:"PSA" }
]

export const CampaignStatusFieldOptions = [
  { value:"running", label:"Running" },
  { value:"cancelled", label:"Cancelled" },
  { value:"completed", label:"Completed" }
]

export const AccountStatusFieldOptions = [
  { value:"0", label:"Disable" },
  { value:"1", label:"Enable" }
]
