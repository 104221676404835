import React, { Component } from "react"
import PropTypes from "prop-types"
import { Col, Modal, ModalBody, Row } from "reactstrap"

class AlertModal extends Component {
  render() {
    const { type } = this.props

    let icoClass = "mdi-alert-circle-outline"
    let icoColor = "orange"

    switch (type) {
      case "error":
        icoColor = "red"
        icoClass = "mdi-close-circle-outline"
        break
      case "info":
        icoColor = "blue"
        icoClass = "mdi-information-outline"
        break
      case "success":
        icoColor = "green"
        icoClass = "mdi-check-circle-outline"
        break
    }

    return (
      this.props.show &&
      <React.Fragment>
        <Modal isOpen={true} toggle={this.props.onCloseClick} centered={true} backdrop="static">
          <ModalBody className="py-3 px-5">
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <i
                    className={"mdi " + icoClass}
                    style={{ fontSize: "9em", color: icoColor }}
                  />
                  <h2>{this.props.title}</h2>
                  <h4>{this.props.message}</h4>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-center mt-30">
                  <button type="button" className="btn btn-outline-dark btn-lg me-2" onClick={() => this.props.onCloseClick()}>Close</button>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

AlertModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.oneOf(["info", "error", "warning", "success"])
}

export default AlertModal
