export const GET_ACCOUNTS = "GET_ACCOUNTS"
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS"
export const GET_ACCOUNTS_FAIL = "GET_ACCOUNTS_FAIL"
export const GET_ACCOUNT_DATA = "GET_ACCOUNT_DATA"
export const GET_ACCOUNT_DATA_SUCCESS = "GET_ACCOUNT_DATA_SUCCESS"
export const GET_ACCOUNT_DATA_FAIL = "GET_ACCOUNT_DATA_FAIL"
export const ADD_NEW_ACCOUNT = "ADD_NEW_ACCOUNT"
export const ADD_NEW_ACCOUNT_SUCCESS = "ADD_NEW_ACCOUNT_SUCCESS"
export const ADD_NEW_ACCOUNT_FAIL = "ADD_NEW_ACCOUNT_FAIL"
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT"
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS"
export const UPDATE_ACCOUNT_FAIL = "UPDATE_ACCOUNT_FAIL"
export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS"
export const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL"
export const RESET_ACCOUNT_STATE = "RESET_ACCOUNT_STATE"