import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Button, Label, FormGroup } from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import CustomSelect from "./../../components/Common/CustomSelect"
import Breadcrumbs from "components/Common/Breadcrumb"
import  { OrientationFieldOptions, OOHTypeFieldOptions, LightingDataFieldOptions } from "../../constants/common"
import { getSites } from "../../store/sites/actions"
import { updatePillar, getPillarData, resetPillarError } from "../../store/pillars/actions"
import { getCities, getDistricts, getProvinces } from "../../store/common/actions"
import AlertModal from "../../components/Common/AlertModal"

class PillarUpdate extends Component {
  componentDidMount () {
    const { onGetProvinces, onGetCities, onGetDistricts, getSites, getPillarData, match, pillar } = this.props
    const { params = {} } = match
    const { id } = params

    if (id) {
      getPillarData(id)
    }

    getSites()
    onGetProvinces()

    if (pillar !== null) {
      if (pillar.Province) {
        onGetCities(pillar.Province.id)
      }

      if (pillar.City) {
        onGetDistricts(pillar.City.id)
      }
    }
  }

  componentDidUpdate(prev) {
    const { onGetCities, onGetDistricts, pillar } = this.props
    if (prev.pillar === null && pillar !== null) {
      if (pillar.Province) {
        onGetCities(pillar.Province.id)
      }

      if (pillar.City) {
        onGetDistricts(pillar.City.id)
      }
    }
  }

  onProvinceChange (province, form) {
    form.setFieldValue("city", "")
    form.setFieldValue("district", "")

    this.props.onGetCities(province.value)
  }

  onCityChange (city, form) {
    form.setFieldValue("district", "")

    this.props.onGetDistricts(city.value)
  }

  render() {
    const { sites, pillar, updatePillar, history, provinces, cities, districts, error, message, resetPillarError, loading } = this.props
    const provincesMap = provinces.map(item => ({"value": item.id, "label": item.name}))
    const citiesMap = cities.map(item => ({ "value": item.id, "label": item.name }))
    const districtsMap = districts.map(item => ({ "value": item.id, "label": item.name }))
    const sitesMap = sites.map(item => ({ value: item.id, label: item.name }))

    let alertShow = false
    let alertTitle, alertMessage, alertType, alertCallback

    if (message && message.length) {
      alertShow = true
      alertTitle = 'Success'
      alertMessage = message
      alertType = 'success'
      alertCallback = () => history.push("/pillars")
    }

    if (error && error.length) {
      alertShow = true
      alertTitle = 'Error'
      alertMessage = error
      alertType = 'error'
      alertCallback = () => resetPillarError()
    }

    return (
      !pillar ? <React.Fragment /> :
      <React.Fragment>
        <div className="page-content">
          <AlertModal show={alertShow} onCloseClick={alertCallback} title={alertTitle} message={alertMessage} type={alertType} />
          <MetaTags>
            <title>Edit Pillar | Nord &amp; Smith Dashboard</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Pillars" breadcrumbItem="Edit Pillar" />
            <Row>
              <Col lg="12">
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    id: pillar.id,
                    name: pillar.name,
                    site: pillar.Site ? pillar.Site.id : "",
                    latitude: pillar.latitude,
                    longitude: pillar.longitude,
                    province: pillar.Province ? pillar.Province.id : "",
                    city: pillar.City ? pillar.City.id : "",
                    district: pillar.District ? pillar.District.id : "",
                    width: pillar.width,
                    height: pillar.height,
                    orientation: pillar.orientation,
                    side: pillar.side,
                    ooh_type: pillar.ooh_type,
                    lighting: pillar.lighting
                  }}
                  validationSchema={Yup.object().shape({
                    id: Yup.string().required("Pillar ID is required"),
                    name: Yup.string().required("Please enter your Pillar Name"),
                    latitude: Yup.string().required("Please enter Pillar Latitude"),
                    longitude: Yup.string().required("Please enter Pillar Longitude"),
                    width: Yup.string().required("Please enter Pillar Width"),
                    height: Yup.string().required("Please enter Pillar Height"),
                    orientation: Yup.string().required("Please select Pillar Orientation"),
                    side: Yup.string().required("Please enter Pillar Side"),
                    ooh_type: Yup.string().required("Please select Pillar OOH Type"),
                    lighting: Yup.string().required("Please select Pillar Lighting"),
                    site: Yup.number()
                  })}
                  onSubmit={values => {
                    let newValues = Object.keys(values).reduce((r, i) => {
                      let cur = values[i]

                      if (cur && cur.value) {
                        r[i] = cur.value
                      } else {
                        r[i] = cur
                      }

                      return r
                    }, {})

                    updatePillar(newValues)
                  }}
                  >
                  {({ errors, touched }) => (
                    <Form className="needs-validation">
                      <Card>
                        <div className="bg-transparent border-primary card-header">
                          <h5 className="my-0 text-primary">
                            <i className="bx bx-map-pin me-3"></i>
                            Pillar Name &amp; Location
                          </h5>
                        </div>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Pillar Name</Label>
                                <Field
                                  name="name"
                                  type="text"
                                  className={"form-control" + (errors.pillarName && touched.pillarName ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="name" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Site</Label>
                                <Field name="site" options={[{value: "", label: "None"}, ...sitesMap]} component={CustomSelect} />
                                <ErrorMessage name="site" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Latitude</Label>
                                <Field
                                  name="latitude"
                                  type="text"
                                  className={"form-control" + (errors.latitude && touched.latitude ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="latitude" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Longitude</Label>
                                <Field
                                  name="longitude"
                                  type="text"
                                  className={"form-control" + (errors.longitude && touched.longitude ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="longitude" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label>Province</Label>
                                <Field name="province" placeholder="Province" options={provincesMap} onChanged={this.onProvinceChange.bind(this)} component={CustomSelect} />
                                <ErrorMessage name="province" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label>City</Label>
                                <Field name="city" placeholder="City" options={citiesMap} onChanged={this.onCityChange.bind(this)} component={CustomSelect} />
                                <ErrorMessage name="city" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="mb-3">
                                <Label>District</Label>
                                <Field name="district" placeholder="District" options={districtsMap} component={CustomSelect} />
                                <ErrorMessage name="district" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card>
                        <div className="bg-transparent border-primary card-header">
                          <h5 className="my-0 text-primary">
                            <i className="bx bx-screenshot me-3"></i>
                            Pillar Size &amp; Detail
                          </h5>
                        </div>
                        <CardBody>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Width</Label>
                                <Field
                                  name="width"
                                  type="text"
                                  placeholder="Width in meter"
                                  className={"form-control" + (errors.width && touched.width ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="width" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Height</Label>
                                <Field
                                  name="height"
                                  placeholder="Height in meter"
                                  type="text"
                                  className={"form-control" + (errors.height && touched.height ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="height" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Orientation</Label>
                                <Field name="orientation" options={OrientationFieldOptions} component={CustomSelect} />
                                <ErrorMessage name="orientation" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Side</Label>
                                <Field
                                  name="side"
                                  type="text"
                                  className={"form-control" + (errors.side && touched.side ? " is-invalid" : "")}
                                />
                                <ErrorMessage name="side" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>OOH Type</Label>
                                <Field name="ooh_type" options={OOHTypeFieldOptions} component={CustomSelect} />
                                <ErrorMessage name="ooh_type" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                            <Col md="6">
                              <FormGroup className="mb-3">
                                <Label>Lighting</Label>
                                <Field name="lighting" options={LightingDataFieldOptions} component={CustomSelect} />
                                <ErrorMessage name="lighting" component="div" className="invalid-feedback"/>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" className="text-right">
                              <FormGroup className="mb-3">
                                <Button color="primary" type="submit" disabled={loading}>{loading ? 'Saving' : 'Save'}</Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

PillarUpdate.propTypes = {
  provinces: PropTypes.array,
  cities: PropTypes.array,
  districts: PropTypes.array,
  sites: PropTypes.array,
  pillar: PropTypes.object,
  error: PropTypes.string,
  message: PropTypes.string,
  loading: PropTypes.bool,
  getSites: PropTypes.func,
  getPillarData: PropTypes.func,
  onGetProvinces: PropTypes.func,
  onGetCities: PropTypes.func,
  onGetDistricts: PropTypes.func,
  updatePillar: PropTypes.func,
  resetPillarError: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object
}

const mapStateToProps = ({ common, sites, pillars }) => ({
  provinces: common.provinces,
  cities: common.cities,
  districts: common.districts,
  sites: sites.sites && sites.sites.data ? sites.sites.data : [],
  pillar: pillars.pillarData && pillars.pillarData.data ? pillars.pillarData.data : null,
  error: pillars.error,
  message: pillars.message,
  loading: pillars.loading
})
const mapDispatchToProps = dispatch => ({
  getSites: () => dispatch(getSites()),
  getPillarData: id => dispatch(getPillarData(id)),
  onGetProvinces: () => dispatch(getProvinces()),
  onGetCities: provinceId => dispatch(getCities(provinceId)),
  onGetDistricts: cityId => dispatch(getDistricts(cityId)),
  updatePillar: pillar => dispatch(updatePillar(pillar)),
  resetPillarError: () => dispatch(resetPillarError())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PillarUpdate))
