export const GET_BOOKINGS = "GET_BOOKINGS"
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS"
export const GET_BOOKINGS_FAIL = "GET_BOOKINGS_FAIL"
export const GET_BOOKING_DATA = "GET_BOOKING_DATA"
export const GET_BOOKING_DATA_SUCCESS = "GET_BOOKING_DATA_SUCCESS"
export const GET_BOOKING_DATA_FAIL = "GET_BOOKING_DATA_FAIL"
export const ADD_NEW_BOOKING = "ADD_NEW_BOOKING"
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS"
export const ADD_BOOKING_FAIL = "ADD_BOOKING_FAIL"
export const UPDATE_BOOKING = "UPDATE_BOOKING"
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS"
export const UPDATE_BOOKING_FAIL = "UPDATE_BOOKING_FAIL"
export const DELETE_BOOKING = "DELETE_BOOKING"
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS"
export const DELETE_BOOKING_FAIL = "DELETE_BOOKING_FAIL"
export const RESET_BOOKING_ERROR = "RESET_BOOKING_ERROR"
export const RESET_BOOKING_MESSAGE = "RESET_BOOKING_MESSAGE"