import {
  GET_PROVINCES,
  GET_PROVINCES_FAIL,
  GET_PROVINCES_SUCCESS,
  GET_CITIES,
  GET_CITIES_FAIL,
  GET_CITIES_SUCCESS,
  GET_DISTRICTS,
  GET_DISTRICTS_FAIL,
  GET_DISTRICTS_SUCCESS,
  GET_SUB_INDUSTRY,
  GET_SUB_INDUSTRY_FAIL,
  GET_SUB_INDUSTRY_SUCCESS,
  GET_ADVERTISER,
  GET_ADVERTISER_FAIL,
  GET_ADVERTISER_SUCCESS,
  GET_SALES,
  GET_SALES_FAIL,
  GET_SALES_SUCCESS
} from "./actionTypes"

export const getProvinces = () => ({
  type: GET_PROVINCES
})

export const getProvincesSuccess = provinces => ({
  type: GET_PROVINCES_SUCCESS,
  payload: provinces
})

export const getProvincesFail = error => ({
  type: GET_PROVINCES_FAIL,
  payload: error
})

export const getCities = (provinceId) => ({
  type: GET_CITIES,
  payload: provinceId
})

export const getCitiesSuccess = cities => ({
  type: GET_CITIES_SUCCESS,
  payload: cities
})

export const getCitiesFail = error => ({
  type: GET_CITIES_FAIL,
  payload: error
})

export const getDistricts = cityId => ({
  type: GET_DISTRICTS,
  payload: cityId
})

export const getDistrictsSuccess = districts => ({
  type: GET_DISTRICTS_SUCCESS,
  payload: districts
})

export const getDistrictsFail = error => ({
  type: GET_DISTRICTS_FAIL,
  payload: error
})

export const getSubIndustry = () => ({
  type: GET_SUB_INDUSTRY
})

export const getSubIndustrySuccess = subIndustries => ({
  type: GET_SUB_INDUSTRY_SUCCESS,
  payload: subIndustries
})

export const getSubIndustryFail = error => ({
  type: GET_SUB_INDUSTRY_FAIL,
  payload: error
})

export const getAdvertiser = () => ({
  type: GET_ADVERTISER
})

export const getAdvertiserSuccess = advertisers => ({
  type: GET_ADVERTISER_SUCCESS,
  payload: advertisers
})

export const getAdvertiserFail = error => ({
  type: GET_ADVERTISER_FAIL,
  payload: error
})

export const getSales = () => ({
  type: GET_SALES
})

export const getSalesSuccess = sales => ({
  type: GET_SALES_SUCCESS,
  payload: sales
})

export const getSalesFail = error => ({
  type: GET_SALES_FAIL,
  payload: error
})