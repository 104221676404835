import { takeEvery, fork,put, call,all } from "redux-saga/effects"
import { EDIT_PROFILE, GET_PROFILE } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"
import { getMyProfile, updateMyProfile } from "./../../../helpers/api_v1_helper"

function* getProfile({ payload: { history }}) {
  try {
    const response = yield call(getMyProfile)

    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))

    if (localStorage) {
      localStorage.removeItem("accessToken")

      history.push("/login")
    }
  }
}

function* editProfile({ payload: data }) {
  try {
    const response = yield call(updateMyProfile, data)

    yield put(profileSuccess(response))
  } catch (error) {
    yield put(profileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(GET_PROFILE, getProfile)
  yield takeEvery(EDIT_PROFILE, editProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
