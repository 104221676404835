import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import MetaTags from "react-meta-tags";
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row, Button, Label, FormGroup, Table } from "reactstrap"
import { Formik, Field, Form, ErrorMessage } from "formik"
import * as Yup from "yup"
import Breadcrumbs from "components/Common/Breadcrumb"
import { getPlayerData, getPillars, setPlayerMap } from "../../store/actions"
import CustomSelect from "../../components/Common/CustomSelect"

class MlpMappingEdit extends Component {
  componentDidMount() {
    const { getPlayerData, getPillars, match = {} } = this.props
    const { params = {} } = match
    const { id } = params

    if (id) {
      getPlayerData(id)
    }

    getPillars()
  }

  render() {
    const { player, pillars, setPlayerMap, history } = this.props
    const pillarMap = pillars.map(item => ({ value: item.id, label: item.name }))

    return (
      !player ? <></> : <>
        <div className="page-content">
          <MetaTags>
            <title>Media Log Proof Static | Nord & Smith Dashboard</title>
          </MetaTags>
          <Container fluid={true}>
            <Breadcrumbs title="Media Log Proof" breadcrumbItem="Edit Mapping" />
              <Row>
                <Col md="12">
                  <Formik
                    enableReinitialize={false}
                    initialValues={{
                      id: player.player_id,
                      pillar_id: player.PlayerMap ? player.PlayerMap.pillar_id : "",
                    }}
                    validationSchema={Yup.object().shape({
                      id: Yup.string().required("Please enter Player Id"),
                      pillar_id: Yup.string().required("Please select Pillar Name")
                    })}
                    onSubmit={values => {
                      let newValues = Object.keys(values).reduce((r, i) => {
                        let cur = values[i]

                        if (cur && cur.value) {
                          r[i] = cur.value
                        } else {
                          r[i] = cur
                        }

                        return r
                      }, {})

                      setPlayerMap(newValues, () => history.push("/mlp-mapping-list"))
                    }}
                    >
                    {({ handleSubmit, isSubmitting }) => (
                      <Form className="needs-validation" onSubmit={handleSubmit}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  <Label>ID Player</Label>
                                  <Field
                                    name="id"
                                    type="text"
                                    className="form-control"
                                    readOnly={true}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup className="mb-3">
                                  <Label>Pillar</Label>
                                  <Field
                                      name="pillar_id"
                                      placeholder="Select Pillar"
                                      options={[{ label: "Select Pillar", options: pillarMap }]}
                                      component={CustomSelect}
                                      classNamePrefix="select2-selection"
                                  />
                                  <ErrorMessage name="pillar_id" component="div" className="invalid-feedback"/>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12" className="text-right">
                                <FormGroup className="mb-3">
                                  <Button color="primary" type="submit" disabled={isSubmitting}>{ isSubmitting ? 'Saving' : 'Save'}</Button>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12" className="well mw-800 centered">
                                <Card className="border card border-primary">
                                  <CardBody className="text-center">
                                    <p>Current Connection</p>
                                    <Table striped bordered hover size="sm">
                                      <tbody>
                                        <tr>
                                          <th width="40%"><p>ID Player</p></th>
                                          <th width="30%"><p>Player Name</p></th>
                                          <th width="">Mapping</th>
                                          <th width="40%"><p>Pillar Name</p></th>
                                        </tr>
                                        <tr>
                                          <td><p>{player.player_id}</p></td>
                                          <td><p>{player.player_name}</p></td>
                                          <td><i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right" /></td>
                                          <td>{player.PlayerMap && player.PlayerMap.Pillar ? <Link to={"/pillars/view/" + player.PlayerMap.Pillar.id}>{player.PlayerMap.Pillar.name}</Link> : 'No Connection'}</td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Form>
                    )}
                  </Formik>
                </Col>
              </Row>
          </Container>
        </div>
      </>
    );
  }
}

MlpMappingEdit.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  player: PropTypes.object,
  pillars: PropTypes.array,
  getPlayerData: PropTypes.func,
  getPillars: PropTypes.func,
  setPlayerMap: PropTypes.func
}

const mapStateToProps = ({ player, pillars }) => ({
  player: player && player.playerData && player.playerData.data ? player.playerData.data : null,
  pillars: pillars.pillars && pillars.pillars.data ? pillars.pillars.data : []
})

const mapDispatchToProps = dispatch => ({
  getPlayerData: id => dispatch(getPlayerData(id)),
  getPillars: () => dispatch(getPillars()),
  setPlayerMap: (data, callback) => dispatch(setPlayerMap(data, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MlpMappingEdit))